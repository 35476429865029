.personal-account-company-wrapper {
  position: relative;

  &__input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 5;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &:checked + .personal-account-company--bordered {
      background: #F4F5F8;
      border-color: $blue;
      .personal-account-company__name {
        color: $blue;
      }
    }

    &:checked + .personal-account-company--bordered-green {
      border-color: $green;
      .personal-account-company__name {
        color: $green;
      }
    }

  }

  .personal-account-company {
    @media (max-width: 576px) {
      max-width: 100%;
    }
  }
}

.personal-account-company {
  padding: 8px;
  background: #F7F7F7;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  //max-width: 258px;
  position: relative;

  &--bordered {
    background: #fff;
    border: 1px solid #DEE2EA;
  }

  &--transparent {
    background: transparent;
    border: 1px solid #DEE2EA;
  }

  &__square {
    width: 50px;
    height: 50px;
    background: linear-gradient(45deg, #BDC5D5 0%, #D3D8E3 100%), #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    flex-shrink: 0;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 576px) {
      width: 40px;
      height: 40px;
    }

    &--no-shadow {
      filter: none;
    }

  }

  &__icon {
    width: 50px;
    height: auto;
    object-fit: contain;
    @media (max-width: 576px) {
      width: 22px;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #424E66;
    margin-bottom: 4px;
  }

  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #929FB9;

  }
}
