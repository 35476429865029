.pay {
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column;
  }

  &__img {
    width: 192px;
    margin-right: 16px;
    @media (max-width: 480px) {
      margin: 0 0 16px 0;
    }
  }

  &__content {
    width: 100%;
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__text {
    color: #AEACB4;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 16px;
  }

  &__upload {
    margin-top: 16px;
    @media (max-width: 576px) {
      margin-top: 8px;
    }
  }

}

.pay-total {

  &--flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__sum {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    @media (max-width: 576px) {
      margin-bottom: 8px;
    }

    span {
      color: $green;
    }
  }

}