body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  color: #000;
}

a {
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
} 

*,
*:before,
*:after {
  box-sizing: border-box;
}

textarea::-webkit-scrollbar {
  width: 5px;
  height: 50px;
}

textarea::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #F5F5F5;
}



.container {
  max-width: 1170px;
  margin: 0 auto;
}

.header__logo {
  display: block;

  @media(max-width: 992px) {
    padding-left: 10px;
  }

  @media (max-width: 576px) {
    img {
      width: 130px;
    }
  }
}

header {
  padding: 30px 0;
  box-shadow: 0px 4px 10px rgba(43, 57, 69, 0.05);

  @media(max-width: 992px) {
    padding: 20px 0;
  }

  @media(max-width: 576px) {
    padding: 15px 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icons {
    display: flex;
    align-items: center;
    padding-left: 10px;

    @media(max-width: 576px) {
      display: flex;
      padding-left: 0;
      justify-content: center !important;
    }
  }

  &__box {
    @media(max-width: 576px) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.header-icon {
  display: block;
  margin-right: 30px;

  @media (max-width: 992px) {
    margin-right: 20px;
  }

  &:last-child {
    margin-right: 0;
  }

  &__notification {
    position: absolute;
    top: -9px;
    right: -7px;
    min-width: 20px;
    height: 20px;
    background: #F03A31;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
      min-width: 19px;
      height: 19px;
    }

  }

  &__notification-number {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;

    @media (max-width: 576px) {
      font-size: 9px;
    }
  }

  &--bubble {
    img {
      @media (max-width: 576px) {
        width: 25px;
        height: 25px;
      }
    }
  }

  &--user {
    img {
      @media (max-width: 576px) {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.header-icon--position {
  position: relative;
}

.nav {
  display: flex;

  @media(max-width: 992px) {
    display: none;
    padding: 0 10px;
    margin-top: 15px
  }
}

.nav-link {
  display: block;
  margin-right: 30px;
  color: #2B3945;
  transition: .2s linear;

  &:hover {
    color: rgb(11, 197, 129);
  }

  &:last-child {
    margin-right: 0;
  }

  @media(max-width: 768px) {
    font-size: 14px;
    margin-right: 15px;
  }

  @media(max-width: 576px) {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

}

.nav-authorization {
  display: block;
  color: #01C77F;
  font-weight: 500;
  transition: .2s linear;

  &:hover {
    color: rgb(7, 165, 107);
  }

  @media(max-width: 992px) {
    margin-right: 20px;
  }

  @media(max-width: 768px) {
    font-size: 14px;
  }

  &__arrow {
    width: 16px;
    height: 16px;
    mask: url("../images/icons/red-arrow-right.svg");
    -webkit-mask: url("../images/icons/red-arrow-right.svg");
    background: $red;
    margin-left: 7px;
    @include mask-contain-center;
    flex-shrink: 0;

    @media (max-width: 576px) {
      width: 13px;
      height: 13px;
    }
  }

  &--red {
    display: flex;
    align-items: center;
    color: $red;

    &:hover {
      color: $red-hover;

      .nav-authorization__arrow {
        background: $red-hover;
      }
    }
  }

  @media (max-width: 576px) {
    display: none;
  }

  &--mobile {
    display: none;

    @media (max-width: 576px) {
      display: flex;
      justify-content: flex-end;
      padding: 0;
    }
  }
}

.menu {
  text-align: center;
  display: none;

  @media(max-width: 992px) {
    display: block;
  }

  &__box {
    margin: 0 auto;
    position: relative;
  }

  &__icon {
    color: #01C77F;
    font-size: 24px;
  }

  &-list {
    display: none;
    padding: 10px;
    border: 1px solid #cacaca;
    z-index: 22;
    top: 20px;
    left: -210px;
    background: white;
    text-align: right;
    position: absolute;
    border-radius: 3px;
  }

  &__link {
    font-size: 15px;
    color: #555555;
    border-bottom: 1px solid #cacaca;
    margin-bottom: 10px;
    padding-bottom: 5px;
    transition: .2s linear;

    &:hover {
      color: rgb(37, 146, 91);
    }

    &:nth-child(4) {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;

      @media (max-width: 576px) {
        border-bottom: 1px solid #cacaca;
        margin-bottom: 10px;
        padding-bottom: 5px;
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    display: block;

    &--authorization {
      color: $red;
    }
  }
}

.main {
  background: url(../images/background.png) no-repeat center /cover;
  padding: 125px 0;

  @media(max-width: 992px) {
    padding: 100px 0;
  }

  @media(max-width: 768px) {
    padding: 55px 20px;
  }

  @media(max-width: 576px) {
    padding: 35px 10px;
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 58px;
    color: #fff;
    text-align: center;

    span {
      color: #01C77F;
    }

    @media(max-width: 1200px) {
      font-size: 38px;
    }

    @media(max-width: 992px) {
      font-size: 30px;
    }

    @media(max-width: 768px) {
      font-size: 23px;
    }

    @media(max-width: 576px) {
      line-height: 38px;
    }
  }

  &__input {
    &::placeholder {
      color: #fff;
      font-size: 16px;
      line-height: 19px;

      @media(max-width: 992px) {
        font-size: 14px;
      }

      @media(max-width: 576px) {
        font-size: 13px;
      }
    }

    @media(max-width: 992px) {
      width: 45%;
    }

    @media(max-width: 768px) {
      width: 100%;
    }

    @media(max-width: 576px) {
      padding: 15px 10px;
      width: 100%;
    }

    outline: none;
    padding: 16px 20px;
    width: 40%;
    border: none;
    border-radius: 5px;
    color: #fff;
    background: rgba(255, 255, 255, 0.3);
    margin-right: 7px;
  }

  &__btn {
    @media(max-width: 768px) {
      width: 100%;
    }
  }
}

.main-form {
  margin-top: 30px;
  text-align: center;

  @media(max-width: 768px) {
    margin-top: 20px;
  }
}

.advantages {
  padding: 38px 0;
  background: #262026;

  @media(max-width: 768px) {
    padding: 30px 20px;
  }

  @media(max-width: 576px) {
    padding: 20px 15px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }
}

.advantages-item {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media(max-width: 576px) {
      margin-bottom: 10px;
    }
  }

  &__title-img {
    margin-right: 20px;
  }

  &__title-text {
    color: #fff;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__text {
    line-height: 24px;
    font-size: 14px;
    color: #fff;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }
}

.views {
  padding: 100px 0;

  @media(max-width: 992px) {
    padding: 80px 0;
  }

  @media(max-width: 768px) {
    padding: 50px 0;
  }

  @media(max-width: 576px) {
    padding: 30px 0;
  }

  &__title {
    text-align: center;
    color: #262026;
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 100px;

    @media(max-width: 1200px) {
      font-size: 30px;
      margin-bottom: 80px;
    }

    @media(max-width: 992px) {
      font-size: 28px;
      margin-bottom: 60px;
    }

    @media(max-width: 768px) {
      font-size: 25px;
      margin-bottom: 50px;
    }

    @media(max-width: 576px) {
      font-size: 22px;
      margin-bottom: 30px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;

    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-gap: 25px;
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
}

.views-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #FCFCFC;
  border: 1px solid #F5F5F5;
  width: 100%;
  min-height: 270px;
  text-align: center;
  padding-bottom: 35px;

  @media (max-width: 576px) {
    min-height: 230px;
  }

  &__img {
    width: 80px;
    height: 80px;
    margin: 0 auto 30px auto;

    @media(max-width: 768px) {
      margin-bottom: 20px;
    }

    @media (max-width: 576px) {
      width: 65px;
      height: 65px;
    }
  }

  &__text {
    line-height: 24px;
    font-size: 18px;

    @media(max-width: 992px) {
      font-size: 16px;
    }

    @media(max-width: 768px) {
      font-size: 14px;
    }
  }
}

.attract-box {
  background-color: #F2F2F2;
  border-radius: 5px;

  &__inner {
    padding: 70px 50px 70px 50px;
    background: url(../images/attract-background.png) no-repeat;

    @media(max-width: 992px) {
      padding: 45px 40px;
    }

    @media(max-width: 768px) {
      padding: 30px 25px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #262026;
    margin-top: 30px;
    margin-bottom: 15px;

    @media(max-width: 1200px) {
      font-size: 34px;
    }

    @media(max-width: 992px) {
      font-size: 30px;
    }

    @media(max-width: 768px) {
      font-size: 22px;
      margin-bottom: 10px;
      margin-top: 15px;
    }

    @media(max-width: 768px) {
      font-size: 20px;
    }

    @media(max-width: 768px) {
      font-size: 18px;
    }
  }

  &__text {
    font-size: 18px;
    color: #525252;
    line-height: 28px;

    @media(max-width: 768px) {
      font-size: 16px;
    }

    @media(max-width: 768px) {
      font-size: 14px;
    }
  }

  &__arrow {
    margin-left: 15px;
  }

  &__btn {
    @media(max-width: 576px) {
      padding: 10px 9px;
    }
  }
}

.performers {
  padding: 150px 0;

  @media(max-width: 992px) {
    padding: 100px 0;
  }

  @media(max-width: 768px) {
    padding: 60px 0;
  }

  @media(max-width: 576px) {
    padding: 40px 0;
  }

  &__title {
    font-size: 32px;
    font-weight: 500;
    color: #262026;
    line-height: 42px;
    margin-bottom: 50px;
    text-align: center;

    @media(max-width: 1200px) {
      font-size: 30px;
    }

    @media(max-width: 992px) {
      font-size: 28px;
    }

    @media(max-width: 768px) {
      font-size: 24px;
      margin-bottom: 25px;
    }

    @media(max-width: 768px) {
      font-size: 21px;
    }

    @media(max-width: 576px) {
      margin-bottom: 30px;
    }
  }

  &__btn {
    text-align: center;
  }
}

.performers-item {
  &:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
  }

  transition: .2s linear;
  cursor: pointer;
  text-align: center;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  padding: 20px;
  height: 100%;

  &__img-box {
    display: block;
    position: relative;
    z-index: 1;

    @media(max-width: 576px) {
      width: 70%;
      margin: 0 auto;
    }
  }

  &__img {
    width: 70%;
    height: auto;
    margin: 0 auto 20px auto;
  }

  &__shield {
    position: absolute;
    z-index: 2;
    bottom: 15px;
    right: 30px;
    width: 25%;
    height: auto;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: #262026;
    margin-bottom: 10px;

    @media(max-width: 992px) {
      font-size: 16px;
    }

    @media(max-width: 768px) {
      font-size: 14px;
    }
  }

  &__building {
    font-size: 14px;
    line-height: 16px;
    color: #01C77F;
    margin-bottom: 10px;

    @media(max-width: 992px) {
      font-size: 13px;
    }

    @media(max-width: 768px) {
      font-size: 12px;
    }
  }

  &__about {
    height: 50px;
    font-size: 12px;
    line-height: 16px;
    color: #858585;

    @media(max-width: 768px) {
      font-size: 11px;
    }
  }
}

.executor {
  @media(max-width: 992px) {
    padding-bottom: 120px;
  }

  @media(max-width: 768px) {
    padding-bottom: 90px;
  }

  @media(max-width: 576px) {
    padding-bottom: 50px;
  }

  padding-bottom: 150px;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 576px) {
      display: block;
    }
  }

  &__box {
    @media(max-width: 768px) {
      margin-right: 10px;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    color: #F03A31;
    line-height: 42px;
    margin-bottom: 20px;

    @media(max-width: 1200px) {
      font-size: 30px;
    }

    @media(max-width: 992px) {
      font-size: 28px;
    }

    @media(max-width: 768px) {
      font-size: 25px;
      margin-bottom: 10px;
    }

  }

  &__subtext {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #262026;
    margin-bottom: 20px;

    @media(max-width: 1200px) {
      font-size: 18px;
    }

    @media(max-width: 992px) {
      font-size: 16px;
    }

    @media(max-width: 768px) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &__text {
    width: 80%;
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;
    color: #262026;

    @media(max-width: 1200px) {
      font-size: 16px;
    }

    @media(max-width: 992px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      width: 100%;
    }
  }

  &__btn {
    margin-top: 60px;

    @media(max-width: 992px) {
      margin-top: 40px;
    }

    @media(max-width: 768px) {
      margin-top: 30px;
    }

    @media(max-width: 576px) {
      margin-top: 20px;
    }
  }

  &__img {
    width: 90%;
    height: auto;

    @media(max-width: 1200px) {
      width: 50%;
    }

    @media(max-width: 992px) {
      width: 45%;
    }

    @media(max-width: 576px) {
      width: 60%;
    }
  }
}

.footer {
  background: #262026;
  padding: 60px 0 0 0;

  @media(max-width: 992px) {
    padding: 50px 0 0 0;
  }

  @media(max-width: 576px) {
    padding: 30px 0 0 0;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }

    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  &__link {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 30px;
    transition: .2s linear;

    &:hover {
      color: #01C77F;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media(max-width: 992px) {
      margin-bottom: 20px;
    }

    @media(max-width: 576px) {
      margin-bottom: 15px;
      font-size: 14px;
    }
  }

  &__link-company {
    color: $green;
    transition: .2s linear;
    display: inline-block;

    &:hover {
      color: $green-hover;
    }
  }

  &__contacts-box {
    display: flex;
    align-items: center;
  }

  &__bottom {
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin-top: 80px;
    padding-bottom: 30px;

    @media (max-width: 992px) {
      flex-direction: column;
      margin-top: 50px;
    }

    @media (max-width: 768px) {
      margin-top: 30px;
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 19px;
    color: $grey;

    @media (max-width: 992px) {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }

    &--col2 {
      grid-column: 1/3;
    }
  }

  &__company {
    background: #171517;
    padding: 47px 0;

    @media (max-width: 992px) {
      padding: 30px 0;
    }

    @media (max-width: 768px) {}
  }

  &__company-inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &__company-logo {
    width: 100%;
    height: auto;
    object-fit: contain;

    @media (max-width: 768px) {
      width: 300px;
    }

    @media (max-width: 576px) {
      width: 85%;
      max-width: 85%;
    }
  }

  &__logo {
    object-fit: contain;
    width: 300px;

    @media (max-width: 768px) {
      width: 250px;
    }

    @media (max-width: 576px) {
      margin: 0 20px 0 0;
      width: 100%;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &--mt {
    margin-top: 84px;

    @media (max-width: 576px) {
      margin-top: 50px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin-bottom: 50px;

    @media (max-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 30px;
    }
  }

  &__col {
    &--end {
      grid-column: 3/4;

      @media (max-width: 576px) {
        grid-column: initial;
      }
    }
  }
}

.footer-logo {
  &__right {
    margin-top: 27px;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;

    @media(max-width: 992px) {
      font-size: 16px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }
}

.footer-contacts {
  @media(max-width: 768px) {
    margin-top: 30px;
  }

  @media(max-width: 576px) {
    margin-top: 20px;
  }

  &__tel {
    display: inline-block;
    color: #858585;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    margin-right: 40px;
    transition: .2s linear;

    &:hover {
      color: rgb(221, 221, 221);
    }

    @media(max-width: 992px) {
      font-size: 22px;
      margin-right: 20px;
    }

    @media(max-width: 992px) {
      font-size: 19px;
    }

    @media(max-width: 576px) {
      font-size: 15px;
    }
  }

  &__icon {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }

    @media(max-width: 992px) {
      margin-right: 20px;
    }

    transition: .2s linear;

    &:hover {
      opacity: 0.8;
    }
  }

  &__subtext {
    margin-top: 17px;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: #858585;
    text-align: end;

    span {
      color: #01C77F;
    }

    @media(max-width: 992px) {
      font-size: 16px;
    }

    @media(max-width: 768px) {
      text-align: start;
    }

    @media(max-width: 768px) {
      font-size: 14px;
    }
  }
}

.search {
  margin: 20px 0 25px 0;
}

.search-form {
  display: flex;

  &__input {
    outline: none;
    background: #F5F5F5;
    border-radius: 5px;
    width: 95%;
    border: none;
    padding: 15px 20px;
    margin-right: 10px;

    &::placeholder {
      font-size: 16px;
      line-height: 19px;
      color: #525252;

      @media(max-width: 576px) {
        font-size: 13px;
      }
    }

    @media(max-width: 1200px) {
      width: 94%;
    }

    @media(max-width: 992px) {
      width: 100%;
    }
  }

  &__button {
    cursor: pointer;
    outline: none;
    background: #262026;
    border-radius: 5px;
    border: none;
    height: 51px;
    width: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s linear;

    &:hover {
      background: #423d42;
    }

    @media(max-width: 576px) {
      padding: 13px 15px;
    }
  }
}

.navigation {
  &__link {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
  }

  &__link-box {
    margin-bottom: 30px;

    label {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  &__link {
    p {
      font-size: 18px;
      line-height: 21px;
      color: #262026;
      width: 200px;

      @media(max-width: 576px) {
        font-size: 15px;
      }
    }
  }

  &__info {
    font-size: 18px;
    line-height: 21px;
    color: $dark;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 50px;
    margin-bottom: 16px;
    transition: .2s linear;

    @media (max-width: 576px) {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 8px;
    }

    &:hover {
      color: #EA1E21;

      .navigation__count {
        color: #EA1E21;
      }

      .navigation__icon--star {
        mask: url("../images/icons/fill-star.svg");
        -webkit-mask: url("../images/icons/fill-star.svg");
      }
    }

    &--active {
      color: #EA1E21;

      .navigation__count {
        color: #EA1E21;
      }

      .navigation__icon--star {
        mask: url("../images/icons/fill-star.svg");
        -webkit-mask: url("../images/icons/fill-star.svg");
      }
    }
  }

  &__icon {
    margin-right: 15px;
    transition: .2s linear;

    &--star {
      width: 24px;
      height: 24px;
      mask: url("../images/icons/star-empty.svg");
      -webkit-mask: url("../images/icons/star-empty.svg");
      @include mask-contain-center;
      background: $red;
    }
  }

  &__info-text {
    margin-right: 15px;
  }

  &__count {
    color: $grey;
    margin-left: auto;
    transition: .2s linear;
  }
}

.navigation-box {
  padding: 0 20px;

  @media(max-width: 576px) {
    padding: 0 10px;
  }
}

.navigation-form {
  margin-top: 20px;

  &__link {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #F03A31;
    margin: 10px 0 25px 0;
    transition: .2s linear;

    &:hover {
      color: rgb(204, 51, 43);
    }
  }

  &__checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin-bottom: 19px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__radio {
    margin-bottom: 19px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  label {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    font-size: 15px;
    cursor: pointer;
  }
}

.navigation-form__checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: rgba(248, 142, 136, 0.582);
}

.navigation-form__checkbox+label {
  display: inline-flex;
  user-select: none;
}

.navigation-form__checkbox+label::before {
  content: '';
  transition: .2s linear;
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #F5F5F5;
  border-radius: 2px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 70%;
}

.navigation-form__checkbox:checked+label::before {
  background-color: #fff;
  background-image: url(../images/icons/check-red.svg);
}

.navigation-form__radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.navigation-form__radio+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.navigation-form__radio:not(:disabled):not(:checked)+label:hover::before {
  border-color: rgba(248, 142, 136, 0.582);
}

.navigation-form__radio+label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #F5F5F5;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.navigation-form__radio:checked+label::before {
  background-color: #FFFFFF;
  background-image: url(../images/icons/radio.svg);
}

.executor-search-investment {
  margin-top: 100px;

  @media(max-width: 992px) {
    display: none !important;
  }
}

.executor-investment {
  margin-top: 100px;

  @media(max-width: 992px) {
    display: none !important;
  }
}

.investment {
  @media(max-width: 768px) {
    display: none;
  }

  width: 100%;
  display: inline-block;
  border-radius: 5px;
  background: #F3F3F3;

  &__inner {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 100%;
    padding: 60px 20px 40px 20px;
    background: url(../images/investment.png) no-repeat;

    @media(max-width: 1200px) {
      padding: 60px 10px 40px 10px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #262026;
    margin-bottom: 11px;
    text-align: center;

    @media(max-width: 1200px) {
      font-size: 17px;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    color: #525252;
    text-align: center;
  }

  &__btn {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &__button {
    background: #296CDB;
    border-radius: 5px;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    padding: 12px 15px;
    display: flex;
    align-items: center;

    img {
      margin-left: 9px;
    }

    @media(max-width: 1200px) {
      font-size: 12px;
      padding: 12px 10px;
    }
  }
}

.executor-item {
  padding: 30px;
  //border: 1px solid #F5F5F5;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: relative;
  transition: .2s linear;
  cursor: pointer;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  &:hover {
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.09);
  }

  @media(max-width: 768px) {
    width: 100%;
  }

  @media(max-width: 576px) {
    padding: 15px;
  }

  &__inner {
    display: flex;
    align-items: center;

    @media(max-width: 576px) {
      display: block;
    }
  }

  &__box {
    margin-left: 11px;

    @media(max-width: 576px) {
      margin-left: 0;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #262026;
    margin-bottom: 10px;

    @media(max-width: 1200px) {
      font-size: 18px;
    }

    @media(max-width: 992px) {
      font-size: 16px;
    }

    @media(max-width: 567px) {
      font-size: 14px;
    }
  }

  &__subtext {
    font-size: 16px;
    line-height: 19px;
    color: #262026;
    margin-bottom: 10px;

    @media(max-width: 1200px) {
      font-size: 14px;
    }

    @media(max-width: 567px) {
      font-size: 13px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    color: #858585;

    @media(max-width: 567px) {
      font-size: 13px;
    }
  }

  &__about {
    margin-top: 20px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #262026;

    @media(max-width: 567px) {
      font-size: 14px;
    }
  }

  &__img-box {
    position: relative;
    display: inline-block;
  }

  &__guard {
    position: absolute;
    bottom: -5px;
    right: -5px;
  }

  &__star-box {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 30px;
  }
}

.pagination {
  margin: 100px 0;

  @media(max-width: 768px) {
    margin: 70px 0;
  }

  @media(max-width: 576px) {
    margin: 50px 10px;
  }

  &__inner {
    display: flex;
    justify-content: center;
  }

  &__link {
    color: #000;
    font-size: 20px;
    line-height: 23px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F0F0F0;
    width: 60px;
    height: 56px;
    border-radius: 5px;
    transition: .2s linear;

    &:hover {
      color: #fff;
      background: #01C77F;
    }

    @media(max-width: 1200px) {
      width: 50px;
      height: 50px;
      font-size: 18px;
    }

    @media(max-width: 992px) {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }

    @media(max-width: 992px) {
      width: 30px;
      height: 30px;
      font-size: 14px;
    }

    &--mr-none {
      margin-right: 0;
    }
  }

  &__link-active {
    color: #fff;
    background: #01C77F;
  }

  &__next {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 20px;
    line-height: 23px;
    color: #858585;
    transition: .2s linear;

    &:hover {
      color: #01C77F;
    }

    @media(max-width: 1200px) {
      font-size: 18px;
    }

    @media(max-width: 992px) {
      font-size: 16px;
    }

    @media(max-width: 768px) {
      font-size: 14px;
    }

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__separator {
    margin: 0 25px;
    font-size: 20px;
    line-height: 23px;
    color: $grey;
    display: flex;
    align-items: center;
  }
}

.back-link {
  margin: 40px 0 30px 0;

  @media(max-width: 768px) {
    margin: 30px 0 20px 0;
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__img {
    margin-right: 15px;
  }

  &__icon {
    margin-right: 15px;
    transition: .2s linear;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #C2C2C2;
    font-size: 16px;
    line-height: 19px;
    transition: all .2s linear;

    &:hover {
      svg {
        fill: #858585;
      }

      color: #858585;
    }

    i {
      margin-right: 13px;
      font-size: 15px;
    }

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }
}

.executor-head {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  @media(max-width: 992px) {
    margin-top: 30px;
  }

  @media(max-width: 768px) {
    margin-bottom: 30px
  }

  @media(max-width: 576px) {
    display: block;
    margin-bottom: 23px
  }

  &__img {
    width: 30%;
    height: auto;

    @media(max-width: 576px) {
      width: 40%;
    }
  }

  &__logo-box {
    height: 270px;
    width: 270px;
    border-radius: 50%;
    border: 4px solid #EDEDED;
    @include flex-column-center;
    padding: 40px;
    flex-shrink: 0;

    @media (max-width: 992px) {
      width: 240px;
      height: 240px;
    }

    @media (max-width: 768px) {
      width: 200px;
      height: 200px;
    }

    @media (max-width: 576px) {
      margin: 0 0 15px 0;
      width: 180px;
      height: 180px;
    }
  }

  &__logo {
    object-fit: contain;
    width: 100%;
    height: auto;
  }

  &__box {
    margin-left: 30px;

    @media(max-width: 576px) {
      margin-left: 5px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #262026;
    margin-bottom: 15px;

    @media(max-width: 1200px) {
      font-size: 33px;
    }

    @media(max-width: 992px) {
      font-size: 30px;
    }

    @media(max-width: 768px) {
      font-size: 25px;
    }

    @media(max-width: 768px) {
      font-size: 22px;
      margin-bottom: 5px;
    }

    @media(max-width: 768px) {
      font-size: 19px;
    }
  }

  &__about {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    color: #262026;
    margin-bottom: 18px;

    @media(max-width: 768px) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &__shield {
    margin: 0 14px 0 33px;
  }

  &__contracts {
    margin-bottom: 16px;
    color: #262026;
    font-size: 14px;
    line-height: 16px;
  }

  &__text {
    font-size: 16px;
    line-height: 16px;
    color: #858585;

    @media(max-width: 768px) {
      font-size: 14px;
    }
  }

  &__company {
    margin: 0 30px 0 10px;
  }

  &--grey {
    color: #858585;
  }
}

.company-title {
  span {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #858585;

    @media(max-width: 992px) {
      font-size: 21px;
    }

    @media(max-width: 768px) {
      font-size: 19px;
    }

    @media(max-width: 576px) {
      font-size: 17px;
    }
  }

  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #262026;

  @media(max-width: 992px) {
    font-size: 21px;
    margin-bottom: 20px;
  }

  @media(max-width: 768px) {
    font-size: 19px;
    margin-bottom: 15px;
  }

  @media(max-width: 576px) {
    font-size: 17px;
    margin-bottom: 10px;
  }
}

.company-text {
  font-size: 16px;
  line-height: 30px;
  color: #525252;
  margin-bottom: 60px;

  @media(max-width: 992px) {
    margin-bottom: 50px;
  }

  @media(max-width: 768px) {
    margin-bottom: 40px;
  }

  @media(max-width: 576px) {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.portfolio {

  &__inner {
    margin-bottom: 80px;

    @media(max-width: 992px) {
      margin-bottom: 30px;
    }

    @media(max-width: 768px) {
      margin-bottom: 10px;
    }
  }
}

.portfolio-item {
  &:hover {
    .portfolio-item__text {
      color: #01C77F;
    }
  }

  &__text {
    transition: all .2s linear;
    display: block;
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
    color: #262026;

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__img {
    height: 270px;
    overflow: hidden;
    border-radius: 5px;

    img {
      &:hover {
        transform: scale(1.2);
      }

      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      transition: transform .5s ease;
    }
  }
}

.types-jobs {
  margin-bottom: 60px;

  @media(max-width: 768px) {
    margin-bottom: 40px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #262026;
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 18px;

    &:after {
      content: '';
      display: block;
      width: 50px;
      height: 3px;
      background: #01C77F;
      position: absolute;
      bottom: 0px;
    }
  }

  &__box {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__job {
    font-size: 16px;
    line-height: 30px;
    color: #262026;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.company-head {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media(max-width: 768px) {
    margin-bottom: 20px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #262026;

    @media(max-width: 992px) {
      font-size: 22px;
    }

    @media(max-width: 768px) {
      font-size: 20px;
    }

    @media(max-width: 576px) {
      font-size: 18px;
    }
  }

  &__num {
    margin-left: 10px;
    font-size: 24px;
    line-height: 28px;
    color: #C2C2C2;

    @media(max-width: 992px) {
      font-size: 22px;
    }

    @media(max-width: 768px) {
      font-size: 20px;
    }

    @media(max-width: 576px) {
      font-size: 18px;
    }
  }

  &--medium {
    .company-head__title {
      font-size: 20px;

      @media (max-width: 576px) {
        font-size: 18px;
      }
    }

    .company-head__num {
      font-size: 20px;

      @media (max-width: 576px) {
        font-size: 18px;
      }
    }
  }
}

.patterns {
  &__item {
    cursor: pointer;
    margin-bottom: 30px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 15px;
    transition: .2s linear;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.09);
    }

    @media(max-width: 768px) {
      margin-bottom: 20px;
      padding: 10px;
    }

    @media(max-width: 576px) {
      margin-bottom: 10px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #262026;
    margin-bottom: 20px;

    @media(max-width: 768px) {
      padding-left: 10px;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  &__services {
    height: 55px;
    display: flex;
    align-items: center;

    @media(max-width: 768px) {
      font-size: 15px;
    }
  }

  &__shield {
    width: 20px;
    height: auto;
    margin-left: 12px;
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    color: #C2C2C2;
  }
}

.patterns--btn {
  margin: 20px 0 60px 0;

  @media(max-width: 768px) {
    margin: 15px 0 40px 0;
  }
}

.company-btn {
  justify-content: center;
  display: flex;

  &__link {
    display: block;
    border: 1px solid #01C77F;
    padding: 16px 20px;
    border-radius: 5px;
    color: #01C77F;
    font-size: 16px;
    line-height: 19px;

    @media(max-width: 768px) {
      padding: 14px 20px;
      font-size: 14px;
    }
  }
}

.reviews {
  &__img {
    @media(max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__item {
    display: flex;
    margin-bottom: 34px;

    &:last-child {
      margin-bottom: 0;
      ;
    }

    @media(max-width: 768px) {
      display: block;
    }
  }

  &__item-box {
    margin-left: 20px;
  }

  &__rating-head {
    display: flex;
    margin-bottom: 17px;

    @media(max-width: 768px) {
      margin-bottom: 10px;
    }

    @media(max-width: 576px) {
      display: block;
    }
  }

  &__rating {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #262026;
    margin-right: 20px;

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      margin-bottom: 10px;
    }
  }

  &__rating-img {
    display: flex;
    align-items: center;
    margin-left: 20px;

    img {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__company {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #858585;
    margin-right: 20px;

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      margin-bottom: 10px;
    }
  }

  &__date {
    font-size: 16px;
    line-height: 19px;
    color: #858585;

    @media(max-width: 768px) {
      font-size: 15px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 30px;
    color: #262026;

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 768px) {
      font-size: 14px;
    }
  }
}

.reviews--btn {
  margin: 30px 0 60px 0;

  @media(max-width: 768px) {
    margin: 20px 0 40px 0;
  }
}

.partners-company {
  margin-bottom: 150px;

  @media(max-width: 768px) {
    margin-bottom: 100px;
  }

  @media(max-width: 576px) {
    margin-bottom: 60px;
  }

  &__text {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #262026;
    margin-bottom: 20px;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__skills {
    display: flex;

    @media(max-width: 768px) {
      display: block;
    }
  }

  &__skills-item {
    margin-right: 10px;
    padding: 7px 10px;
    display: inline-block;
    color: #525252;
    font-size: 12px;
    line-height: 14px;
    background: #F2F2F2;
    border-radius: 5px;

    &:last-child {
      margin-right: 0;
    }

    @media(max-width: 576px) {
      text-align: center;
      display: block;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  &__skils-item--white {
    border: 1px solid #F5F5F5;
    background: #FFFFFF;
  }
}

.partners-company-item {
  &:hover {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  }

  transition: .2s linear;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  padding: 30px 40px;

  @media(max-width: 768px) {
    padding: 20px 30px;
  }

  @media(max-width: 576px) {
    padding: 10px 20px;
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media(max-width: 576px) {
      display: block;
    }
  }

  &__img {
    position: relative;
    display: inline-block;

    @media(max-width: 576px) {
      margin-bottom: 15px;
    }
  }

  &__shield {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__about {
    margin-left: 14px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #262026;
    margin-bottom: 10px;

    @media(max-width: 992px) {
      font-size: 22px;
    }

    @media(max-width: 768px) {
      font-size: 20px;
    }

    @media(max-width: 576px) {
      font-size: 18px;
    }
  }

  &__subtext {
    font-size: 16px;
    line-height: 19px;
    color: #262026;
    margin-bottom: 10px;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__projects {
    font-size: 14px;
    line-height: 16px;
    color: #858585;
  }
}

.offer-btn {
  display: block;
  background: #01C77F;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  padding: 16px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  box-shadow: 0px 4px 10px rgba(1, 199, 127, 0.2);

  @media(max-width: 1200px) {
    font-size: 14px;
  }

  @media(max-width: 992px) {
    width: 100%;
  }

  @media(max-width: 576px) {
    padding: 14px 0;
    font-size: 13px;
  }

  &--mt {
    margin-top: 80px;

    @media (max-width: 992px) {
      margin-top: 0;
    }
  }

  &--mb {
    margin-bottom: 40px;

    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }
}

.send-btn {
  display: flex;
  align-items: center;

  &__message {
    display: inline-block;
    background: #F5F5F5;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    color: #525252;
    padding: 16px 0;
    width: 100%;
    text-align: center;
    margin-right: 10px;

    @media(max-width: 1200px) {
      font-size: 14px;
    }

    @media(max-width: 992px) {
      width: 93%;
    }

    @media(max-width: 576px) {
      padding: 14px 0;
      font-size: 13px;
    }
  }

  &__favorites {
    display: inline-block;
    background: #F5F5F5;
    border-radius: 5px;
    height: 51px;
    width: 51px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      .send-btn__favorites-icon {
        mask: url("../images/icons/favorites-black-star.svg");
        -webkit-mask: url("../images/icons/favorites-black-star.svg");
      }
    }

    &--active {
      .send-btn__favorites-icon {
        mask: url("../images/icons/favorites-black-star.svg");
        -webkit-mask: url("../images/icons/favorites-black-star.svg");
      }
    }

    @media(max-width: 576px) {
      padding: 14px;
    }
  }

  &__favorites-icon {
    width: 20px;
    height: 20px;
    @include mask-contain-center;
    mask: url("../images/icons/favorites.svg");
    -webkit-mask: url("../images/icons/favorites.svg");
    background: $dark;
    transition: .2s linear;
  }
}

.check-company {
  font-size: 16px;
  line-height: 19px;
  color: #F03A31;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F03A31;
  padding: 16px 0;
  border-radius: 5px;
  transition: .2s linear;

  svg {
    margin-right: 13px;
  }

  &:hover {
    svg {
      fill: #fff;
    }

    background-color: rgb(243, 93, 85);
    color: #fff;
    border-color: rgb(243, 93, 85);
  }

  @media(max-width: 768px) {
    padding: 14px 0;
    font-size: 14px;
  }

  @media(max-width: 576px) {
    padding: 13px 0;
    font-size: 13px;
  }

  i {
    margin-right: 13px;
  }

  &__link {}

  @media(max-width: 992px) {
    margin-top: 50px;
    width: 100%;
  }

  @media(max-width: 768px) {
    margin-top: 40px;
  }

  @media(max-width: 576px) {
    margin-top: 30px;
  }
}

.competencies {
  &__title {
    margin: 45px 0 20px 0;
  }

  &__form {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  &__input {
    &::placeholder {
      font-size: 14px;
      line-height: 16px;
      color: #C2C2C2;

      @media(max-width: 1200px) {
        font-size: 13px;
      }
    }

    width: 92%;
    margin-right: 10px;
    outline: none;
    padding: 12px 15px;
    border: 1px solid #F5F5F5;
  }

  &__btn {
    span {
      display: flex;
      align-items: center;
      justify-content: center;

    }

    outline: none;
    cursor: pointer;
    background: #C2C2C2;
    border-radius: 5px;
    border: none;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    transition: .2s linear;

    &:hover {
      background: rgb(168, 168, 168);
    }
  }

  &__filter {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__property {
    display: inline-block;
    background: #F2F2F2;
    padding: 7px 10px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #525252;

    @media(max-width: 576px) {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
}

.contacts-info {
  margin-bottom: 150px;

  @media(max-width: 768px) {
    margin-bottom: 100px;
  }

  @media(max-width: 576px) {
    margin-bottom: 60px;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #525252;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: #525252;
  }
}

.information {
  margin-top: 120px;

  @media(max-width: 992px) {
    margin-top: 90px;
  }

  @media(max-width: 768px) {
    margin-top: 50px;
  }

  @media(max-width: 576px) {
    margin-top: 30px;
  }

  &__accounting-item {
    display: flex;
    justify-content: space-between;
  }

  &__subtitle {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #262026;
  }

  &__accounting-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__accounting-name {
    font-size: 16px;
    line-height: 30px;
    color: #262026;
  }

  &__accounting-num {
    font-size: 16px;
    line-height: 30px;
    color: #525252;
  }
}

.sign-in {
  width: 50%;

  @media(max-width: 992px) {
    width: 100%;
  }

  &__inner {
    width: 55%;
    margin: 0 auto;
    padding-top: 60px;

    @media(max-width: 1200px) {
      width: 70%;
    }

    @media(max-width: 992px) {
      width: 50%;
      padding-top: 80px;
    }

    @media(max-width: 768px) {
      width: 70%;
      padding-top: 60px;
    }

    @media(max-width: 576px) {
      width: 90%;
      padding-top: 40px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 28px;
    color: #262026;
    margin: 145px 0 50px 0;

    @media(max-width: 992px) {
      font-size: 29px;
      margin: 120px 0 40px 0;
    }

    @media(max-width: 768px) {
      font-size: 25px;
      margin: 100px 0 40px 0;
    }

    @media(max-width: 576px) {
      font-size: 22px;
      margin: 80px 0 40px 0;
    }
  }

  &__input {
    outline: none;
    width: 100%;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 18px 23px;
    margin-bottom: 20px;

    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: #858585;

      @media(max-width: 768px) {
        font-size: 15px;
      }

      @media(max-width: 576px) {
        font-size: 14px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #01C77F;
    text-align: end;
    transition: .2s linear;

    &:hover {
      color: rgb(31, 167, 117)
    }
  }

  &__button {
    outline: none;
    cursor: pointer;
    width: 100%;
    border: none;
    background: #01C77F;
    border-radius: 5px;
    padding: 16px 0;
    color: #FFFFFF;

    @media(max-width: 768px) {
      padding: 15px 0;
      font-size: 15px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
      padding: 14px 0;
    }
  }

  &__register {
    margin-top: 40px;
    font-size: 16px;
    line-height: 28px;
    color: #262026;

    @media(max-width: 992px) {
      margin-top: 30px;
    }

    @media(max-width: 768px) {
      font-size: 15px;
      text-align: center;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__register-link {
    color: #01C77F;
    font-weight: 500;
    transition: .2s linear;

    &:hover {
      color: rgb(31, 167, 117)
    }
  }

  &__right {
    margin-top: 230px;
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #C2C2C2;

    @media(max-width: 992px) {
      margin-top: 200px;
    }

    @media(max-width: 768px) {
      margin-top: 120px;
    }

    @media(max-width: 576px) {
      margin-top: 90px;
    }
  }
}

.sign-capabilities {
  background: linear-gradient(153.11deg, #0FF7A2 9.04%, #01C77F 93.59%);
  width: 50%;

  @media(max-width: 992px) {
    width: 100%;
  }

  &__box {
    width: 70%;
    padding-top: 90px;
    margin: 0 auto;

    @media(max-width: 1200px) {
      width: 90%;
    }

    @media(max-width: 992px) {
      width: 70%;
      padding: 80px 0 20px 0;
    }

    @media(max-width: 768px) {
      width: 80%;
    }

    @media(max-width: 576px) {
      width: 90%;
      padding: 40px 0 20px 0;
    }
  }

  &__inner {
    background: url(../images/sign-in/decoration.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__title {
    text-align: center;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;

    @media(max-width: 768px) {
      font-size: 16px;
      margin-bottom: 10px;
    }

    @media(max-width: 576px) {
      font-size: 15px;
    }
  }

  &__text {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    opacity: 0.8;

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }
}

.sign-in-box {
  display: flex;
  min-height: 100vh;
  overflow: hidden;

  @media(max-width: 992px) {
    display: block;
  }
}

.sign-in-owl .owl-dots {
  margin-top: 30px !important;
}

.sign-in-owl .owl-dot {
  outline: none;
}

.sign-in-owl .owl-dots .owl-dot.active span {
  outline: none;
  background: #FFFFFF;
  opacity: 1;
}

.sign-in-owl .owl-dots .owl-dot span {
  background: #FFFFFF;
  opacity: 0.3;
  width: 8px;
  height: 8px;
}

.title-2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  color: #262026;

  @media(max-width: 1200px) {
    font-size: 30px;
  }

  @media(max-width: 992px) {
    font-size: 29px;
  }

  @media(max-width: 768px) {
    font-size: 26px;
  }

  @media(max-width: 576px) {
    font-size: 23px;
  }
}

.help {
  text-align: center;

  &__title {
    margin: 80px 0 20px 0;

    @media(max-width: 992px) {
      margin: 60px 0 20px 0;
    }

    @media(max-width: 768px) {
      margin: 40px 0 15px 0;
    }

    @media(max-width: 576px) {
      margin: 30px 0 10px 0;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 21px;
    color: #C2C2C2;
    margin-bottom: 113px;

    @media(max-width: 992px) {
      font-size: 17px;
      margin-bottom: 90px;
    }

    @media(max-width: 768px) {
      font-size: 16px;
      margin-bottom: 60px;
    }

    @media(max-width: 576px) {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
}

.help-questions {
  margin-bottom: 150px;

  @media(max-width: 992px) {
    margin-bottom: 100px
  }

  @media(max-width: 768px) {
    margin-bottom: 70px
  }

  @media(max-width: 576px) {
    margin-bottom: 50px
  }

  &__theme {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #262026;
    margin-bottom: 30px;

    @media(max-width: 768px) {
      margin-bottom: 20px;
    }

    @media(max-width: 576px) {
      margin-bottom: 15px;
    }
  }

  &__theme-title {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__theme-link {
    font-size: 16px;
    line-height: 19px;
    color: #262026;
    transition: .2s linear;

    &:hover {
      color: #01C77F
    }

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__theme-active {
    font-weight: 500;
    color: #01C77F;
  }

  &__quest {
    width: 75%;
    font-size: 18px;
    line-height: 24px;
    color: #262026;
    padding-bottom: 20px;
    border-bottom: 1px solid #F5F5F5;
    margin-bottom: 18px;

    @media(max-width: 992px) {
      font-size: 17px;
    }

    @media(max-width: 768px) {
      font-size: 16px;
      width: 100%;

    }

    @media(max-width: 576px) {
      font-size: 15px;
    }
  }

  &__icon {
    margin-right: 25px;
  }

  &__list {
    display: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
    color: #525252;

    @media(max-width: 576px) {
      margin-top: 10px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #262026;

  @media(max-width: 992px) {
    font-size: 20px;
  }

  @media(max-width: 768px) {
    font-size: 18px;
  }

  @media(max-width: 576px) {
    font-size: 16px;
  }
}

.legal-information {
  margin-bottom: 150px;

  @media(max-width: 992px) {
    margin-bottom: 100px;
  }

  @media(max-width: 768px) {
    margin-bottom: 70px;
  }

  @media(max-width: 576px) {
    margin-bottom: 50px;
  }

  &__title {
    margin-bottom: 30px;

    @media(max-width: 768px) {
      margin-bottom: 20px;
    }

    @media(max-width: 576px) {
      margin-bottom: 15px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 30px;
    color: #525252;

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      @media(max-width: 576px) {
        margin-bottom: 10px;
      }
    }
  }
}

.create-request-box {
  text-align: end;
}

.create-request {
  &__header {
    display: flex;
    align-items: center;
  }

  &__button {
    display: block;
    text-align: end;

  }

  &__investment {
    margin-top: 44px;

    @media(max-width: 992px) {
      display: none;
    }
  }
}

.create-parameters {

  margin-top: 40px;
  border-radius: 0px 5px 5px 5px;
  padding-bottom: 40px;

  &--pb-none {
    padding-bottom: 0;
  }

  &__inner {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
    padding: 30px;
    border-radius: 5px;

    @media(max-width: 992px) {
      padding: 20px;
    }

    @media(max-width: 768px) {
      padding: 10px;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    color: #262026;
    margin-bottom: 20px;

    @media(max-width: 992px) {
      font-size: 16px;
    }

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__input-name-box {
    position: relative;
  }

  &__input-name-label {
    font-size: 11px;
    line-height: 13px;
    color: #858585;
    position: absolute;
    top: 9px;
    left: 23px;

    &:focus {
      color: #01C77F;
    }
  }

  &__input-name {
    height: 60px;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    width: 100%;
    padding: 25px 23px 10px 23px;
    margin-bottom: 20px;

    &:focus {
      outline: none;
      border-color: #01C77F;
    }

    &:focus::placeholder {
      color: #01C77F;
    }
  }

  &__input {
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    width: 100%;
    padding: 18px 23px;
    margin-bottom: 20px;

    &:focus {
      outline: none;
      border-color: #01C77F;
    }

    &:focus::placeholder {
      color: #01C77F;
    }
  }

  &__select {
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    width: 100%;
    padding: 18px 23px;
    font-size: 16px;
    line-height: 24px;
    color: #C2C2C2;
    margin-bottom: 20px;

    &:focus {
      border-color: #01C77F;
      color: #01C77F;
    }
  }

  &__textarea {
    resize: none;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    width: 100%;
    padding: 18px 23px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;

    &::placeholder {
      color: #C2C2C2;
    }

    &:focus {
      outline: none;
      border-color: #01C77F;
    }

    &:focus::placeholder {
      color: #01C77F;
    }
  }

  &__span {
    color: #858585;
    //font-style: italic;
  }

  &__file {
    text-align: center;
    padding: 18px 0;
    width: 100%;
    border: 1px dashed #C2C2C2;
    border-radius: 5px;
    margin-bottom: 30px;

    &:focus {
      outline: none;
      border-color: #01C77F;
    }

    &:focus::placeholder {
      color: #01C77F;
    }
  }

  &__input--wd {
    width: 50%;
    margin-right: 30px;
  }

  &__select--wd {
    width: 50%;
  }

  &__box {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.create-parameters__input-name:focus+.create-parameters__input-name-label {
  color: red;
}

.create-nav-link {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 768px) {
    margin-top: 20px;
  }

  @media(max-width: 576px) {
    flex-direction: column;
  }

  &--end {
    justify-content: flex-end;
  }

  &--mt-small {
    margin-top: 10px;
  }
}

.grey-btn {
  padding: 16px 30px;
  border-radius: 5px;
  border: 1px solid #F5F5F5;
  background: #F5F5F5;
  color: #525252;
  font-size: 16px;
  line-height: 19px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media(max-width: 992px) {
    font-size: 14px;
    padding: 14px 30px;
  }

  @media(max-width: 768px) {
    font-size: 13px;
    padding: 10px 30px;
  }

  @media(max-width: 576px) {
    margin-bottom: 15px;
  }
}

.green-btn {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #01C77F;
  border-radius: 5px;
  padding: 16px 30px;
  border: none;

  @media(max-width: 992px) {
    font-size: 14px;
    padding: 14px 30px;
  }

  @media(max-width: 768px) {
    font-size: 13px;
    padding: 10px 30px;
  }
}

.bottom-right {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #C2C2C2;

  @media(max-width: 992px) {
    font-size: 16px;
  }

  @media(max-width: 768px) {
    font-size: 14px;
  }

  @media(max-width: 576px) {
    font-size: 13px;
  }
}

.bottom-right--indent {
  margin: 248px 0 18px 0;

  @media(max-width: 992px) {
    margin: 150px 0 18px 0;
  }

  @media(max-width: 768px) {
    margin: 120px 0 18px 0;
  }

  @media(max-width: 576px) {
    margin: 90px 0 18px 0;
  }
}

.request-preview {
  padding: 30px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;

  @media(max-width: 768px) {
    padding: 20px;
  }

  @media(max-width: 576px) {
    padding: 15px;
  }

  &__header {
    display: flex;
    margin-bottom: 20px;
  }

  &__header-box {
    width: 100%;
    margin-left: 20px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #262026;

    @media(max-width: 992px) {
      font-size: 18px;
    }

    @media(max-width: 768px) {
      font-size: 16px;
    }

    @media(max-width: 576px) {
      font-size: 15px;
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media(max-width: 576px) {
      display: block;
    }
  }

  &__title-text {
    font-size: 20px;
    line-height: 24px;
    color: #858585;

    @media(max-width: 992px) {
      font-size: 18px;
    }

    @media(max-width: 768px) {
      font-size: 16px;
    }

    @media(max-width: 576px) {
      font-size: 15px;
    }
  }

  &__subtext {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #01C77F;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__about {
    font-size: 16px;
    line-height: 24px;
    color: #262026;
    margin-bottom: 20px;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__img-box {
    display: flex;
    margin-bottom: 20px;

    img {
      @media(max-width: 576px) {
        width: 32%;
        height: auto;
      }

      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

  }
}

.parameters {
  &__item {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    color: #525252;
    background: #F2F2F2;
    border-radius: 5px;
    padding: 7px 10px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @media(max-width: 768px) {
      display: block;
      margin-bottom: 10px;
      margin-right: 0;
    }

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }
}

.request-deadlines {
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #262026;

    @media(max-width: 576px) {
      font-size: 16px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: #858585;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }
}

.inquiry-investment {
  margin-top: 0;

  @media(max-width: 992px) {
    display: none;
  }
}

.application-execution {
  margin: 50px 0 150px 0;

  @media(max-width: 992px) {
    margin-bottom: 90px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
}

.application-item {
  transition: .2s linear;
  cursor: pointer;
  padding: 20px;
  //border: 1px solid #F5F5F5;
  text-align: center;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  &:hover {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.09);
  }

  &__active {
    border-color: #01C77F !important;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 20px 0 5px 0;

    @media(max-width: 768px) {
      font-size: 16px;
    }

    &--medium {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__message--active {
    color: #01C77F !important;
  }

  &__message {
    font-size: 14px;
    line-height: 20px;
    color: #858585;

    &--medium {
      font-size: 16px;
      line-height: 19px;
    }
  }

  @media(max-width: 1200px) {
    img {
      width: 90%;
      height: auto;
    }
  }

  @media(max-width: 576px) {
    padding: 20px;
  }

  &__img {
    width: 100%;
    max-height: 142px;
    object-fit: contain;
    margin: 0 auto;
    border-radius: 5px;
  }
}

.window-inquiry-dialog {
  display: none;
}

.inquiry-dialog {
  padding: 0;
  width: 770px;
  height: 838px;
  border-radius: 5px;

  @media(max-width: 992px) {
    width: 80%;
  }

  @media(max-width: 576px) {
    width: 95%;
  }

  &__inner {
    padding: 30px;
    height: 778px;

    @media(max-width: 768px) {
      padding: 20px;
    }
  }

  &__box {
    display: flex;

    @media(max-width: 576px) {
      display: block;
    }
  }

  &__header {
    width: 100%;
    display: flex;

    @media(max-width: 768px) {
      display: block;
    }
  }

  &__logo {
    display: inline-block;
    position: relative;
    height: 85px;
  }

  &__logo-shield {
    position: absolute;
    right: -8px;
    bottom: -8px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #262026;
    margin-bottom: 10px;

    @media(max-width: 992px) {
      font-size: 18px;
    }

    @media(max-width: 576px) {
      font-size: 15px;
    }
  }

  &__company {
    font-size: 16px;
    line-height: 19px;
    color: #525252;
    margin-bottom: 10px;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__about {
    margin-left: 20px;

    @media(max-width: 992px) {
      width: 50%;
    }

    @media(max-width: 768px) {
      width: 100%;
      margin-bottom: 15px;
    }

    @media(max-width: 576px) {
      margin-left: 0;
    }
  }

  &__reviews {
    display: flex;
  }

  &__reviews-stars {
    margin-right: 10px;
  }

  &__projects {
    font-size: 14px;
    line-height: 16px;
    color: #858585;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__executor-profile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 68%;

    @media(max-width: 768px) {
      width: 100%;
      ;
      margin-left: 20px;
      justify-content: flex-start;
    }

    @media(max-width: 576px) {
      margin-left: 0;
    }
  }

  &__message {
    margin-top: 30px;
  }

  &__message-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__message-head {
    display: flex;
    align-items: center;
  }

  &__message-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__message-time {
    color: #C2C2C2;
    font-size: 14px;
    line-height: 24px;
    margin-left: 10px;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__message-text {
    margin-top: 5px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #262026;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__message-images {
    margin-top: 10px;
    display: flex;

    img {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__form-box {
    display: flex;
    justify-content: flex-end;
  }

  &__form {
    display: flex;
    width: 100%;
  }

  &__textarea {
    resize: none;
    outline: none;
    background: #F5F5F5;
    border-radius: 0px 0px 5px 5px;
    border: 0;
    padding: 16px 30px;
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #262026;
    scrollbar-color: rgb(184, 183, 183) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(184, 183, 183);
      border-radius: 3px;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 20px;
      color: #858585;
    }
  }

  &__send-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    background: #01C77F;
    border: 0;
    width: 60px;
    height: 60px;
    border-radius: 0px 0px 5px 0px;
    transition: .2s linear;

    &:hover {
      background: rgb(31, 167, 117);
    }
  }
}

.green-text {
  color: #01C77F;
  font-weight: 500;
}

.public-records-item {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  cursor: pointer;
  padding: 20px;
  transition: .2s linear;

  &--grey {

    .public-records-item__time,
    .public-records-item__time-text {
      color: #404040;
    }
  }

  &--archive {
    border: 1px solid #B5B5B5;
  }

  &:hover {
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.09);
  }

  @media(max-width: 576px) {
    padding: 10px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #262026;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-y: hidden;

    @media(max-width: 768px) {
      font-size: 16px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__prof {
    padding: 3px 10px;
    display: inline-block;
    background: #01C77F;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    @media(max-width: 768px) {
      font-size: 13px;
    }

    &--grey {
      background: #B5B5B5;
    }

    &--no-bg {
      background: transparent;
      color: #B5B5B5;
      padding: 0;
      margin-top: 6px;

      @media (max-width: 576px) {
        margin-top: 0;
      }
    }
  }

  &__company {
    font-size: 14px;
    margin-left: 10px;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__time {
    font-size: 16px;
    line-height: 20px;
    color: #525252;

    &--dark-grey {
      color: #404040;
    }
  }

  &__time-text {
    font-size: 12px;
    line-height: 18px;
    color: #525252;
  }

  &__cost {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #262026;
    text-align: end;

    @media(max-width: 768px) {
      text-align: start;
    }

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__cost-text {
    font-size: 12px;
    line-height: 18px;
    color: #525252;
    text-align: end;

    @media(max-width: 768px) {
      text-align: start;
    }
  }

  &__notifications {
    background: #F03A31;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 10px;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__studio {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #01C77F;
  }

  &__active {
    border: 1px solid #01C77F;
  }

  &__star {
    cursor: pointer;
  }

  &__remove {
    width: 24px;
    height: 24px;
    background: #EDEDED;
    mask: url("../images/icons/delete.svg");
    -webkit-mask: url("../images/icons/delete.svg");
    @include mask-contain-center;
    transition: .2s linear;

    &:hover {
      background: $red;
    }
  }


}

.inquiry-executor--mb {
  margin-bottom: 300px;

  @media(max-width: 768px) {
    margin-bottom: 200px;
  }

  @media(max-width: 576px) {
    margin-bottom: 100px;
  }
}

.window-send-inquiry {
  display: none;
}

.modal-send-inquiry {
  padding: 30px;
  width: 770px;
  height: auto;
  border-radius: 5px;

  @media(max-width: 992px) {
    width: 80%;
  }

  @media(max-width: 768px) {
    padding: 20px;
    width: 90%;
  }

  @media(max-width: 576px) {
    width: 95%;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 40px;
    padding-right: 21px;

    @media(max-width: 768px) {
      font-size: 18px;
      margin-bottom: 30px;
    }

    @media(max-width: 576px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  &__textarea {
    outline: none;
    resize: none;
    width: 100%;
    border: 1px solid #C2C2C2;
    border-radius: 5px;
    padding: 18px 23px;
    margin-bottom: 20px;

    &::placeholder {
      color: #C2C2C2;
      font-size: 16px;

      @media(max-width: 576px) {
        font-size: 13px;
      }
    }

    &:focus {
      border-color: #01C77F;
    }

    &:focus::placeholder {
      color: #01C77F;
    }
  }

  &__file {
    outline: none;
    border: 1px dashed #C2C2C2;
    border-radius: 5px;
    padding: 18px;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;

    &::placeholder {
      color: #C2C2C2;
      font-size: 16px;

      @media(max-width: 576px) {
        font-size: 13px;
      }
    }

    &:focus::placeholder {
      color: #01C77F;
    }

    &:focus {
      border-color: #01C77F;
    }
  }

  &__button-box {
    display: flex;
    justify-content: space-between;

    @media(max-width: 576px) {
      flex-direction: column;
    }
  }
}

.area-head {
  display: flex;
  align-items: center;

  @media(max-width: 768px) {
    margin-bottom: 15px;
  }

  @media(max-width: 576px) {
    margin-bottom: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  &__logo {
    @media(max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  &__box {
    margin-left: 20px;

    @media(max-width: 576px) {
      margin-left: 0;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #262026;
  }

  &__company-name {
    font-size: 14px;
    line-height: 16px;
    color: #262026;
    margin-right: 20px;
  }

  &__company {
    margin-top: 14px;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  &__name {
    color: #858585;
    font-size: 14px;
    line-height: 16px;
  }
}

.personal-area {
  &__head {
    margin: 20px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 992px) {
      display: block;
    }
  }

  &__check-btn {
    display: inline-flex;
    color: #525252;
    font-size: 16px;
    line-height: 19px;
    background: #F5F5F5;
    border-radius: 5px;
    padding: 16px 20px;
    margin-right: 20px;

    img {
      margin-right: 13px;
    }

    @media(max-width: 992px) {
      margin-right: 0;
      margin-bottom: 15px;
      font-size: 14px;
    }
  }

  &__exit-btn {
    display: inline-flex;
    align-items: center;
    border: 1px solid #01C77F;
    border-radius: 5px;
    padding: 16px 23px;
    font-size: 16px;
    line-height: 19px;
    color: #01C77F;
    transition: .2s linear;

    i {
      font-size: 19px;
      margin-right: 10px;
    }

    img {
      margin-right: 13px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__buttons {
    @media(max-width: 992px) {
      display: flex;
      flex-direction: column;
    }
  }
}

.personal-area-title {
  display: flex;
  justify-content: space-between;
  background: #F5F5F5;
  border-radius: 5px;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #262026;

  &__archive-link {
    font-size: 18px;
    line-height: 21px;
    color: #C2C2C2;
    font-weight: 400;
    transition: .2s linear;

    &:hover {
      color: #262026;
    }

    @media(max-width: 768px) {
      font-size: 16px;
    }

    @media(max-width: 576px) {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  @media(max-width: 768px) {
    font-size: 16px;
  }

  @media(max-width: 576px) {
    font-size: 14px;
    flex-direction: column;
  }
}

.personal-area-menu {
  margin-bottom: 8px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;

    line-height: 24px;
    color: #424E66;
    transition: .2s linear;

    &:hover {
      background: #F2F2F2;
    }

    &--active {
      background: #4277FF !important;
      color: #fff;
    }

    &--active-green {
      background: $green !important;
      color: #fff;
    }

    &--active-red {
      background: $red !important;
      color: #fff;
    }

    &--notify {
      position: relative;
      margin-top: 16px;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #DEE2EA;
        position: absolute;
        top: -8px;
        left: 0;
      }
    }

  }

  &__item-active {
    background: #01C77F;
    color: #fff;
    cursor: default;

    &:hover {
      background: #01C77F;
    }
  }

  &__notifications {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #01C77F;
    width: 24px;
    height: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }
}

.personal-area-download {
  border-image: url(../images/back-download.svg) 1 1 4 1 fill stretch repeat;
  border-image-width: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
  padding: 36px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 154px;

  &__plus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    width: 75px;
    border: 1px solid #C2C2C2;
    border-radius: 100%;
  }

  &--center {
    padding: 0;
    border-image: url(../images/back-download.svg) 1 18 3 18 fill stretch repeat;
  }
}

.personal-area-messages {
  padding-bottom: 90px;
}

.personal-area-projects--footer {
  margin-top: 136px;

  @media(max-width: 768px) {
    margin-top: 110px;
  }

  @media(max-width: 576px) {
    margin-top: 90px;
  }
}

.contract-executor {
  &__label {
    left: 35px;
    top: 20px;
  }

  &__input {
    padding: 20px 23px 10px 23px !important;
  }

  &--height {

    height: 46%;
  }

  &--height-big {
    height: 84%;
  }

  @media (max-width: 992px) {
    height: auto;
    margin-bottom: 40px;
  }
}

.contract-customer {
  &--height {
    height: 46%;
  }

  @media (max-width: 992px) {
    height: auto;
    margin-bottom: 40px;
  }
}

.messages-navigation {
  &__nav {
    cursor: pointer;
    background: #F5F5F5;
    border-radius: 10px 10px 0px 0px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    color: #525252;
    transition: .2s linear;

    &:hover {
      color: #fff;
      background: #01C77F;
    }

    @media(max-width: 992px) {
      border-radius: 5px;
    }
  }

  &__active {
    background: #01C77F;
    color: #fff;
    cursor: default;
  }

  &__notifications {
    margin-left: 10px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}

/*
.notifications {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  background: #F03A31;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 576px) {
    font-size: 13px;
  }
}
 */

.messages-area {
  border-radius: 0px 0px 5px 5px;

  &__input-box {
    border: 1px solid #F5F5F5;
    display: flex;
    padding: 22px 23px;
  }

  &__input {
    margin-left: 13px;
    outline: none;
    width: 100%;
    border: none;

    &::placeholder {
      font-size: 14px;
      line-height: 16px;
      color: #858585;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 270px 1fr;
    border-radius: 5px;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }
  }

  &--shadow-rounded {
    border-radius: 5px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

    .messages-area__select {
      border-radius: 5px 0 0 5px;
    }

    .messages-area__input-box {
      border-radius: 5px 0 0 0;
    }

    .messages-text {
      border-radius: 0 5px 0 0;
    }

    .messages-text__search {
      border-radius: 0 5px 0 0;
    }
  }
}

.messages-area--style {
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

.messages-area--border {
  border-right: 1px solid #F5F5F5;
}

.messages-company {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: relative;

  @media (max-width: 576px) {
    padding: 10px 15px;
  }

  &__active {
    background: #01C77F;
  }

  &__logo {
    display: inline-flex;
    position: relative;
  }

  &__shield {
    position: absolute;
    right: 0;
    bottom: -5px;
  }

  &__name {
    margin-left: 10px;
  }

  &__title {
    font-size: 14px;
    line-height: 16px;
    color: #262026;
    margin-bottom: 8px;
  }

  &__title-active {
    color: #fff;
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    color: #858585;
  }

  &__text-active {
    color: #fff;
  }

  &__unread {
    position: absolute;
    width: 8px;
    height: 8px;
    background: #F03A31;
    border-radius: 100%;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.messages-text {
  border: 1px solid #F5F5F5;
  border-bottom: none;
  border-top: none;
  height: 90%;

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s linear;
  }

  &__form {
    &--style {
      padding: 0 20px;
    }

    border-bottom: 1px solid #F5F5F5;
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      padding: 0;
      outline: none;
      cursor: pointer;
      border: none;
      background: none;
    }
  }

  &__input {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #262026;
    outline: none;
    border: none;
    width: 95%;

    &--style {
      height: 60px;
    }
  }

  &__search-button {
    &:hover {
      background: #F5F5F5;
    }

    border: 1px solid #F5F5F5;
    border-radius: 0px 5px 0px 0px;
    cursor: pointer;
    transition: .2s linear;
    padding: 16px;
    outline: none;
    background: none;
  }

  &__button {
    font-size: 14px;
    line-height: 24px;
    color: #525252;
    text-align: center;
    padding: 18px 20px;
    width: 100%;
    background: #F5F5F5;
    border-radius: 0px 0px 5px 0px;
    display: block;
    transition: .2s linear;

    &:hover {
      background: rgb(236, 236, 236);
    }

    @media(max-width: 768px) {
      padding: 16px 20px;
    }

    @media(max-width: 576px) {
      font-size: 13px;
      padding: 15px 20px;
    }
  }

  &__link {
    border: 1px solid #F5F5F5;
    border-right: none;
    padding: 17px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    transition: .2s linear;
    font-size: 14px;
    color: #525252;

    &:hover {
      background: #F5F5F5;
      color: #525252;
    }
  }
}

.messages-text-area {
  padding: 20px;
  //height: 590px;
  height: 100%;
  overflow-y: auto;
  scrollbar-color: #F5F5F5 transparent;
  scrollbar-width: thin;

  &--medium {
    height: calc(100% - 52px);
  }

  @media (max-width: 576px) {
    padding: 15px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #F5F5F5;
    border-radius: 3px;
  }

  &__item {
    margin-bottom: 20px;
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #262026;
  }

  &__title-green {
    color: #01C77F;
  }

  &__time {
    font-size: 14px;
    line-height: 24px;
    color: #C2C2C2;
    margin-left: 10px;
  }

  &__text {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #262026;
    margin-bottom: 10px;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__images {
    display: flex;

    img {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__files {
    display: flex;
    flex-wrap: wrap;
  }

  &__file {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    display: flex;
    width: calc(50% - 10px);
    height: 52px;

    @media(max-width: 768px) {
      width: 150px;
    }

    @media(max-width: 576px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__file-name {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-size: 14px;
    line-height: 24px;
    color: #525252;
  }

  &__format {
    width: 52px;
    height: 52px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #01C77F;
    background: #E4FFF0;
    border-radius: 5px;
  }
}

.messages-send {
  &__form {
    display: flex;

  }

  &__textarea {
    resize: none;
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    padding-top: 15px;
    overflow: auto;
    scrollbar-color: rgb(184, 183, 183) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(184, 183, 183);
      border-radius: 3px;
    }

    &::placeholder {
      @media(max-width: 576px) {
        font-size: 14px;
      }
    }
  }

  &__form-box {
    padding: 0 20px;
    width: 100%;
    background: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 0px 5px;

    button {
      &:hover {
        opacity: 0.7;
      }

      transition: .2s linear;
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
      background: none;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
    width: 60px;
    height: 60px;
    background: #01C77F;
    border-radius: 0px 0px 5px 0px;
    transition: .2s linear;

    &:hover {
      background: rgb(31, 167, 117);
    }
  }
}

.pesonal-area--bottom-right {
  margin: 100px 0 18px 0;

  @media(max-width: 768px) {
    margin-top: 70px;
  }

  @media(max-width: 576px) {
    margin-top: 50px;
  }
}

.personal-notifications {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  transition: .2s linear;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px 15px 15px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  transition: all .2s linear;

  &:hover {
    svg {
      fill: #01C77F;
    }

    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.09);
  }

  &__box {
    margin-left: 15px;
    padding-right: 20px;

    @media(max-width: 768px) {
      margin-left: 0;
      padding-right: 0;
    }
  }

  &__logo {
    display: inline-block;

    @media(max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  &__inner {
    display: flex;

    @media(max-width: 768px) {
      padding-right: 25px;
      flex-direction: column;
    }
  }

  &__name {
    font-size: 14px;
    line-height: 16px;
    color: #262026;
    margin-bottom: 8px;
  }

  &__text {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #262026;

    span {
      color: #01C77F;
      font-weight: 500;
    }
  }

  &__link {
    svg {
      transition: all .2s linear;
    }

    justify-content: flex-end;
    display: flex;
  }
}

.safety {
  margin: 0 -15px 70px -15px;
  padding: 30px 20px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 5px 5px;
  height: 670px;

  @media(max-width: 992px) {
    height: 80%;
  }

  @media(max-width: 576px) {
    padding: 20px 15px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
    color: #000000;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__sign {
    margin-bottom: 30px;
  }

  &__input {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    font-size: 16px;
    line-height: 24px;
    color: #262026;
    outline: none;
    width: 100%;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 10px 23px;
    height: 60px;

    @media(max-width: 576px) {
      font-size: 14px;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: #C2C2C2;

      @media(max-width: 576px) {
        font-size: 14px;
      }
    }
  }

  &__toggle {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    font-size: 16px;
    line-height: 24px;
    color: #525252;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 576px) {
      font-size: 14px;
      margin-bottom: 15px;

      img {
        padding-left: 10px;
        ;
      }
    }
  }

  &__item-logo {
    margin-bottom: 20px;
  }

  &__data {
    margin-top: 30px;
  }

  &__data-input {
    width: 50% !important;
    margin-right: 50px;

    &:last-child {
      margin-right: 0;
    }

    @media(max-width: 576px) {
      margin-bottom: 15px;
    }
  }

  &__data-form {
    display: flex;

    @media(max-width: 576px) {
      flex-direction: column;
    }
  }

  &__contacts-box {
    display: flex;

  }

  &__contacts-btn {
    flex-shrink: 0;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    width: 60px;
    height: 60px;
    background: #F5F5F5;
    border-radius: 5px;
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    color: #858585;
  }

  &__link {
    color: #01C77F;
    transition: .2s linear;
    display: inline-block;

    &:hover {
      color: rgb(3, 160, 102);
    }
  }

  &__box {
    justify-content: space-between;
    display: flex;

    @media(max-width: 992px) {
      flex-direction: column;
    }
  }

  &__button {
    margin-top: 15px;
  }

  &--indent {
    margin-bottom: 23px;
  }

}

.list-item {
  width: 100%;
}

.safety-section {
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__inner {
    //height: 586px;
    height: 520px;
    overflow-y: auto;
  }

  &__input {
    outline: none;
    background: #01C77F;
    border-radius: 5px 5px 0px 0px;
    border: none;
    width: 100%;
    height: 50px;
    padding: 13px 20px;
    font-size: 14px;
    color: #fff;

    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      color: #fff;
    }
  }

  &__control {
    align-items: flex-end;
    display: flex;
    margin-top: auto;

    @media(max-width: 992px) {
      justify-content: center;
    }
  }

  &__control-btn {
    font-size: 15px;
    color: #858585;
    transition: .2s linear;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F5F5F5;
    border-radius: 2px;
    width: 65px;
    height: 40px;

    @media(max-width: 992px) {
      width: 100%;
    }

    &:hover {
      background-color: #01C77F;
      color: #fff;
      border-color: #01C77F;
    }
  }

  &__btn-active {
    color: #fff;
    border-color: #01C77F;
    background-color: #01C77F;
  }
}

.safety--border {
  border-right: 1px solid #F5F5F5;
}

.style-input {
  outline: none;
  height: 60px;
  padding: 10px 23px;
  width: 100%;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  font-size: 15px;
  line-height: 24px;
  color: #262026;

  &:focus {
    border-color: #01C77F;
  }

  &::placeholder {
    color: #C2C2C2;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  @media(max-width: 576px) {
    font-size: 14px;
  }
}

.textarea {
  //border: none;
  border: 1px solid #DEE2EA;
  color: #161616;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  outline: none;
  width: 100%;
  resize: none;
  border-radius: 8px;
  //transition: 0.2s linear;
  padding: 6px 10px 3px 10px;

  &::-webkit-scrollbar {
    //width: 0;
  }

  &--height {
    height: 65%;
  }

  &--height-big {
    height: 100%;
  }

  &:disabled {
    color: #424E66;
    background: #E9ECF1;
    border: 1px solid #DEE2EA;
  }
}

.textarea--border {
  border: 1px solid #F5F5F5;
}

.textarea--indent {
  padding: 10px 15px 5px 15px;
}

.textarea--indent-big {
  padding: 18px 30px 18px 23px;
}

.textarea--grey {
  color: #525252;
}

.textarea--black {
  color: #262026;
}

.textarea--mb {
  margin-bottom: 17px;
}

.textarea--big {
  font-size: 16px;
}

.style-textarea {
  font-size: 16px;
  line-height: 24px;
  color: #262026;
  outline: none;
  resize: none;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #F5F5F5;
  padding: 25px 23px 10px 23px;

  &::placeholder {
    color: #C2C2C2;

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &:focus {
    border-color: #01C77F;
  }

  @media(max-width: 576px) {
    font-size: 14px;
  }
}

.style-select {
  width: 100%;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  padding: 18px 23px;
  outline: none;
  color: #C2C2C2;

  &:focus {
    border-color: #01C77F;
  }

  @media(max-width: 576px) {
    font-size: 14px;
  }
}

.safety--mb {
  margin-bottom: 10px;
}

.safety--mb15 {
  margin-bottom: 15px;
}

.right-contract--style {
  margin: 70px 0 18px 0;
  font-size: 18px;

  @media(max-width: 768px) {
    font-size: 16px;
    margin-top: 60px;
  }

  @media(max-width: 576px) {
    font-size: 14px;
    margin-top: 50px;
  }
}

.contract-nav {
  &__item {
    cursor: pointer;
    padding: 17px 5px;
    background: #F5F5F5;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #525252;
    font-size: 14px;
    line-height: 16px;
    transition: .2s linear;

    i {
      transition: .2s linear;
      color: #C2C2C2;
      margin-right: 10px;
    }

    @media(max-width: 1200px) {
      font-size: 13px;
    }

    @media(max-width: 992px) {
      border-radius: 5px;
    }

    &:hover {
      i {
        color: #01C77F !important;
      }

      background: #E4FFF0;
      color: #01C77F;
    }

    &--active {
      &:hover {
        i {
          color: #fff !important;
        }

        background: #01C77F;
        color: #fff !important;
      }

      i {
        color: #fff;
      }

      cursor: default;
      background: #01C77F;
      color: #fff;
    }

  }
}

.title-3 {
  font-size: 18px;
  line-height: 24px;
  color: #262026;

  @media(max-width: 768px) {
    font-size: 17px;
  }

  @media(max-width: 576px) {
    font-size: 16px;
  }
}

.safety-patterns {
  margin-top: 24px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;

    @media(max-width: 992px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__nav {
    @media(max-width: 992px) {
      margin-top: 20px;
    }

    @media(max-width: 576px) {
      flex-direction: column;
      width: 100%;
    }

    display: flex;
  }

  &__nav-active {
    background: #01C77F !important;
    color: #FFFFFF !important;
  }

  &__text {
    font-size: 14px;
    line-height: 22px;
    color: #858585;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__button {
    transition: .2s linear;

    &:hover {
      background: #F5F5F5;
      color: #858585;
    }

    transition: .2s linear;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 576px) {
      margin-right: 0;
    }

  }
}

.patterns-executor {
  &__nav-box {
    border: 1px solid #F5F5F5;
    border-radius: 5px;
  }

  &__nav {
    cursor: pointer;
    height: 40px;
    padding: 12px 20px;
    background: #FFFFFF;
    font-size: 14px;
    line-height: 16px;
    color: #525252;

    &.active {
      &:hover {
        background: #F5F5F5;
      }

      cursor: default;
      background: #F5F5F5;
    }
  }

  &__search {
    display: flex;
    margin-bottom: 10px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: none;
    transition: .2s linear;

    &:hover {
      background: rgb(235, 235, 235);
    }
  }

  &__form {
    align-items: center;
    display: flex;
    width: 100%;
  }

  &__input-box {
    margin-right: 10px;
    height: 40px;
    padding: 0 17px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #F5F5F5;
    border-radius: 5px;

    img {
      flex-shrink: 0;
      margin-right: 12px;
      width: 13px;
      height: 13px;
    }

    input {
      outline: none;
      border: none;
      width: 100%;
      font-size: 14px;

      &::placeholder {
        font-size: 14px;
        line-height: 16px;
        color: #858585;
      }
    }
  }

  &__item {
    &:hover {
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.09);
    }

    transition: .2s linear;
    cursor: pointer;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #FCFCFC;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 10px 15px;

    @media(max-width: 768px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__selected {
    border: 1px solid #01C77F;
  }

  &__item-title {
    font-size: 14px;
    line-height: 16px;
    color: #262026;
    margin-bottom: 5px;
  }

  &__item-inner {
    width: 65%;

    @media(max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  &__item-standart {
    font-size: 12px;
    line-height: 14px;
    color: #858585;
  }

  &__item-box {
    width: 100%;
    margin-right: 10px;
    text-align: end;

    @media(max-width: 768px) {
      text-align: start;
      margin-bottom: 15px;
    }
  }

  &__item-text {
    font-size: 12px;
    line-height: 14px;
    color: #C2C2C2;
  }
}

.template-composition {
  width: 770px;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  background: #FFFFFF;

  &__title {
    display: inline-block;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 28px;

    @media(max-width: 768px) {
      font-size: 18px;
      margin-bottom: 15px;
    }

    @media(max-width: 576px) {
      font-size: 16px;
    }
  }

  &__list {
    font-size: 14px;
    line-height: 24px;
    color: #858585;
  }

  &__list-title {
    color: #262026;
  }

  @media(max-width: 992px) {
    width: 630px;
  }

  @media(max-width: 768px) {
    width: 85%;
    padding: 20px;
  }
}

.template-company {
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  background: #FFFFFF;
  padding: 20px;

  @media(max-width: 768px) {
    padding: 15px;
  }

  &__box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 6px;
    color: #262026;

    @media(max-width: 768px) {
      font-size: 16px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__head {
    margin-left: 10px;
  }

  &__scope {
    font-size: 11px;
    line-height: 13px;
    color: #262026;
    margin-bottom: 6px;
  }

  &__projects {
    font-size: 11px;
    line-height: 13px;
    color: #858585;
  }

  &__description {
    font-size: 12px;
    line-height: 18px;
    color: #262026;
    margin-bottom: 15px;
  }

  &__competenties-item {
    margin-right: 6px;
    margin-bottom: 6px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    display: inline-block;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 10px;
    line-height: 12px;
    color: #525252;
  }

  &__button {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    background: #F5F5F5;
    border-radius: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #525252;

    @media(max-width: 576px) {
      font-size: 13px;
      padding: 12px 0;
    }
  }

  &__buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    @media(max-width: 576px) {
      margin-top: 15px;
      flex-direction: column;
    }
  }
}

.information-edit {
  padding: 18px 20px;
  border: 1px solid #F5F5F5;
  border-radius: 5px;

  &__text {
    span {
      font-weight: 500;
      margin-left: 5px;
    }

    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    color: #525252;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    margin-left: 10px;
    font-size: 12px;
    line-height: 18px;
    color: #01C77F;
    transition: .2s linear;

    &:hover {
      color: rgb(2, 167, 106);
    }
  }

  &__text-signature--green {
    color: #01C77F !important;
  }

  &__text-signature {
    &:last-child {
      margin-bottom: 0;
    }

    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #C2C2C2;
  }
}

.discussion-section {
  @media(max-width: 1200px) {
    width: 100%;
  }

  border: 1px solid #F5F5F5;
  border-radius: 5px;

  &__inner {
    height: 325px;
    overflow: auto;
    padding: 10px 20px 0px 20px;
    scrollbar-color: #F5F5F5 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #F5F5F5;
      border-radius: 3px;
    }

    &--height {
      height: 390px;
    }
  }

  &__head {
    padding: 15px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #525252;
    border-bottom: 1px solid #F5F5F5;
  }

  &__input-box {
    textarea {
      font-size: 12px;
      margin-right: 5px;
      resize: none;
      outline: none;
      width: 100%;
      display: flex;
      background: #FCFCFC;
      align-items: center;
      border: none;

      &::placeholder {
        color: #C2C2C2;
        font-size: 12px;
        line-height: 18px;
      }
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      padding: 0;
    }

    background: #FCFCFC;
    padding: 13px 20px;
    height: 50px;
    border: 1px solid #F5F5F5;
    border-radius: 0px 0px 5px 5px;
    display: flex;
  }
}

.contract-btn-edit {
  background: #F5F5F5;
  border-radius: 5px;
  display: inline-block;
  border: none;
  cursor: pointer;
  padding: 11px 30px;
  font-size: 16px;
  line-height: 19px;
  color: #525252;
}

.contract-customer--mt {
  //margin-top: 40px;
  margin-top: auto;

  @media (max-width: 992px) {
    margin-top: 40px;
  }
}

.subsections {
  &__item {
    &.active {
      background: #F5F5F5;
      cursor: default;

      &:hover {
        background: #F5F5F5;
      }
    }

    &.green {
      background: #01C77F;
      color: #fff;
      cursor: default;

      &:hover {
        color: #fff;
        background: #01C77F;
      }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 10px 8px 20px;
    font-size: 12px;
    line-height: 24px;
    color: #858585;
    transition: .2s linear;

    &:hover {
      background: #F5F5F5;
    }
  }

  &__item-active {
    color: #525252;
    background: #F5F5F5;
  }

  &__item--active-green {
    background: #01C77F;
    color: #fff;
  }

  &__input-box {
    background: #FCFCFC;
    border-bottom: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 0 20px;
    height: 40px;
    display: flex;
    justify-content: space-between;

    input {
      background: transparent;
      border: none;
      outline: none;

      &::placeholder {
        font-size: 12px;
        line-height: 24px;
        color: #525252;
      }
    }

    button {
      outline: none;
      cursor: pointer;
      border: none;
      background: none;
    }
  }
}

.division {
  &__item {
    &--first {
      border-radius: 5px;
    }

    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    color: #525252;
    padding: 13px 10px 13px 30px;
    display: flex;
    align-items: center;
    transition: .2s linear;

    &.active {
      background: #01C77F;
      color: #fff;
      cursor: default;

      &:hover {
        background: #01C77F;
        color: #fff;
      }
    }

    &:hover {
      background: #E4FFF0;
    }
  }
}

.modal-how-fill {
  display: none;
}

.how-fill-contract {
  cursor: pointer;
  display: flex;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  padding: 18px 20px;

  a {
    font-size: 12px;
    line-height: 18px;
    color: #01C77F;
    transition: .2s linear;
    margin-left: 10px;

    &:hover {
      color: rgb(1, 165, 105);
    }
  }
}

.discussion-chat {
  &__item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  &__title {
    font-size: 12px;
    line-height: 18px;
    color: #262026;
  }

  &__title--red {
    color: #F03A31;
  }

  &__time {
    color: #C2C2C2;
    font-size: 10px;
    line-height: 16px;
    margin-left: 10px;
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    color: #262026;
  }
}

.how-fill {
  width: 770px;
  border-radius: 5px;
  padding: 30px;

  @media(max-width: 992px) {
    width: 630px;
  }

  @media(max-width: 768px) {
    width: 95%;
  }

  &__title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;

    @media(max-width: 768px) {
      font-size: 18px;
      margin-bottom: 20px;
    }

    @media(max-width: 576px) {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &--medium {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      margin-bottom: 0;
    }

    &--grey {
      color: #7C7C7C;
      margin-left: 10px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    color: #262026;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @media(max-width: 576px) {
      font-size: 13px;
    }

    &--mb {
      margin-bottom: 30px;
    }
  }

  &__subtitle {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #262026;
  }

  &__links {
    margin-top: 140px;
    display: flex;
    justify-content: flex-end;

    @media(max-width: 576px) {
      margin-top: 100px;
      flex-direction: column;
    }

    &--mt {
      margin-top: 20px;
    }
  }

  &__btn {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__info {
    height: 447px;
    overflow-y: auto;
    padding-right: 15px;

    &::-webkit-scrollbar {
      width: 18px;
    }

    &::-webkit-scrollbar-track {
      background: #F6F6F6;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #D7D7D7;
      border-radius: 3px;
    }
  }

  &__overhead {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
}

.input-edit {
  width: 100%;
  height: 50px;
  padding: 3px 5px;
  outline: none;
  font-size: 14px;
  border: 11px solid #01C77F;

  &::placeholder {
    font-size: 14px;
    line-height: 24px;
    color: #858585;
  }
}

.fa-trash {
  &:hover {
    color: #F03A31;
  }

  transition: color .2s linear;
  color: #C2C2C2;
  font-size: 14px;
  width: 10px;
  height: 13px;
}

.modal-remove-window {
  display: none;
}

.remove-window {
  padding: 30px;
  width: 370px;
  border-radius: 5px;
  text-align: center;

  @media(max-width: 576px) {
    width: 90%;
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #262026;

    @media(max-width: 768px) {
      font-size: 16px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
    color: #262026;

    @media(max-width: 576px) {
      font-size: 13px;
    }
  }

  &__links {
    display: flex;

    @media(max-width: 576px) {
      flex-direction: column;
    }
  }

  &__btn {
    cursor: pointer;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    border-radius: 5px;
    width: 145px;
    display: inline-block;
    font-size: 16px;
    line-height: 19px;
    color: #525252;
    padding: 10px 0;

    @media(max-width: 576px) {
      width: 100%;
      font-size: 14px;
      padding: 8px 0;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.blue-btn--style {
  transition: .2s linear;

  &:hover {
    background: rgb(37, 97, 194);
  }
}

.white-btn--hover {
  transition: .2s linear;

  &:hover {
    svg {
      fill: #fff;
    }

    color: #fff;
    background: #01C77F;
  }
}

.red-btn--hover {
  transition: .2s linear;

  &:hover {
    background: rgb(218, 54, 46);
  }
}

.green-btn--style {
  &:hover {
    background: rgb(31, 167, 117);
  }

  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  transition: .2s linear;
  background: #01C77F;
  color: #fff;

  @media(max-width: 768px) {
    font-size: 15px;
  }

  @media(max-width: 576px) {
    font-size: 14px;
    width: 100%;
  }
}

.grey-btn--style {
  &:hover {
    background: rgb(238, 238, 238);
  }

  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  transition: .2s linear;
  background: #F5F5F5;
  color: #525252;

  @media(max-width: 768px) {
    font-size: 15px;
  }

  @media(max-width: 576px) {
    font-size: 14px;
  }
}

.window-save-patterns {
  display: none;
}

.save-patterns {
  width: 570px;
  text-align: center;
  padding: 30px;
  border-radius: 5px;

  @media(max-width: 768px) {
    width: 480px;
  }

  @media(max-width: 576px) {
    width: 90%;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #262026;
    margin-bottom: 20px;

    @media(max-width: 768px) {
      font-size: 16px;
      margin-bottom: 15px;
    }

    @media(max-width: 576px) {
      font-size: 14px;
    }
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    @media(max-width: 576px) {
      flex-direction: column;
    }
  }

  &__btn {
    border-radius: 5px;
    padding: 11px 30px;
    font-size: 16px;
    line-height: 19px;

    @media(max-width: 768px) {
      font-size: 15px;
    }

    @media(max-width: 576px) {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      font-size: 14px;
      width: 100%;
    }
  }
}

.fa-check {
  color: #01C77F !important;
}

.timeline {
  position: relative;

  &__item {
    position: relative;
    z-index: 11;
    margin-right: 33px;

    &:last-child {
      margin-right: 0;
    }

    display: inline-block;
    text-align: center;

    @media(max-width: 1200px) {
      margin-right: 20px;
    }

    @media(max-width: 992px) {
      margin-bottom: 10px;
      margin-right: 30px;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #e5e5e5;
    }

    @media(max-width: 576px) {
      margin-right: 20px;
    }
  }

  &__date {
    font-size: 10px;
    line-height: 16px;
    color: #858585;
    margin-bottom: 7px;
  }

  &__circle {
    &:hover {
      background: #E4FFF0;
    }

    transition: .2s linear;
    background: #fff;
    cursor: pointer;
    margin: 0 auto;
    width: 15px;
    height: 15px;
    border: 2px solid #01C77F;
    border-radius: 100%;
    margin-bottom: 7px;
  }

  &__circle-active {
    background: #01C77F;
  }

  &__time {
    font-size: 10px;
    line-height: 16px;
    color: #C2C2C2;
  }

  &__box {

    @media (max-width: 992px) {
      margin-right: -20px;
      overflow: hidden;
    }

    &:after {
      @media(max-width: 992px) {
        display: none;
      }

      left: 7px;
      z-index: 0;
      position: absolute;
      content: '';
      height: 3px;
      width: 94%;
      top: 50%;
      background: #F5F5F5;

      @media(max-width: 1200px) {
        width: 74%;
      }

    }

    &--flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      flex-wrap: wrap;

      &::after {
        width: calc(100% - 15px);
      }

      @media (max-width: 992px) {
        justify-content: flex-start;
      }
    }
  }
}

.safety-btn {
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  padding: 11px 30px;
  border-radius: 5px;

  @media(max-width: 768px) {
    font-size: 15px;
  }
}

.contract-main--input {
  border-radius: 5px;
}

.contract-subsections {
  //height: 374px;
  height: 68%;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  overflow-y: auto;

  @media (max-width: 576px) {
    height: auto;
  }
}

.button-spoiler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #01C77F;
  border-radius: 5px;
  padding: 13px 20px;
  font-size: 16px;
  line-height: 24px;
  color: #525252;
  transition: .2s linear;
  width: 100%;

  &:hover {
    border: 1px solid #01C77F;
    background: #E4FFF0;
  }

  &--active {
    &:hover {
      color: #fff;
      background: #01C77F;
    }

    cursor: default;
    color: #fff;
    background: #01C77F;
  }

  &--complete {
    border: 1px solid #01C77F;
    background: #E4FFF0;
  }

  @media(max-width: 1200px) {
    font-size: 14px;
  }
}

.contract-discussion {
  height: 510px;
  overflow: auto;
  padding: 10px 20px 0px 20px;
  scrollbar-color: #F5F5F5 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #F5F5F5;
    border-radius: 3px;
  }
}

.safety__contract-customer {
  margin-top: 29px;
}

.spoiler-list {
  border: 1px solid #F5F5F5;
  border-radius: 5px 5px 0 0;
  height: 374px;

  &__nav {
    display: flex;
    align-items: flex-end;
  }

  &__button {
    font-size: 14px;
    color: #858585;
    cursor: pointer;
    height: 40px;
    width: 50%;
    background: #FCFCFC;
    border: 1px solid #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s linear;

    &:hover {
      background: rgb(236, 236, 236);
    }
  }

  &__item {
    &.active {
      background: #F5F5F5;
    }

    cursor: pointer;
    padding: 8px 20px;
    font-size: 12px;
    line-height: 24px;
    color: #525252;
  }
}

.calendar-checkbox-label {
  margin-top: 10px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  color: #525252;
}

.formalization-img {
  overflow-y: auto;
  height: 56%;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 992px) {
    height: 100%;
  }
}

.delivery {
  &__title {
    font-size: 16px;
    line-height: 24px;
    color: #525252;
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #858585;
  }
}

.delivery-wrapper {
  margin-top: auto;

  @media (max-width: 992px) {
    margin-top: 40px;
  }
}

.step {
  border: 1px solid #F5F5F5;
  border-radius: 5px;

  &__inner {
    padding: 10px 20px 20px 20px;
  }
}

.header-step {
  font-size: 18px;
  line-height: 21px;
  color: #525252;
  background: #EDEDED;
  border-radius: 5px 5px 0 0;
  padding: 15px 20px;

  &--rounded {
    border-radius: 5px;
  }

  @media(max-width: 768px) {
    font-size: 16px;
  }

  @media(max-width: 576px) {
    font-size: 14px;
  }
}

.document-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 0;
  background: #F6F6F6;
  border-radius: 5px;

  @media(max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__header {
    cursor: pointer;
    align-items: center;
    display: flex;

    @media(max-width: 576px) {
      margin-bottom: 15px;
    }
  }

  &__format {
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    background: #E4FFF0;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #01C77F;
  }

  &__text {
    margin-left: 12px;
    font-size: 14px;
    line-height: 24px;
    color: #525252;
  }

  &__icons {
    display: flex;

    a {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 576px) {
      padding: 0 0 10px 10px;
    }
  }

  &__icon {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.8;
    }

    transition: .2s linear;
    width: 20px;
    font-weight: 300;
    color: #C2C2C2;
    font-size: 20px;
  }
}

.icon-green {
  color: #01C77F !important;
}

.modal-formalization {
  display: none;
}

.window-formalization {
  padding: 30px;
  width: 770px;
  background: #FFFFFF;
  border-radius: 5px;

  @media(max-width: 992px) {
    width: 700px;
  }

  @media(max-width: 768px) {
    width: 95%;
  }

  &__img {
    height: 500px;
    overflow-x: auto;
    scrollbar-color: #F5F5F5 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;
    }

    @media(max-width: 576px) {
      height: 300px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #F5F5F5;
      border-radius: 3px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    @media(max-width: 576px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__btn {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.modal-courier {
  display: none;
}

.courier {
  padding: 30px;
  width: 770px;
  background: #FFFFFF;
  border-radius: 5px;

  @media(max-width: 992px) {
    width: 700px;
  }

  @media(max-width: 768px) {
    width: 90%;
  }

  @media(max-width: 576px) {
    width: 95%;
  }

  &__input {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    display: flex;
    align-items: center;
  }

  &__subtitle {
    margin-bottom: 15px;
  }

  &__date-box {
    padding: 18px 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
  }

  &__date--input {
    font-size: 16px;
    line-height: 24px;
    width: 90%;
    outline: none;
    border: none;

    &::placeholder {
      color: #C2C2C2;
    }
  }

  &__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    @media(max-width: 576px) {
      flex-direction: column;
    }
  }

  &__btn {
    @media(max-width: 576px) {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    padding: 11px 30px;
    display: inline-block;
  }
}

.modal-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 30px;

  @media(max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
  }

  @media(max-width: 768px) {
    font-size: 16px;
  }
}

.modal-subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #262026;
}

.grey-label {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  color: #525252;
  margin-left: 10px;
  display: block;
  background: #F5F5F5;
  border-radius: 5px;
  padding: 8px 20px;
  width: 90%;
  left: 25px;
}

.checkbox-courier {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-courier {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.checkbox-courier+label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #F5F5F5;
  border-radius: 2px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  position: absolute;
  left: -34px;
  transition: .2s linear;
}

.checkbox-courier:checked+label::before {
  background-color: #fff;
  background-image: url(../images/icons/check-green.svg);
}

.checkbox-courier:not(:disabled):not(:checked)+label:hover::before {
  border-color: rgba(15, 247, 162, 0.479);
}

.white-input {
  outline: none;
  width: 100%;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  padding: 18px 23px;
  font-size: 16px;
  line-height: 24px;

  &::placeholder {
    color: #C2C2C2;
  }
}

.step-bill {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 576px) {
      flex-direction: column;
    }
  }

  &__bill {
    color: #262026;
    font-size: 14px;
    line-height: 24px;
    width: 65%;
    background: #FCFCFC;
    border-radius: 5px;
    padding: 14px 20px;

    @media(max-width: 576px) {
      width: 100%;
      text-align: center;
    }
  }

  &__date {
    padding: 14px 35px;
    background: #FCFCFC;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
    color: #858585;
  }

  &__status {
    border-radius: 5px;
    width: 123px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 24px;

    @media(max-width: 576px) {
      width: 100%;
      height: 48px;
    }
  }

  &__status--red {
    background: #FFEDEC;
    color: #F03A31;
  }

  &__status--green {
    background: #E4FFF0;
    color: #01C77F;
  }
}

.modal-template-composition {
  display: none;
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox+label {
  display: inline-flex;
  user-select: none;
}

.checkbox+label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #F5F5F5;
  border-radius: 2px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  transition: .2s linear;
}

.checkbox:checked+label::before {
  background-color: #fff;
  background-image: url(../images/icons/check-green.svg);
}

.checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: rgba(15, 247, 162, 0.479);
}

.safety--height {
  height: 670px;

  @media(max-width: 992px) {
    height: auto;
  }
}

/*******************************************************/
.safety__checkbox {
  position: relative;
}

.input_wrap {
  position: relative;
}

.safety__checkbox input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.checkbox_custom {
  width: 50px;
  height: 30px;
  background: #C2C2C2;
  border-radius: 18px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  position: relative;
  cursor: pointer;
}

.checkbox_custom span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  transform: translateX(35%);
  top: calc(50% - 10px);
  background-color: #fff;
  transition: all .3s;
}

.safety__checkbox input[type="checkbox"]:checked+label {
  background: #01C77F;
}

.safety__checkbox input[type="checkbox"]:checked+label span {
  transform: translateX(120%);
}

.create-parameters__textarea {
  padding: 23px 23px;
}

.safety__sign .safety__input {
  padding: 24px 23px 9px 23px;
}

.input_wrap .style-input {
  padding: 24px 23px 9px 23px;
}

.create-parameters__input-name-label {
  font-size: 16px;
  color: #C2C2C2;
  top: 24px;
  transition: all .3s;
  pointer-events: none;
}

.label_green {
  color: #01C77F;
  font-size: 11px;
  top: 10px;
}

.label_active {
  color: #858585;
  font-size: 11px;
  top: 10px;
}

.safety__sign .input_wrap {
  margin-bottom: 20px;
}

.safety__sign .input_wrap:last-child {
  margin-bottom: 0;
}

.safety .input_wrap input:focus {
  outline: none;
  border-color: #01C77F;
}

.safety input:focus {
  outline: none;
  border-color: #01C77F;
}

.safety__contacts-box .input_wrap {
  width: 100%;
}

.safety__data-form .input_wrap {
  width: 100%;
  margin-right: 50px;
}

.safety__data-form .input_wrap:last-child {
  margin-right: 0;
}


.choice_of_options {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  position: relative;

  &.active {
    .select_button__arrow {
      background: $green;
      transform: rotate(180deg);
    }
  }

  &.has-value {
    .select_button {
      color: #262026;
    }
  }
}

.choice_of_options .select_button {
  width: 100%;
  height: 60px;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  padding: 18px 23px;
  outline: none;
  color: #C2C2C2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_button {
  &__arrow {
    width: 12px;
    height: 8px;
    mask: url('../images/icons/arrow-bottom.svg');
    -webkit-mask: url('../images/icons/arrow-bottom.svg');
    background: #7C7C7C;
  }
}


.choice_of_options .total_value {
  display: none;
}

ul.options {

  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  background-color: #fff;
  z-index: 100;
  //border: 1px solid #01C77F;
  border: 1px solid #D7D7D7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
  //border-top: none;
}

ul.options li {
  padding: 0 60px 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  color: #262026;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: 23px;
    top: 50%;
    width: 24px;
    height: 24px;
    background: url('../images/icons/mdi_check.svg') center /contain no-repeat;
    transform: translateY(-50%);
    opacity: 0;
  }

  &:hover {
    background: #F5F5F5;

    &::before {
      opacity: 1;
    }
  }
}


.choice_of_options.active .options {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.choice_of_options .select_button i {
  transition: all .3s;
  margin-left: 5px;
}

.choice_of_options.active .select_button i {
  transform: rotate(180deg);
  transition: all .3s;
}

.choice_of_options.active .select_button {
  border: 1px solid #01C77F;
  //border-bottom-left-radius: 0;
  //border-bottom-right-radius: 0;
}

.chouse-select .select_button i {
  transition: all .3s;
}

.chouse-select.active .select_button i {
  transform: rotate(0deg);
  transition: all .3s;
}

.safety2,
.safety3 {
  display: none;
}

.create_item2,
.create_item3,
.create_item4,
.create_item5,
.create_item6 {
  display: none;
}

.box-file {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.box-file .file_upload {
  width: 100%;
  height: 60px;
  border: 1px dashed #C2C2C2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 15px;
}

.box-file .file_upload input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.box-file p.desr {
  color: #C2C2C2;
  font-size: 16px;
}

.box-file .wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.box-file .wrap .item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-right: 20px;
}

.box-file .wrap .item p {
  color: #262026;
  font-size: 14px;
}

#file_one {
  display: block;
}

#file_two {
  display: none;
}

.item_documents {
  display: none;
}

.item_discussion {
  display: none;
}

.help-questions__quest .help-questions__list {
  display: none;
}

.help-questions__quest .img {
  width: 14px;
  height: 14px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background-image: url(../images/icons/plus.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  margin-right: 25px;
  transition: all .2s linear;
}

.help-questions__quest.active .img {
  background-image: url(../images/icons/minus.svg);
}

.navigation__link-box .item {
  display: none;
}

.navigation__link-box img {
  transition: all .2s linear;
}

.navigation__link-box.active img {
  transform: rotate(-180deg);
}

.create-parameters__input--width {
  width: 390px;
  margin-right: 30px;

  @media(max-width: 768px) {
    margin-right: 0;
  }
}

.arrow-left-icon {
  width: 21px;
  height: 8px;
  fill: #C2C2C2;
  display: block;
}

.download-icon {
  &--red {
    fill: #F03A31;
  }

  width: 18px;
  height: 18px;
  fill: #C2C2C2;
  display: block;
}

.share-icon {
  width: 18px;
  height: 20px;
  fill: #C2C2C2;
  display: block;
}

.check-icon {
  width: 18px;
  height: 14px;
  fill: #01C77F;
  display: block;
}

.rub-icon {
  width: 16px;
  height: 18px;
  fill: #01C77F;
  display: block;
}

.pen-icon {
  width: 18px;
  height: 18px;
  fill: #01C77F;
  display: block;
}

.star-icon {
  width: 20px;
  height: 19px;
  fill: #01C77F;
  display: block;

  &:hover {
    stroke: #01C77F;
    fill: white;
  }
}

.grey-star-icon {
  width: 20px;
  height: 19px;
  display: block;
  stroke: #e6e6e6;
  fill: white;
  transition: .2s linear;

  &:hover {
    stroke: #01C77F;
  }
}

.arrow-right-icon {
  width: 16px;
  height: 16px;
  display: block;
  fill: #C2C2C2;
}

.exit-icon {
  &--indent {
    margin-right: 13px;
  }

  transition: .2s linear;
  width: 18px;
  height: 18px;
  display: block;
  fill: #01C77F;
}

.fancybox-slide--html .fancybox-close-small {
  color: #a5a5a5;
  right: 20px;
  top: 29px;
  padding: 0;
  width: 24px;
  height: 24px;

  @media (max-width: 768px) {
    right: 13px;
    top: 20px;
  }
}

.green-btn--shadow {
  box-shadow: 0px 4px 10px rgba(1, 199, 127, 0.2);
}

.navigation__link {
  &.active {
    transition: .2s linear;

    img {
      transform: rotate(180deg);
    }

  }
}

.how-fill .fancybox-close-small {
  top: 20px;
  right: 15px;
}

.input-list {
  height: 30px;
  border: 1px solid #F5F5F5;
  border-radius: 2px;
  color: #858585;
  font-size: 12px;
  line-height: 24px;
  margin-left: 20px;
  width: 90%;
  margin-bottom: 13px;

  &:last-child {
    margin-bottom: 0;
  }
}

.input-list--division {
  height: 30px;
  margin: 10px 0px 9px 10px;
  font-size: 14px;
  line-height: 24px;
  color: #525252;
}

.investment--indent {
  margin-top: 23px;
}

.input--styles {
  width: 48%;
  flex-shrink: 0;

  @media(max-width: 768px) {
    width: 100%;
  }
}


.navigation-form-toggle {
  display: block;

  &--hidden {
    display: none;
  }
}

.overhead-label {
  font-size: 16px;
  line-height: 19px;
  color: $black;
  margin-bottom: 20px;
}

.public-records-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 20px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  &--indent {
    margin: 20px 0 40px 0;
  }
}

.hint {
  &__icon {
    margin-left: 10px;
    cursor: pointer;
  }
}

.title-flex {
  display: flex;
  align-items: center;
}

.row-height {
  height: 100%;
}

.tab-content {
  display: none;

  &--active {
    display: flex;
    flex-direction: column;
  }
}

.contract-subsections--hidden {
  display: none;
}

.upload-photo {
  position: relative;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(194, 194, 194, 0.5);
    backdrop-filter: blur(5px);
    opacity: 0;
    border-radius: 5px;
    transition: .2s linear;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    .upload-photo__remove {
      opacity: 1;
    }
  }

  &__img {
    object-fit: cover;
    width: 52px;
    height: 52px;
    border-radius: 5px;
    position: relative;

  }

  &__remove {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url("../images/icons/close.svg") center /contain no-repeat;
    z-index: 2;
    opacity: 0;
    transition: .2s linear;
    cursor: pointer;
  }
}

.file-name {
  font-size: 14px;
  line-height: 16px;
  color: #262026;
  display: flex;
  align-items: center;
  margin: 0 20px 10px 0;

  &:last-child {
    margin-right: 0;
  }

  &__remove {
    width: 14px;
    height: 14px;
    margin-left: 10px;
    cursor: pointer;
    flex-shrink: 0;
    transition: .2s linear;
    mask: url('../images/icons/close.svg');
    -webkit-mask: url('../images/icons/close.svg');
    @include mask-contain-center;
    background: $green;

    &:hover {
      opacity: 0.6;
    }
  }
}

.label-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__item {
    background: #F2F2F2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    padding: 7px 10px;
    margin: 0 10px 10px 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &__remove {
    margin-left: 12px;
    width: 12px;
    height: 12px;
    mask: url('../images/icons/close.svg');
    -webkit-mask: url('../images/icons/close.svg');
    @include mask-contain-center;
    cursor: pointer;
    background: #7C7C7C;
    transition: .2s linear;

    &:hover {
      background: $dark;
    }
  }
}

.formalization-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__item {
    display: flex;
    align-items: center;

    &--bordered {
      border: 1px solid #EDEDED;
      border-radius: 5px;
      padding-right: 15px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border: none;
      }
    }

    @media (max-width: 768px) {
      margin: 0 0 20px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;

    &--hourglass {
      mask: url('../images/icons/hourglass_empty.svg');
      -webkit-mask: url('../images/icons/hourglass_empty.svg');
      @include mask-contain-center;
      background: #7C7C7C;
      margin-right: 9px;
    }

  }

  &__status {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #7C7C7C;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      color: $green;

      .formalization-panel__icon--hourglass {
        mask: url('../images/icons/mdi_check.svg');
        -webkit-mask: url('../images/icons/mdi_check.svg');
        background: $green;
      }
    }

    @media (max-width: 768px) {
      margin: 0 0 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__status-title {
    padding: 11px 15px;
    background: #EDEDED;
    border-radius: 5px 0 0 5px;
    margin-right: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #404040;
    text-align: center;

    @media (max-width: 768px) {
      width: 100%;
      margin: 0 0 10px 0;
      border-radius: 5px;
    }
  }

  &__use-btn {
    width: 40px;
    height: 40px;
    background: #F6F6F6;
    border-radius: 5px;
    margin-right: 10px;
    @include flex-column-center;
    transition: .2s linear;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background: #ececec;
    }
  }

  &__btn {
    padding: 10px 30px;
    background: $green;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    transition: .2s linear;

    &:hover {
      background: $green-hover;
    }
  }
}

.messages-text__search--no-hover {
  .messages-text__link {
    &:hover {
      background: #fff;
    }
  }
}


.cfa {
  max-width: 700px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    max-width: 1030px;
  }

  &__joint-stock {
    border: 1px solid #00736B;
    border-radius: 4px;
    min-height: 111px;
    width: 100%;
    padding: 20px;

    &--background {
      background: #00736B;

      .cfa__joint-stock-text,
      .cfa__joint-stock-name,
      .cfa__joint-stock-title {
        color: #FFFFFF;
      }

      .cfa__joint-stock-btn {
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
      }
    }
  }

  &__joint-stock-title {
    font-size: 18px;
    line-height: 20px;
    color: #00736B;

  }

  &__joint-stock-inner {
    display: flex;
    width: 100%;
    margin-top: 20px;

    @media (max-width: 420px) {
      flex-wrap: wrap;
      justify-content: space-between;
      grid-gap: 20px;
    }
  }

  &__joint-stock-item {
    max-width: 212px;
    width: 100%;
    margin-right: 10px;

    @media (max-width: 420px) {
      max-width: fit-content;
    }
  }


  &__joint-stock-name {
    color: #404040;
    font-size: 13px;
    line-height: 14px;
  }

  &__joint-stock-text {
    color: #AEACB4;
    font-size: 11px;
    line-height: 11px;
    margin-top: 4px;
  }

  &__loading {
    margin-top: 20px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    width: 100%;
    border-radius: 4px;
    min-height: 174px;
    padding: 20px;

    &-title {
      color: #445A6C;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    &-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-gap: 10px;

      @media (max-width: 576px) {
        flex-direction: column;
        grid-gap: 15px;
      }
    }

    &-name {
      font-size: 13px;
      line-height: 14px;
      color: #404040;
    }

    &-text {
      color: #AEACB4;
      font-size: 11px;
      line-height: 11px;
      margin-top: 4px;
    }

    &-btn {
      border: 1px solid #58748B;
      border-radius: 2px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      cursor: pointer;
      white-space: nowrap;

      font-size: 13px;
      line-height: 13px;
      color: #58748B;

      &.active {
        border: 1px solid #7C7C7C;
        color: #B5B5B5;
      }
    }


    #upload {
      display: none;
    }

    &-next {
      background: #01C77F;
      opacity: 1;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s linear;
      width: fit-content;
      height: 34px;
      padding: 0 20px;
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      margin: 20px 0 0 auto;

      &:hover {
        background: #1fa775;
      }
    }
  }

  &__time {
    margin-top: 20px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    width: 100%;
    border-radius: 4px;
    min-height: 174px;
    padding: 20px;

    &-next {
      background: #01C77F;
      opacity: 1;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s linear;
      width: fit-content;
      height: 34px;
      padding: 0 20px;
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      margin: 20px 0 10px auto;

      &:hover {
        background: #1fa775;
      }
    }

    &-inner {
      margin-top: 20px;
      grid-gap: 10px;
      display: flex;

      svg {
        width: 42px;
        height: 42px;
        flex-shrink: 0;

        @media (max-width: 450px) {
          width: 26px;
          height: 26px;
        }
      }

      &-title {
        font-size: 13px;
        line-height: 14px;
        color: #404040;
      }

      &-text {
        margin-top: 6px;
        color: #AEACB4;
        font-size: 11px;
        line-height: 11px;
      }
    }

    &-btn {
      margin-top: 20px;
      background: #58748B;
      border-radius: 2px;
      width: 100%;
      height: 34px;
      display: flex;
      font-size: 14px;
      line-height: 14px;
      color: #FFFFFF;
      align-items: center;
      justify-content: center;
      transition: .2s linear;

      &:hover {
        background: #4e6a81;
      }
    }

    &-sum {
      color: #01C77F;
      font-weight: bold;
      font-size: 13px;
      line-height: 14px;
      white-space: nowrap;
    }

    .select2-selection__rendered {
      color: #161616;
      font-size: 13px;
      line-height: 14px;
    }

    .select2-container {
      height: 34px;
      width: 100% !important;
    }

    &-single {
      width: 100%;
      max-width: 383px;
    }

    .select2-selection {
      height: 34px;
      display: flex;
      align-items: center;
    }

    &-block {
      margin-top: 20px;
      display: flex;
      grid-gap: 20px;
      align-items: center;
    }

    &-title {
      font-size: 18px;
      line-height: 20px;
      color: #445A6C;
    }
  }

  &__process {
    margin-top: 20px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    width: 100%;
    border-radius: 4px;
    min-height: 150px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.4);

    &-title {
      font-size: 14px;
      line-height: 15px;
      color: #404040;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    &-progress {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;


      &:before {

        position: absolute;
        width: 98%;
        border: 1px solid #01C77F;
        content: '';
        left: 0;
      }

      &-item {
        width: 30px;
        height: 30px;
        border: 1px solid #01C77F;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 15px;
        color: #01C77F;
        background: #fff;
        z-index: 1;
        cursor: pointer;
          &:after {
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            border-top: 15px solid #fff;
            border-right: 15px solid #fff;
            display: block;
            transform: matrix(1, 0, 0, -1, 0, 0) rotate(135deg);
            cursor: pointer;
            margin: 0 28px;
            top: 40px;
            visibility: hidden;
            opacity: 0;
            transition: .2s linear;
          }
        &:hover {
          .cfa__process-inner {
            display: block;
            margin-bottom: 200px;
            visibility: visible;
            opacity: 1;
          }

          &:after {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    &-inner {
      top: 50px;
      left: -20px;
      width: calc(100% + 40px);
      padding: 10px 15px;
      position: absolute;
      min-height: 260px;
      background: #FFFFFF;
      filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
      border-radius: 5px;
      display: block;
      transition: .2s linear;
      visibility: hidden;
      opacity: 0;

      @media (max-width: 400px) {
        min-height: 375px;
      }

      &-text {
        color: #7C7C7C;
        font-size: 13px;
        line-height: 19px;
      }
    }

    &-text {
      margin-top: 10px;
      color: #B5B5B5;
      font-size: 13px;
      line-height: 14px;
    }
  }

  &__joint-stock-btn {
    border: 1px solid #00736B;
    border-radius: 2px;
    height: 22px;
    color: #00736B;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 13px;
    padding: 0 20px;
  }

  &__joint-stock-block {
    grid-gap: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 576px) {
      flex-direction: column;

    }
  }

  &__accepted {
    margin-top: 20px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    width: 100%;
    border-radius: 4px;
    min-height: 174px;
    padding: 20px;

    &-title {
      font-size: 18px;
      line-height: 20px;
      color: #445A6C;
    }

    &-text {
      margin-top: 20px;
      font-size: 13px;
      line-height: 14px;
      color: #404040;
    }

    &-next {
      background: #01C77F;
      opacity: 1;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s linear;
      width: fit-content;
      height: 34px;
      padding: 0 20px;
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      margin: 20px 0 10px auto;

      &:hover {
        background: #1fa775;
      }
    }
  }
}

.select2-search__field {
  display: none;
}

.select2-results__option {
  font-size: 13px;
  line-height: 14px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-top: 0px;
  border: 0;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4.99999 3.78145L8.29999 0.481445L9.24266 1.42411L4.99999 5.66678L0.757324 1.42411L1.69999 0.481445L4.99999 3.78145Z' fill='%23929FB9'/%3e%3c/svg%3e ");
  width: 10px;
  height: 6px;
  //transition: .2s linear;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: rotateZ(180deg);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px;
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
  border: 1px solid #D7D7D7;
}

.body-background {
  background-color: #F0F3F4;
}

.select2-selection__rendered {
  padding-right: 27px;
}

.select2-results__option--selected {
  background-color: transparent;
}

.select2-results__option--selectable {
  color: inherit !important;
  background-color: transparent !important;
}
.select2-search--dropdown {
  padding: 0;
}
.select2-dropdown {
  border: 1px solid #DEE2EA;
  border-radius: 8px;
}

.select2-results__option {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #929FB9 !important;
  padding: 8px 10px !important;
}

