@import "variables";
@import "mixin";

/* components */
@import "components/main-modal";
@import "components/about-service";
@import "components/personal-account";
@import "components/panel";
@import "components/personal-account-company";
//@import "components/navbar";
@import "components/table-wrapped";
@import "components/message";
@import "components/company-offer";
@import "components/incoming-applications";
@import "components/personal-info";
@import "components/right-sidebar";
@import "components/user-contact";
@import "components/choose-personal-account";
@import "components/pay";

/* UI */
@import "UI/line-awesome/line-awesome-build.css";
@import "UI/input";
@import "UI/header-main";
@import "UI/sidebar-main";
@import "UI/overhead-main";
@import "UI/breadcrumbs";
@import "UI/nav-main";
@import "UI/form-settings";
@import "UI/main-checkbox";
@import "UI/main-radio";
@import "UI/button";
@import "UI/switch";
@import "UI/custom-select";
@import "UI/back-button";

@import "style-difis";

@import "style-base";  // Стиль из файла style.scss, который был после импортов
