.main-radio {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  &__input {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__circle {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 50%;
    border: 2px solid #DEE2EA;
    position: relative;
    z-index: -1;
    flex-shrink: 0;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      background: $blue;
      border-radius: 50%;
      opacity: 0;
      transition: .2s linear;
    }
  }

  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: inherit;
    color: #424E66;

    &--lg {
      font-size: 16px;
      @media (max-width: 375px) {
        font-size: 14px;
      }
    }

  }

}

.main-radio__input:checked + .main-radio__circle {
  border: 2px solid $blue;

  &:after {
    opacity: 1;
  }

}

.main-radio__input:checked + .main-radio__circle--green {
  border: 2px solid $green;

  &:after {
    background: $green;
  }

}

.radio-item {
  cursor: pointer;

  &--disabled {
    cursor: default;
  }

  &.active {
    .main-modal__area-content {
      display: block;
    }
  }

}