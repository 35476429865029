$grey: #B3B3B3;
$dark-grey: #808080;
$grey-700: #484848;
$grey-800: #2C2C2C;
$grey-900: #101010;

$blue: #4277FF;
$blue-hover: #6B94FF;
$blue-light: #E5ECFF;

$red: #EA1E21;
$red-hover: #EE4D4F;
$red-light: #FEF0F0;

$watermelon: #FF6978;

$green: #01C77F;
$green-hover: #02b070;
$green-light: #DDFFF2;

$mint: #55D6C2;

$purple: #7D00DF;
$purple-light: #FAF3FF;

$violet: #8477FF;

$blue-gray-50: #F4F5F8;
$blue-gray-100: #E9ECF1;
$blue-gray-150: #DEE2EA;
$blue-gray-200: #D3D8E3;
$blue-gray-300: #BDC5D5;
$blue-gray-500: #929FB9;
$blue-gray-850: #4A5773;
$blue-gray-900: #424E66;

$dark: #262026;

$black: #161616;

$grey: #7c7c7c;
$grey-hover: #707070;

$orange: #FE7D34;
$orange-hover: #e26520;

$orange-light: #FCAF58;

$white: #fff;

$smalt-blue: #58748B;
$smalt-blue-hover: #516a80;

$valencia: #D43F3A;
$valencia-hover: #c43833;

$fern: #5CB85C;
$fern-hover: #51a651;

$cyan: #1CCAD8;