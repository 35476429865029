.right-sidebar {
  width: 260px;
  position: fixed;
  right: 0;
  top: 60px;
  background: #fff;
  z-index: 10;
  bottom: 0;
  transition: transform .2s linear;
  transform: translateX(100%);
  &.show {
    transform: translateX(0);
  }
  &__inner {
    height: calc(100vh - 50px);
    overflow-y: auto;
    display: none;
    &.active {
      display: block;
    }
  }

  &__buttons {
    height: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__button {
    @include flex-row-center;
    background: #D7D6DB;
    border-right: 1px solid #fff;
    &.active {
      background: #fff;
    }
    &:last-child {
      border-right: 0;
    }
  }

  &__label {
    color: #aeacb4;
    font-size: 11px;
    margin: 15px 10px 10px 10px;
    text-transform: uppercase;
  }
  &__icon {
    object-fit: contain;
    width: 17px;
    height: 17px;
    background: rgba(44, 47, 59, 0.6);
  }
  &__row {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid #c8c7cc;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__text {
    color: #8e8e93;
    font-size: 13px;
    margin-left: 12px;
  }
  &__save {
    margin: 10px 0 0 10px;
  }
}
