.user-contact {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c8c7cc;
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    background: #efeff0;
  }
  &__avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    flex-shrink: 0;
  }
  &__name {
    color: #8e8e93 ;
    font-size: 13px;
    margin-bottom: 4px;
  }
  &__text {
    color: #c8c7cc;
    display: block;
    font-size: 12px;
  }
  &__circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #5A8770;
  }
  &__box {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__counter {
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: #5cb85c;
    padding: 5px 6px;
    margin-right: 10px;
    font-size: 10px;
    border-radius: 2px;
  }
  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
