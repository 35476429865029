.main-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  &__input {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__square {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 4px;
    border: 2px solid #DEE2EA;
    position: relative;
    z-index: -1;
    flex-shrink: 0;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 17px;
      //background: $blue;
      //border: 2px solid $blue;
      /*
      @include mask-contain-center;
      mask: url("../images/white-chevron.svg");
      -webkit-mask: url("../images/white-chevron.svg");
      opacity: 0;
       */
      background-image: url(../images/white-chevron.svg);
      background-repeat: no-repeat;
      background-position: center;
      transition: .2s linear;
    }
  }

  &__label {
    font-weight: 500;
    font-size: 14px;
    //line-height: 16px;
    color: #424E66;
  }
  
  &--grey {
    .main-checkbox__square {
      background: #fff;
      width: 20px;
      border: 1px solid #C8C7CC;
      height: 20px;
      &::after {
        width: 12px;
        height: 12px;
      }
    }
    .main-checkbox__input:checked + .main-checkbox__square {
      background: #58748B;
      border-color: #58748B;
    }
    .main-checkbox__label {
      color: #858585;
    }
  }

}

.main-checkbox__input:checked + .main-checkbox__square {
  background: $blue;
  border: 2px solid $blue;

  &:after {
    opacity: 1;
  }
}

.main-checkbox__input:checked + .main-checkbox__square--green {
  background: $green;
  border: 2px solid $green;
}