.message {
  background: #e9e8ea;
  padding: 10px;
  margin-bottom: 5px;
  &:hover {
    background: #e0dfe1;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__info {
    display: flex;
    align-items: center;
  }

  &__avatar {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 8px;
  }

  &__name {
    font-size: 12px;
    color: #8e8e93;
    margin-bottom: 2px;
  }

  &__text {
    font-size: 11px;
    color: #aeacb4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }

  &__link {
    text-align: right;
    font-size: 11px;
    color: #8e8e93;
  }
}
