.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -5px;
  &__icon {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  &__link {
    font-size: 12px;
    line-height: 13px;
    color: #8E8E93;
    position: relative;
    margin-right: 22px;
    margin-bottom: 5px;
    &::after {
      content: '/';
      position: absolute;
      right: -11px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:last-child {
      margin-right: 0;
      &::after {
        display: none;
      }
    }
  }
}
