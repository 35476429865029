.table-wrapped {
  background: #FFFFFF;
  border: 1px solid #E9ECF1;
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
  margin-bottom: 24px;
  @media (max-width: 576px) {
    display: none;
  }

  &--visible-mobail {
    @media (max-width: 576px) {
      display: block;
    }
  }

  &__overhead {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    color: #4277FF;
    margin-bottom: 20px;
    @media (max-width: 576px) {
      font-size: 14px;
    }

    &--grey {
      color: #5B5B60;
    }

    &--mb {

      @media (max-width: 768px) {
        margin-bottom: 14px;
      }
    }

    &--mb-none {
      padding: 7px 0;
      margin-bottom: 0;
      @media (max-width: 768px) {
        margin-bottom: 14px;
      }
    }

    &--indent-none {
      margin: 0;
    }

    &--column {
      @media (min-width: 320px) {
        flex-wrap: wrap;
      }
      justify-content: space-between;
    }
  }

  &__icon {
    width: 17px;
    height: 17px;
    margin-left: 10px;
  }

  &__inner {
    overflow-x: auto;
    width: 100%;
  }

  &__block {
    overflow: hidden;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    &--mb {
      margin-bottom: 20px;
    }

    &--col {
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &--col-lg {
      @media (max-width: 1400px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 16px;
    }

    &--mb-none {
      margin-bottom: 0;
    }
    &--left-center {
      display: inline-flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
    }
  }

  &__docs {
    @media (min-width: 320px) {
      margin-top: 10px;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
  }

  &__tab {
    padding: 9.5px 15px;
    font-size: 13px;
    line-height: 19px;
    border-radius: 10px;
    color: #C82E29;

    &.active {
      background: #C82E29;
      color: #fff;
    }

    @media (max-width: 576px) {
      font-size: 11px;
      line-height: 15px;
      padding: 7px 14px;
      border-radius: 7px;
    }
  }

  &__doc-tabs {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__doc-tab {
    padding: 7.5px 15px;
    font-size: 13px;
    line-height: 19px;
    border-radius: 5px;
    color: $smalt-blue;

    &.active {
      background: $smalt-blue;
      color: #fff;
    }

    @media (max-width: 576px) {
      font-size: 11px;
      line-height: 15px;
      padding: 7px 14px;
      border-radius: 7px;
    }
  }

  &__search-wrapper {
    position: relative;
    width: 100%;
    max-width: 400px;
    @media (max-width: 992px) {
      max-width: 300px;
    }
    @media (max-width: 768px) {
      max-width: 100%;
      margin-top: 16px;
    }

    &--indent-none {
      margin: 0;
    }
  }

  &__search {
    border: 1px solid #C8C7CC;
    background: #FFFFFF;
    width: 100%;
    padding: 8.5px 5px 8.5px 32px;
    font-size: 13px;
    line-height: 14px;
    color: #9B9B9F;

    &--width {
      max-width: 360px;
    }
  }

  &__search-icon {
    width: 17px;
    height: 17px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__buttons {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__button {
    margin: 0 10px 0 0 !important;

    &:last-child {
      margin-right: 0 !important;
    }

    @media (max-width: 576px) {
      width: 100%;
      margin: 0 0 5px 0 !important;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-left: 20px;
    @media (max-width: 1400px) {
      margin: 14px 0 0 0;
    }
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__refresh {
    margin-bottom: 0 !important;
    @media (max-width: 768px) {
      margin: 14px 0 0 0;
    }
  }

  &__personal-info {
    margin-bottom: 20px;
  }

  &__custom-select {
    width: 130px !important;
    margin-right: 10px;
    @media (max-width: 768px) {
      width: 100% !important;
      margin: 0 0 7px 0;
    }
  }

  &--no-border {
    border: none;
    border-radius: 0;
    padding: 0;
  }

}