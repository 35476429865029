.incoming-applications-wrapper {
  overflow-x: auto;
}

.incoming-applications {
  width: 100%;
  border-spacing: 0;
  @media (max-width: 1330px) {
    width: 1200px;
  }
  @media (max-width: 576px) {
    width: 1100px;
  }
  th {
    border-bottom: 1px solid #DFDFDF;
    font-size: 14px;
    line-height: 15px;
    color: #5B5B60;
    font-weight: 400;
    text-align: left;
    padding: 7px 10px 9px 10px;

    &.center {
      text-align: center;
    }
    @media (max-width: 576px) {
      font-size: 13px;
    }
  }

  td {
    padding: 8px 10px;
    //border-top: 1px solid #DFDFDF;
    font-size: 13px;
    line-height: 14px;
    color: #5B5B60;
    text-align: left;
    @media (max-width: 576px) {
      font-size: 12px;
    }
    &.center {
      text-align: center;
      justify-content: center;
    }
  }

  tr {
    &:last-child {
      td {
        //border-bottom: 1px solid #DFDFDF;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background: rgba(#F1F2F5, 0.50);
      }
    }
  }

  &__avatar {
    border-radius: 2px;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    @media (max-width: 576px) {
      width: 44px;
      height: 44px;
    }
  }
  &__status {
    display: inline-flex;
    padding: 5px 8px;
    background: #5CB85C;
    border-radius: 2px;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    color: #fff;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 10px;
    }
    &--big {
      font-weight: 400;
      padding: 8.5px 20px;
      background: $smalt-blue;
      font-size: 13px;
      line-height: 14px;
      &:hover {
        background: $smalt-blue-hover;
      }
      @media (max-width: 576px) {
        font-size: 12px;
        padding: 6.5px 16px;
      }
    }
    &--black {
      background: #5B5B60;
    }
    &--red {
      background: #D43F3A;
    }
    &--yellow {
      background: #EEA236;
    }
  }
}
