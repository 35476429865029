.header-main {
  right: 0;
  left: 0;
  position: fixed;
  padding: 0 0 0 20px;
  box-shadow: none;
  background: #4F5A67;
  display: flex;
  align-items: center;
  height: 60px;
  z-index: 10;
  @media (max-width: 576px) {
    height: 55px;
  }
  &__left {
    width: 220px;
    margin-right: 10px;
    @media (max-width: 992px) {
      display: none;
    }
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__block {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__box {
    height: 100%;
    display: flex;
    align-items: center;
    &--search {
      padding: 0 15px;
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
  &__menu {
    background: #C82E29;
    border-radius: 2px;
    width: 36px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    &:hover {
      background: #c73530;
    }
    @media (max-width: 992px) {
      margin: -5px 0 -5px -20px;
      height: 100%;
    }
  }
  &__button {
    height: 100%;
    min-width: 62px;
    position: relative;
    @include flex-row-center;
    @media (max-width: 576px) {
      min-width: 50px;
      position: initial !important;
    }
    &--sm {
      min-width: 34px;
    }
    &--hidden {
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
  &__input {
    font-size: 14px;
    line-height: 15px;
    width: 100%;
    max-width: 150px;
    color: #CBCED2;
    padding-right: 15px;
    &::placeholder {
      color: #CBCED2;
    }
  }
  &__notification {
    position: relative;
  }
  &__circle {
    padding: 2px;
    @include flex-row-center;
    position: absolute;
    right: -17px;
    top: -13px;
    min-width: 20px;
    height: 20px;
    background: #C82E29;
    border-radius: 50%;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #fff;
    @media (max-width: 576px) {
      font-size: 11px;
      min-width: 17px;
      height: 17px;
    }
  }
  &__logo {
    object-fit: contain;
    @media (max-width: 576px) {
      width: 80px;
    }
    &--mob {
      margin-left: 14px;
      display: none;
      @media (max-width: 992px) {
        display: block;
      }
    }
  }
  &__list {
    width: 288px;
    padding: 10px 8px;
    background-color: #FFFFFF;
    border: 1px solid rgba(17, 17, 17, 0.15);
    max-width: 300px;
    min-width: 166px;
    border-radius: 7px;
    position: absolute;
    right: 0;
    top: calc(100% + 2px);
    text-align: left;
    opacity: 0;
    visibility: hidden;
    transition: .2s linear;
    &.show {
      opacity: 1;
      visibility: visible;
    }
    @media (max-width: 576px) {
      width: calc(100vw - 15px);
      right: initial;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100vw;
      margin: 0 auto;
    }
  }
  &__label {
    font-size: 11px;
    color: #8e8e93;
    margin-bottom: 12px;
    text-align: left;
  }
  &__link {
    font-size: 14px;
    text-align: center;
    color: #58748B;
    margin-top: 14px;
  }
  &__list-item {
    padding: 15px 10px 15px 10px;
    margin: 0 -8px;
    border-top: 1px solid #DDDDDD;
    &:last-child {
      border-bottom: 1px solid #DDDDDD;
    }
    &:hover {
      background: #f8f8f8;
    }
  }
  &__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto 10px auto;
  }
  &__list-title {
    font-size: 14px;
    text-align: center;
    color: #58748B;
    margin-bottom: 10px;
  }
  &__text {
    color: #c8c7cc;
    font-size: 12px;
    text-align: center;
  }
}

.drop-shadow-before {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 1px;
    left: 0;
    top: 5px;
    bottom: 5px;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 );
    /* IE6-9 */
  }
}

.openRightSidebar {
  &.active {
    img {
      transform: rotate(180deg);
    }
  }
}
