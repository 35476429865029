.form-settings {
  display: grid;
  grid-template-columns: 1fr 380px;
  grid-gap: 20px;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__top {
    padding: 12px 10px;
    background: #E6E8E8;
    font-size: 14px;
    line-height: 15px;
    color: #5B5B60;
  }

  &__body {
    padding: 20px 10px 30px 10px;
    background: #F7F7F8;
    flex: 1;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    &--one {
      grid-template-columns: 1fr;
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
      grid-gap: 14px;
    }
  }

  &__wrapper {
    width: 100%;

    &--mr {
      margin-right: 20px;
      @media (max-width: 768px) {
        margin: 0 0 10px 0;
      }
    }
    &--part {
      width: calc(50% - 10px);
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__part {
    width: calc(50% - 10px);
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    &--between {
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }
    &--col {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__full {
    grid-column: 1/3;
    @media (max-width: 576px) {
      grid-column: initial;
    }
  }

  &__select {
    margin-top: 6px;
    &--mr {
      margin: 0 10px 0 0;
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 768px) {
        width: 100%;
        margin: 0 0 7px 0;
        &:last-child {
          margin-bottom: 0;
        }
        .select2-container {
          width: 100% !important;
        }
      }
    }
  }

  &__checkbox {
    &--mb {
      @media (max-width: 576px) {
        margin-bottom: 10px;
      }
    }
    &--mb-sm {
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

