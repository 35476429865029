.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;

  &--disabled {
    opacity: 0.3;

    .switch__slider {
      cursor: default;
    }

  }

}
/* hide default HTML checkbox */
.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* the slider */
.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

}

.switch__input:checked + .switch__slider  {
  background-color: $green;
}

.switch__input:focus + .switch__slider {
  //box-shadow: 0 0 1px $blue;
}

.switch__input:checked + .switch__slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* rounded sliders */
.switch__slider--round {
  border-radius: 16px;
}

.switch__slider--round:before {
  border-radius: 50%;
}