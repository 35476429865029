.about-service {
  &__logo {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 10px;
    @media (max-width: 576px) {
      width: 44px;
      height: 44px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.2em;
    color: #000;
  }
  &__subtitle {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: #404040;
    letter-spacing: 0.1em;
  }
  &__inner {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  &__desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #424E66;
  }
}
