@import "normalize";
@import "variables";
@import "mixin";

@mixin mask-contain-center {
  mask-repeat: no-repeat !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-position: center center !important;
  -webkit-mask-position: center center !important;
  mask-size: contain !important;
  -webkit-mask-size: contain !important;
}

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/*-- Google --*/
/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FN_P-bnBeA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FN_G-bnBeA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FN_M-bnBeA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FN_C-bk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFN_P-bnBeA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFN_G-bnBeA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFN_M-bnBeA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFN_C-bk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FN_P-bnBeA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FN_G-bnBeA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FN_M-bnBeA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FN_C-bk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFN_P-bnBeA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFN_G-bnBeA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFN_M-bnBeA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFN_C-bk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE9_P-bnBeA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE9_G-bnBeA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE9_M-bnBeA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE9_C-bk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE9_P-bnBeA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE9_G-bnBeA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE9_M-bnBeA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE9_C-bk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E9_P-bnBeA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E9_G-bnBeA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E9_M-bnBeA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E9_C-bk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*-- Fontstorage
@font-face {
  font-family: 'Manrope';
  src: local('Manrope Semibold'), local('Manrope-Semibold'), url('../fonts/Manropesemibold.woff2') format('woff2'), url('../fonts/Manropesemibold.woff') format('woff'), url('../fonts/Manropesemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: local('Manrope Medium'), local('Manrope-Medium'), url('../fonts/Manropemedium.woff2') format('woff2'), url('../fonts/Manropemedium.woff') format('woff'), url('../fonts/Manropemedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: local('Manrope Thin'), local('Manrope-Thin'), url('../fonts/Manropethin.woff2') format('woff2'), url('../fonts/Manropethin.woff') format('woff'), url('../fonts/Manropethin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: local('Manrope Light'), local('Manrope-Light'), url('../fonts/Manropelight.woff2') format('woff2'), url('../fonts/Manropelight.woff') format('woff'), url('../fonts/Manropelight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: local('Manrope Regular'), local('Manrope-Regular'), url('../fonts/Manroperegular.woff2') format('woff2'), url('../fonts/Manroperegular.woff') format('woff'), url('../fonts/Manroperegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Manrope';
  src: local('Manrope Bold'), local('Manrope-Bold'), url('../fonts/Manropebold.woff2') format('woff2'), url('../fonts/Manropebold.woff') format('woff'), url('../fonts/Manropebold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
--*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

/*
.container-difis {
  width: 100%;
  max-width: 770px;
  margin: 0 auto;
  //padding: 0 30px;
  padding: 0 24px 0 32px;
  color: $black;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
  @media (max-width: 576px) {
    padding: 0 12px;
  }

  &--padding {
    padding-right: 335px;
    max-width: 1047px;
    @media (max-width: 1200px) {
      padding-right: 10px;
      margin-top: 20px;
    }
  }

  &--big {
    //max-width: 1200px;
    max-width: 1520px;
  }

  &--large {
    max-width: 1325px;
  }
  &--px {
    padding: 0 30px;
    @media (max-width: 576px) {
      padding: 0 10px;
    }
  }
}
*/
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: none;
  text-rendering: optimizeLegibility;
}

.container-difis {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px 0 32px;
  color: $black;
  height: 100%;
  @media (max-width: 576px) {
    padding: 0;
  }
}
/*
.blue {
  color: $blue;
}
.red {
  color: $red-hover;
}
.green {
  color: $green;
}
.purple {
  color: $purple;
}
*/
.link {
  font-size: 14px;
  line-height: 16px;
  color: $orange;
  display: inline;
  transition: .2s linear;

  &:hover {
    color: $orange-hover;
  }
}

.button-nh-id {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 15px;
  background: $blue;
  border-radius: 5px;
  color: #fff;
  transition: .2s linear;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 13px;
    line-height: 14px;
  }

  &:hover {
    background: $blue-hover;
  }

  &--small {
    padding: 8px 20px;
    font-size: 14px;
    text-transform: none;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &--ml {
    margin-left: 20px;
    @media (max-width: 768px) {
      margin: 15px 0 0 0;
    }
  }

  &--ml-small {
    margin-left: 10px;
    @media (max-width: 768px) {
      margin: 10px 0 0 0;
    }
  }

  &--disabled {
    background: rgba($blue, 0.3);
    cursor: not-allowed;

    &:hover {
      background: rgba($blue, 0.3);
    }
  }

  &--orange {
    background: #FE7D34;
    font-size: 16px;
    text-transform: none;

    &:hover {
      background: #ea722e;
    }
  }

  &--blue-small {
    display: inline-flex;
    width: auto;
    padding: 15px 20px;
    font-size: 16px;
    text-transform: none;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &--grey {
    background: #EDEDED;
    color: $grey;

    &:hover {
      background: #e2e2e2;
    }
  }

  &--orange-disabled {
    background: rgba(#FE7D34, 0.3);
    cursor: not-allowed;

    &:hover {
      background: rgba(#FE7D34, 0.3);
    }
  }

  &--mt {
    margin-top: 30px;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  &--exit {
    display: inline-flex !important;
    width: auto;
    min-width: 123px;
    height: 50px;
    background: #EDEDED;
    color: $grey;

    &:hover {
      background: #e0e0e0;
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 40px;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &--green {
    background: $green;

    &:hover {
      background: $green-hover;
    }
  }
}

.sign {
  background: url("../images/images-nh-id/background.png") no-repeat left center;
  background-size: 60% 100%;
  position: relative;
  z-index: 1;
  @media (max-width: 992px) {
    background-size: 70% 100%;
  }
  @media (max-width: 768px) {
    background-size: cover;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 60%;
    height: 100%;
    background: rgba(#0B2978, 0.5);
    z-index: -1;
    @media (max-width: 992px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: 290px;
      height: auto;
      margin-bottom: 20px;
    }
    @media (max-width: 576px) {
      margin-bottom: 10px;
      width: 250px;
    }
  }

  &__inner {
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 20px 0;
      justify-content: center;
    }
    @media (max-width: 576px) {
      padding: 0 10px;
    }
  }

  &__form {
    padding: 100px 50px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background: #fff;
    width: 100%;
    max-width: 470px;
    @media (max-width: 1200px) {
      padding: 80px 40px;
    }
    @media (max-width: 992px) {
      padding: 50px 30px;
    }
    @media (max-width: 576px) {
      padding: 30px 20px;
    }
  }

  &__logo-state {
    margin-bottom: 30px;
    @media (max-width: 576px) {
      margin-bottom: 20px;
      width: 70px;
    }

  }

  &__title {
    font-weight: 700;
    color: $black;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 30px;
    @media (max-width: 1200px) {
      font-size: 32px;
    }
    @media (max-width: 992px) {
      font-size: 30px;
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      font-size: 26px;
    }
    @media (max-width: 576px) {
      font-size: 22px;
      line-height: 25px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 160%;
    color: #7C7C7C;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.wrapper {
  display: grid;
  //grid-template-columns: 150px 1fr;
  grid-template-columns: 1fr;
  min-height: 100vh;
  //padding-left: 150px;
  padding-left: 94px;
  padding-top: 48px;
  transition: width 0.5s;
  //background: #E9ECF1;
  @media (max-width: 1200px) {
    //grid-template-columns: 120px 1fr;
    //padding-left: 120px;
  }
  @media (max-width: 992px) {
    //padding-left: 60px;
  }
  @media (max-width: 768px) {
    //grid-template-columns: 60px 1fr;
    //padding-left: 47px;
    //padding-left: 50px;
  }
  @media (max-width: 576px) {
    //grid-template-columns: 45px 1fr;
    //padding-left: 40px;
    padding-left: 0;
  }

  &--pl {
    padding-left: 220px;
    @media (max-width: 992px) {   
      padding-left: 0;
    }
  }

  &--pl-sm {
    padding-left: 60px;
    @media (max-width: 992px) {
      padding-left: 0;
    }
  }

  &--pl-0 {
    padding-left: 0;
    padding-top: 0;
  }

}

.sidebar {
  --color: #F4F5F8;
  --radius: 8px;
  --smoothing-radius: 7.4px;
  background: #E9ECF1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  padding-top: 48px;
  @media (max-width: 576px) {
    display: none;
  }

  &__menu {
    margin-bottom: 25px;
    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
      margin-bottom: 15px;
    }
    @media (max-width: 576px) {
      width: 30px;
      height: 30px;
    }
  }

  &__icon {
    width: 94px;
    height: 72px;
    padding: 16px 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    @media (max-width: 576px) {
      width: 54px;
      height: 54px;
      padding: 14px;
      border-radius: 8px;
    }

    &:last-child {
      margin-top: auto;
      @media (max-width: 576px) {
        margin-top: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &--active {
      background: #F4F5F8;

      @media (max-width: 576px) {
        border: 1px solid #E9ECF1;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: var(--radius);
        height: calc(100% + 2 * var(--radius));
        background: radial-gradient( at top left, transparent var(--smoothing-radius), var(--color) var(--radius) ) no-repeat top right / var(--radius) var(--radius), radial-gradient( at bottom left, transparent var(--smoothing-radius), var(--color) var(--radius) ) no-repeat bottom 0 right 0 / var(--radius) var(--radius);
      }

    }

    &--top {
      margin-top: auto;
    }

    &.active {
      background: #4277FF;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

      &.red {
        background: #EA1E21;
      }

      &.green {
        background: #01C77F;
      }

      &.purple {
        background: #9300E4;
      }

    }

    &--lg {
      height: 88px;
      padding: 33px 30px;
      @media (max-width: 576px) {
        height: 54px;
        padding: 14px;
      }
    }

    &--sm {
      height: 45px;
      padding: 0 24px;
    }

  }

  &--fixed {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
  }

  &__logo {
    max-width: 100%;
    height: auto;
  }

}

.input-box {
  width: 100%;
  position: relative;

  &__icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-content: stretch;

  &--flex {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
  }

  &__block {
    padding: 30px 0;
    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-bottom: 30px;
      flex-direction: column;
      align-items: start;
    }
    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  &__setting-btn {
    padding: 10px 15px;
    font-weight: 400;
    width: auto;
    text-transform: none;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 15px;
    }
  }

  &__wrapper {
    display: flex;
    margin-bottom: 30px;
    @media (max-width: 1200px) {
      flex-direction: column;
    }

    &--pt {
      padding-top: 90px;
      @media (max-width: 576px) {
        padding-top: 75px;
      }
    }
    &--pt-sm {
      padding-top: 30px;
      @media (max-width: 576px) {
        padding-top: 20px;
      }
    }

    &--grey {
      background: #F0F3F4;
      flex: 1;
      margin-bottom: 0;
      padding-bottom: 30px;
    }

    &--pr {
      //padding-right: 340px;
      padding-right: 250px;
      @media (max-width: 1200px) {
        padding-right: 0;
      }
    }

    &--pb-none {
      padding-bottom: 0;
    }
  }

  &__user {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      width: 90px;
      height: 90px;
      margin-bottom: 30px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $black;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: $grey;

    &--mb {
      margin-bottom: 50px;
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: center;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__input {
    max-width: 425px;
  }

  &__button {
    font-size: 16px;
    text-transform: none;
    font-weight: 400;
    margin-left: 10px;
    min-width: 135px;
    width: auto;
    @media (max-width: 768px) {
      max-width: 100%;
      margin: 10px 0 0 0;
      font-size: 14px;
    }
  }

  &__link {
    font-size: 14px;
    color: #B5B5B5;
    line-height: 16px;
    transition: .2s linear;
    margin-top: 30px;

    &:hover {
      color: #a0a0a0;
    }

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  &__personal-account {
    text-align: center;
    padding: 100px 0;
    @media (max-width: 992px) {
      padding: 50px 0;
    }
    @media (max-width: 768px) {
      padding: 30px 0;
    }
  }

  &__services {
    padding: 30px 0;

    &--pb-none {
      padding-bottom: 0;
    }
  }

  &__services-title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  &--white {
    background: #fff;
  }

  &--light-gray {
    background: #F4F5F8;
  }


}


.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(45, 52, 54, 0.2);
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 10;
  display: none;

  &--visible {
    display: block;
  }

  &--index {
    z-index: 30;
  }
}

.modal {
  background: #FFFFFF;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 430px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  display: none;

  &--visible {
    display: block;
  }

  @media (max-width: 768px) {
    padding: 20px;
    width: calc(100% - 15px);
  }

  &--height {
    @media (max-width: 768px) {
      height: calc(100vh - 70px);
      overflow-y: auto;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $black;
    text-align: center;

    &--start {
      text-align: start;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__text {
    margin-bottom: 24px;
    color: $blue;
    line-height: 1.6;
    text-align: center;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  &__image {
    margin: 0 auto 15px auto;
    @media (max-width: 768px) {
      width: 100%;
      object-fit: contain;
    }
  }

  &__subtext {
    margin-bottom: 15px;
    font-size: 14px;
    color: $grey;
    text-align: center;

    &--medium {
      font-size: 16px;
      line-height: 140%;
    }

    &--start {
      text-align: start;
    }

    &--mb {
      margin-bottom: 30px;
    }
  }

  &__button {
    width: auto;
    height: 50px;
    border-radius: 5px;
    font-size: 16px;
    text-transform: none;
    padding: 15px 20px;
    font-weight: 400;
    @media (max-width: 768px) {
      height: 40px;
      width: 100%;
      margin-bottom: 8px;
      font-size: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &--selected {
      background: #EDEDED;
      color: $grey;
    }
  }

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 16px;
    color: $black;
    @media (max-width: 768px) {
      margin-bottom: 5px;
    }
  }

  &__link {
    color: $blue;
    font-size: 14px;
    line-height: 16px;
    transition: .2s linear;

    &:hover {
      color: $blue-hover;
    }

    @media (max-width: 768px) {
      margin-left: auto;
    }
  }

  &__upload-file {
    position: relative;
    margin-bottom: 20px;

    &:hover {
      .modal__button {
        background: $blue-hover;
      }

      .modal__button--selected {
        background: #e2e2e2;
        color: $grey;
      }
    }
  }

  &__upload-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__upload-item {
    color: $grey;
    font-size: 13px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__upload-close {
    margin-left: 5px;
    width: 10px;
    height: 10px;
    background: $blue;
    transition: .2s linear;
    @include mask-contain-center;
    mask: url("../images/images-nh-id/icon/close.svg");
    -webkit-mask: url("../images/images-nh-id/icon/close.svg");
    cursor: pointer;

    &:hover {
      background: #e21111;
    }
  }

  &__file {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
}

.aside-menu {
  &--disabled {
    pointer-events: none;
  }

  //padding: 30px;
  padding: 30px 30px 30px 15px;
  width: 100%;
  //max-width: 335px;
  max-width: 250px;
  @media (max-width: 1200px) {
    padding: 20px;
    max-width: 100%;
    order: -1;
  }
  @media (max-width: 768px) {
    padding: 10px;
  }


  &--padding-none {
    padding-top: 0;
    padding-bottom: 0;
    @media (max-width: 1200px) {
      margin-bottom: 40px;
    }
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }

  &--fix {

    background: #fff;
    z-index: 0;
    right: 0;
    height: 100%;
    padding-top: 130px;
    top: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    @media (max-width: 1200px) {
      position: relative;
      padding: 20px;
      max-width: 100%;
      order: 1;
      width: calc(100% - 30px);
    }
    @media (max-width: 576px) {
      width: calc(100% - 20px);
    }
  }

  &--indent {
    @media (max-width: 1200px) {
      margin: 30px auto 0 auto;
    }
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }

  &__button {
    color: #EA1E21;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    box-shadow: 0px 6px 10px rgba(234, 30, 33, 0.1);
    border-radius: 5px;
    padding: 15px 20px;
    border: 1px solid #EA1E21;
    margin-bottom: 20px;
    transition: .2s linear;
    @media (max-width: 992px) {
      padding: 12px 20px;
      margin-bottom: 10px;
    }
    @media (max-width: 768px) {
      padding: 10px 20px;
    }

    &--mt {
      margin-top: 20px;
      margin-bottom: 0;
      @media (max-width: 768px) {
        margin-top: 10px;
      }
    }

    &--between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--green {
      margin-bottom: 30px;
      box-shadow: 0px 6px 10px rgba(1, 199, 127, 0.1);
      border-color: $green;
      color: $green;

      &:hover {
        border-color: $green-hover;
        color: $green-hover;
      }

      @media (max-width: 576px) {
        margin-bottom: 20px;
      }
    }

    &--blue {
      color: #4277FF;
      border-color: #4277FF;
      box-shadow: 0px 6px 10px rgba(66, 119, 255, 0.1);
    }

    &--mb {
      margin-bottom: 30px;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    padding: 13px 20px;
    @media (max-width: 992px) {
      padding: 10px;
    }
  }


}

.profile {
  padding: 38px 0 78px 0;

  &--indent-none {
    padding: 0;
  }

  &__item {
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 30px;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    @media (max-width: 768px) {
      padding-bottom: 20px;
      margin-bottom: 30px;
    }
  }

  &__item-inner {
    padding: 0 30px;
    @media (max-width: 992px) {
      padding: 0 20px;
    }
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__container {
    width: 100%;
    max-width: 254px;
    @media (max-width: 768px) {
      max-width: 100%;
      text-align: center;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__photo {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: relative;
    @media (max-width: 992px) {
      width: 140px;
      height: 140px;
    }
    @media (max-width: 768px) {
      width: 90px;
      height: 90px;
    }
  }

  &__overlay {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(#404040, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    border-radius: 50%;
    transition: .1s linear;
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }

  &__photo-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: .2s linear;
    z-index: 4;
  }

  &__photo-box {
    margin-right: 30px;
    position: relative;
    @media (max-width: 768px) {
      margin: 0 0 20px 0;
    }
  }

  &__photo-wrapper {
    position: relative;
    border-radius: 50%;

    &:hover {
      .profile__overlay {
        opacity: 1;
        visibility: visible;
      }

      .profile__photo-close {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__upload-photo {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  &__title {
    margin-bottom: 32px;

    &--mb {
      margin-bottom: 20px;
    }
  }

  &__name {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 28px;
    color: $black;
    @media (max-width: 992px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  &__text {
    font-size: 14px;
    color: $grey;
    line-height: 20px;

    &--mr {
      margin-right: 20px;
    }

    &--mb {
      margin-bottom: 20px;
    }

    &--black {
      color: $black;
    }
  }

  &__authentication {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__plug {
    width: auto;
    font-weight: 400;
    font-size: 16px;
    padding: 8px 20px;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  &__email-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link {
    font-size: 14px;
    line-height: 16px;
    color: $orange;
    transition: .2s linear;
    min-width: 70px;
    display: inline-flex;
    justify-content: flex-end;

    &:hover {
      color: $orange-hover;
    }

    &--ml {
      margin-left: 30px;
      @media (max-width: 768px) {
        margin-left: 10px;
      }
    }

    &--hidden {
      display: none;
    }
  }

  &__email-label {
    margin-bottom: 10px;
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &--between {
      justify-content: space-between;
      margin-bottom: 23px;
    }
  }

  &__checkbox-label {
    margin-left: 15px;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    @media (max-width: 768px) {
      margin-left: 10px;
      font-size: 12px;
    }

    &--indent-none {
      margin: 0;
    }

    &--medium-grey {
      font-size: 16px;
      line-height: 24px;
      color: #404040;
      margin-right: 15px;
      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  &__checkbox-wrapper {
    margin-bottom: 30px;
  }
}

/*
switch {
  margin: 4rem auto;
}
*/

/* main styles */
/*
.switch {
  width: 24rem;
  position: relative;

  &--green {
    input ~ input:checked ~ .toggle-outside {
      background: $green !important;
      border-color: $green !important;
    }

    input ~ input:checked ~ .toggle-outside .toggle-inside {
      left: 25px !important;
    }

    .toggle-outside {
      background: #B5B5B5 !important;
      border-color: #B5B5B5 !important;
    }

    .toggle-inside {
      top: 4px !important;
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.switch input {
  position: absolute;
  top: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.switch input:checked {
  z-index: 1;
}

.switch input:checked + label {
  opacity: 1;
  cursor: default;
}

.switch input:not(:checked) + label:hover {
  opacity: 0.5;
}

.switch label {
  color: #fff;
  opacity: 0.33;
  transition: opacity 0.25s ease;
  cursor: pointer;
}

.switch .toggle-outside {
  height: 100%;
  border-radius: 2rem;
  padding: 0.25rem;
  overflow: hidden;
  transition: 0.25s ease all;
}

.switch .toggle-inside {
  border-radius: 5rem;
  background: #fff;
  border: 1px solid #EDEDED;
  position: absolute;
  top: 2px;
  transition: 0.25s ease all;
}

.switch--horizontal {
  width: 52px;
  height: 30px;
  font-size: 0;
  flex-shrink: 0;
}

.switch--horizontal input {
  width: 52px;
  height: 30px;
  margin: 0;
}

.switch--horizontal label {
  font-size: 1.5rem;
  line-height: 3rem;
  display: inline-block;
  width: 6rem;
  height: 100%;
  margin: 0;
  text-align: center;
}

.switch--horizontal .toggle-outside {
  background: #fff;
  border: 1px solid #EDEDED;
  position: absolute;
  width: 52px;
}

.switch--horizontal .toggle-inside {
  height: 24px;
  width: 24px;
  left: 4px;
}

//.switch--horizontal input:checked ~ .toggle-outside .toggle-inside {
//
//}
.switch--horizontal input ~ input:checked ~ .toggle-outside .toggle-inside {
  left: 22px;
}

.switch--horizontal input ~ input:checked ~ .toggle-outside {
  background: $blue;
  border-color: $blue;
}
*/
.back {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 20px;
  color: #404040;
  cursor: pointer;
  @media (max-width: 992px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }

  &--mb {
    margin-bottom: 50px;
  }

  &--mb-medium {
    margin-bottom: 30px;
    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }


  &__icon {
    border: 1px solid #B5B5B5;
    width: 34px;
    height: 34px;
    border-radius: 5px;
    background: $white;
    margin-right: 15px;
    transition: .2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      width: 7.4px;
      height: 13px;
    }

    @media (max-width: 992px) {
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }
    @media (max-width: 768px) {
      margin-right: 10px;
      width: 28px;
      height: 28px;
    }
  }

  &--grey {
    .back__icon {
      background: #D7D7D7;
    }

    &:hover {
      .back__icon {
        background: #bfbfbf;
      }
    }
  }

  &--lg {
    .back__icon {
      background: #EDEDED;
    }

    &:hover {
      .back__icon {
        background: #e0e0e0;
      }
    }
  }
}

.cards {
  &--mb {
    margin-bottom: 30px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
  }

  &__title {
    font-size: 14px;
    line-height: 16px;
    color: #7C7C7C;
    margin-bottom: 15px;
  }

  &__box {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__item {
    padding: 15px;
    background: linear-gradient(180deg, #373737 0%, #181818 100%);
    border-radius: 10px;
    width: 170px;
    height: 107px;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    &--add {
      cursor: pointer;
      background: #F6F6F6;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 768px) {
      margin: 0 0 15px 0;
    }

    &:hover {
      .cards__remove {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__dots {
    margin-right: 8px;
  }

  &__data {
    display: flex;
    align-items: center;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 2px;
    color: #fff;
  }

  &__remove {
    width: 12px;
    height: 12px;
    background: rgba(#fff, 0.5);
    @include mask-contain-center;
    mask: url("../images/images-nh-id/icon/close.svg");
    -webkit-mask: url("../images/images-nh-id/icon/close.svg");
    transition: .2s linear;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;

    &:hover {
      background: rgba(#fff, 1);
    }
  }
}

.entity {
  display: flex;
  align-items: center;

  &__icon {
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100px;
    height: 100px;
    margin-right: 30px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 992px) {
      width: 80px;
      height: 80px;
    }
    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
      margin-right: 15px;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    color: $black;
    margin-bottom: 10px;
    @media (max-width: 992px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    color: $grey;
  }
}

.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: #fff;
  @media (max-width: 992px) {
    padding: 15px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }

  &--mb {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  &__name {
    font-size: 16px;
    line-height: 19px;
    color: $black;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__open {
    @include mask-contain-center;
    width: 16px;
    height: 16px;
    background: #B5B5B5;
    margin-left: 35px;
    mask: url("../images/images-nh-id/icon/external.svg");
    -webkit-mask: url("../images/images-nh-id/icon/external.svg");
    transition: .2s linear;
    cursor: pointer;

    &:hover {
      background: $grey-hover;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__notification {
    font-size: 16px;
    line-height: 19px;
    color: $blue;
    transition: .2s linear;

    &:hover {
      color: $blue-hover;
    }

    &--disabled {
      color: #B5B5B5;
      cursor: default;
    }

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.logo-upload {
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }

  &__logo {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    padding: 5px;
    border: 1px solid #EDEDED;
    margin-right: 10px;
    flex-shrink: 0;
    @media (max-width: 768px) {
      margin: 0 0 10px 0;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__button {
    width: auto;
    padding: 15px 20px;
    background: #EDEDED;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    color: #404040;
    margin-right: 10px;
    text-align: center;
    transition: .2s linear;

    &:hover {
      background: #e3e3e3;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 5px;
      padding: 8px 20px;
      font-size: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__container {
    position: relative;
    margin-right: 10px;

    &:hover {
      .logo-upload__file:hover + .logo-upload__button {
        background: #e3e3e3;
      }
    }
  }

  &__file {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.upload {
  position: relative;

  &__file {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__hint {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    @media (min-width: 481px) {
      position: absolute;
      top: 50%;
      left: 100%;
      width: 160px;
      margin-left: 8px;
      margin-top: -8px;
    }
    @media (max-width: 480px) {
      margin-top: 8px;
    }

    &--red {
      color: $red-hover;
    }

    &--grey {
      color: #929FB9;
    }

  }

}

.doc {
  &--d-flex {
    display: flex;
  }
  &__type {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      margin-bottom: 8px;
    }
  }

  &__complete {
    margin-right: 10px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4A5773;

    &--blue {
      color: $blue;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
      //margin-bottom: 0;
    }

    &--mb {
      margin-bottom: 26px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    &--mb-medium {
      margin-bottom: 19px;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: start;
    }

    &--disabled {
      .doc__text {
        color: #929FB9;
      }

      .upload {
        pointer-events: none;
      }

      .doc__upload-btn {
        background: #F4F5F8;
        pointer-events: none;
      }
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: flex-end;
      width: 100%;
      margin-top: 10px;
    }
  }

  &__icon {
    margin-right: 30px;
    width: 24px;
    height: 24px;
    transition: .2s linear;
    background: $blue;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &--upload {
      mask: url("../images/images-nh-id/icon/upload-blue.svg");
      -webkit-mask: url("../images/images-nh-id/icon/upload-blue.svg");
    }

    &--edit {
      mask: url("../images/images-nh-id/icon/edit.svg");
      -webkit-mask: url("../images/images-nh-id/icon/edit.svg");
    }

    &--remove {
      mask: url("../images/images-nh-id/icon/remove.svg");
      -webkit-mask: url("../images/images-nh-id/icon/remove.svg");
    }

    &:hover {
      background: #6f95fc;
    }

    @media (max-width: 768px) {
      margin-right: 20px;
    }
  }

  &__icon-upload {
    position: relative;
    margin-right: 30px;
    @media (max-width: 768px) {
      margin-right: 20px;
    }
  }

  &__upload {
    @media (max-width: 576px) {
      width: 100%;
    }
  }



/*
  &__upload-file {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 24px;
    height: 24px;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
  }

  &__upload {
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
    }

    &--mt {
      @media (max-width: 768px) {
        margin-top: 8px;
      }
    }

    &--mr {
      margin-right: 10px;
    }
  }

  &__upload-file {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

    &:hover + .doc__upload-btn {
      //color: #fff;
      //background: $blue-hover;
      //border-color: $blue-hover;
    }
  }

  &__upload-btn {
    //padding: 7px 15px;
    //font-weight: 500;
    //font-size: 14px;
    //line-height: 16px;
    //color: $blue;
    //background: #fff;
    //border: 1px solid $blue;
    //border-radius: 8px;
    //transition: .2s linear;
    //text-align: center;
    @media (max-width: 768px) {
      //width: 100%;
      //font-size: 14px;
      //padding: 8px 20px;
    }

    &--width {
      min-width: 136px;
    }

    &--disabled {
      color: #929FB9;
      background: #F4F5F8;
      border: 1px solid #DEE2EA;
      cursor: not-allowed;
    }

  }
*/
  &__buttons {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__button {
    margin-left: 8px;
    @media (max-width: 576px) {
      width: 100%;
      margin: 8px 0 0 0;
    }
  }

}

.roadmap {
  padding: 50px 0;

  &__link {
    font-size: 18px;
    color: $orange;
    margin-bottom: 25px;
    transition: .2s linear;

    &:hover {
      color: $orange-hover;
    }
  }
}

.active-contracts {
  &--mt {
    margin-top: 27px;
  }

  &--top {
    margin: auto auto 0 auto;
    width: 100%;
    @media (max-width: 1200px) {
      margin-top: 30px;
    }
  }

  &__icon {
    margin-right: 16px;

    &--briefcase {
      width: 22px;
      height: 19px;
    }
  }

  &__no-projects {
    color: #B5B5B5;
    font-size: 12px;
    line-height: 14px;

  }

  &__header {
    display: flex;
    align-items: center;
    padding: 15px 16px;
    background: #F6F6F6;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #EDEDED;
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    color: #404040;
  }

  &__inner {
    padding: 20px 15px;
    border: 1px solid #EDEDED;
    border-radius: 0 0 10px 10px;
    height: 333px;
    overflow-y: auto;

    &--fit {
      height: fit-content;
    }
  }

  &__company {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__company-img {
    width: 50px;
    height: 50px;
    border: 1px solid #EDEDED;
    border-radius: 10px;
    margin-right: 15px;
    object-fit: contain;
    @include flex-row-center;
  }

  &__company-name {
    font-size: 14px;
    line-height: 20px;
    color: #161616;
  }

  &__company-position {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #404040;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border: 1px solid #EDEDED;
    border-radius: 6px;
    min-height: 44px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    color: #404040;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-y: hidden;

    &--grey {
      color: #7C7C7C;
    }
  }

  &__count {
    min-width: 24px;
    height: 24px;
    border: 1px solid #EA1E21;
    border-radius: 50%;
    @include flex-row-center;
    font-size: 12px;
    line-height: 14px;
    color: #EA1E21;
  }
}

.company-type {
  &--mb {
    margin-bottom: 30px;
    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__company {
    display: flex;
    align-items: center;
  }

  &__img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #EDEDED;
    margin-right: 15px;
    object-fit: contain;
    @media (max-width: 576px) {
      width: 50px;
      height: 50px;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #262026;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  &__tab {
    font-size: 18px;
    line-height: 21px;
    color: #404040;
    margin-right: 15px;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    padding: 0 5px 6px 5px;
    text-align: center;

    &--active {
      border-color: $green;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 576px) {
      width: 50%;

    }
  }
}

.edit-buttons {
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  &--center {
    @media (max-width: 768px) {
      margin-top: 25px;
      width: 100%;
      justify-content: center;
    }
  }
}

.button-edit {
  padding: 5px 20px;
  background: #EDEDED;
  border-radius: 5px;
  font-size: 16px;
  line-height: 30px;
  color: #404040;
  margin-right: 10px;
  transition: .2s linear;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: #dddddd;
  }

  @media (max-width: 576px) {
    width: 100%;
    margin: 0 0 8px 0;
    font-size: 14px;
    line-height: 25px;
  }

  &--white-grey {
    padding: 10px 30px;
    line-height: 19px;
    background: #fff;
    color: #7C7C7C;
    border: 1px solid #B5B5B5;

    &:hover {
      color: #fff;
      background: #B5B5B5;
    }
  }
}

.contracts-overhead {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #262026;

  &__count {
    font-weight: 400;
    margin-left: 10px;
    color: #7C7C7C;
  }
}

                                                        /*-- DiFis--*/
.settings {
  &__title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }
  }

  &__col {
    border-right: 1px solid #EDEDED;

    &:last-child {
      border-right: 0;
    }

    @media (max-width: 992px) {
      border-right: 0;
      border-bottom: 1px solid #EDEDED;
    }

    &--pr {
      padding-right: 25px;
      @media (max-width: 992px) {
        padding: 0 0 25px 0;
      }
    }

    &--pl {
      padding-left: 25px;
      @media (max-width: 992px) {
        padding: 25px 0 0 0;
      }
    }
  }

  &__overhead {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
    color: #000;
  }
}

.personal-info {
  &__overhead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 370px;
    grid-gap: 30px;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  &__company {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      margin: 0 0 20px 0;
    }
  }

  &__aside {
    @media (max-width: 1200px) {
      order: -1;
    }
  }

  &__preview {
    border: 1px solid #E6E8E8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    flex-shrink: 0;
    padding: 7px;
    background: #fff;
    @media (max-width: 576px) {
      width: 50px;
      height: 50px;
      margin-right: 15px;
    }
  }

  &__icon {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__overhead {
    margin-bottom: 18px;
  }

  &__name {
    font-size: 18px;
    line-height: 20px;
    color: #445A6C;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__card {
    margin-bottom: 17px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.aside-info {
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  border-radius: 4px;
  @media (max-width: 576px) {
    padding: 15px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 20px;
    color: #445A6C;
    @media (max-width: 576px) {
      margin: 0 0 20px 0;
    }
  }

  &__button {
    padding: 8.5px 20px;
    font-size: 13px;
    line-height: 13px;
    color: #B5B5B5;
    border: 1px solid #B5B5B5;
    border-radius: 2px;
  }

  &__row {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #EDEDED;
    display: flex;
    align-items: flex-start;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    object-fit: contain;
    margin-right: 10px;
  }

  &__text {
    font-size: 14px;
    line-height: 15px;
    color: #7C7C7C;

    span {
      color: #161616;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 15px;
    color: #58748B;
    margin-top: 10px;
  }

  &__box {
    padding-top: 5px;
  }
}

.personal-payment-card {
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  border-radius: 4px;
  @media (max-width: 576px) {
    padding: 15px;
  }

  &__title {
    font-size: 18px;
    line-height: 20px;
    color: #445A6C;
    margin-bottom: 23px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1330px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__icon {

  }

  &__name {

  }

  &__company {
    display: flex;
    align-items: center;
    margin-right: 20px;
    @media (max-width: 1330px) {
      margin: 0 0 20px 0;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 10px;
  }

  &__panel {
    display: flex;
    align-items: center;
    @media (max-width: 1330px) {
      width: 100%;
      justify-content: space-between;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__button {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 576px) {
      width: 100%;
      margin: 0 0 5px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__cost {
    font-size: 18px;
    line-height: 20px;
    color: #5CB85C;
    margin-right: 20px;
    @media (max-width: 576px) {
      margin: 0 0 20px 0;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 19px;
    color: #7C7C7C;
  }
}

.main-button {
  padding: 8.5px 20px;
  font-size: 13px;
  line-height: 13px;
  color: #58748B;
  border: 1px solid #58748B;
  border-radius: 5px;
  text-align: center;
  transition: .2s linear;

  &--blue {
    border-radius: 4px;
    border: 1px solid #4277FF;
    color: #4277FF;
    padding: 7px 10px;
  }

  &--sm {
    padding: 4.5px 20px;
    background: #4277FF;
    border-color: #4277FF;
    color: #fff;

    &:hover {
      background: $blue-hover;
    }
  }

  &--disabled {
    pointer-events: none;
    background: #C7D6FF;
    border-color: #C7D6FF;
  }

  &--grey {
    padding: 7px 10px;
    background: #E6E8E8;
    border: 1px solid #718EA5;
    border-radius: 4px;
    color: #445A6C;

    &:hover {
      background: #dcdede;
    }
  }

  &--light-grey {
    font-size: 14px;
    padding: 3.5px 21px;
    border: 1px solid #B5B5B5;
    border-radius: 5px;
    color: #B5B5B5;
  }

}

.setting-button {
  padding: 14.5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #58748B;
  border-radius: 2px;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  transition: .2s linear;

  &__icon {
    width: 17px;
    height: 17px;
    margin-right: 8px;
  }

  &:hover {
    background: #4e6a81;
  }
}


.main-input {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #C8C7CC;
  padding: 11px 5px 9px 5px;
  font-size: 13px;
  line-height: 14px;
  color: #5B5B60;
  transition: .2s linear;

  &::placeholder {
    color: #9B9B9F;
  }

  &:focus {
    background: #F7F7F8;
    border-color: #AEACB4;
  }

  &:disabled {
    background: #F7F7F8;

    &::placeholder {
      color: #9B9B9F;
    }
  }
}

.uploading {
  //padding: 8px 0;
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  @media (max-width: 576px) {
    //flex-direction: column;
    //align-items: flex-start;
  }

  &--mb {
    margin-bottom: 20px;
  }

  &__box {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  &__icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $blue;
    border-radius: 8px;

    i {
      color: $blue;
      font-size: 16px;
    }

    &--mr {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: 576px) {
        margin-right: 15px;
      }
    }

  }

  &__name {
    font-size: 13px;
    line-height: 19px;
  }

  &__panel {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      width: 100%;
      justify-content: flex-end;
      margin-top: 8px;
    }
  }

  &__checked {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

.company-info {
  &__inner {
    //display: flex;
    //align-items: flex-start;
  }

  &__box {
    width: 50%;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 576px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &--mb {
      margin-bottom: 28px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &--align-end {
      align-items: flex-end;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__position {
    font-size: 13px;
    line-height: 108%;
    color: #01C77F;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    @media (max-width: 576px) {
      margin-top: 8px;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    flex-shrink: 0;
  }

  &__hint {
    font-size: 12px;
    line-height: 130%;
    color: #B5B5B5;
    @media (max-width: 576px) {
      margin-top: 5px;
    }

    &--pb {
      padding-bottom: 9px;
      @media (max-width: 576px) {
        margin: 9px 0 0 0;
        padding-bottom: 0;
      }
    }
  }
}

.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  color: #404040;
  margin-bottom: 5px;

  &--mb {
    margin-bottom: 10px;
  }
}

.desc {
  font-size: 13px;
  line-height: 100%;
  color: #7C7C7C;
}

input,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.personal-account-company-wrapper {
  input,
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -moz-appearance: none;
  }
}

// news

.invest-table {
  border-spacing: 0;
  width: 100%;

  tbody tr {
    cursor: pointer;
  }

  th {
    padding: 13px 16px 14px;
    background: #F4F5F8;
    color: #2D3436;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;

    &:first-child {
      text-align: left;

      .invest-table__row {
        justify-content: flex-start;
      }
    }

    &:last-child {
      text-align: right;

      .invest-table__row {
        justify-content: end;
      }
    }

  }

  td {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #424E66;
    padding: 13px 16px;
    border-top: 1px solid #EDEDED;
    white-space: nowrap;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    &.start {
      text-align: left;
    }

    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    &.top {
      vertical-align: top;
    }

    &.normal {
      white-space: normal;
    }

    &.bold {
      font-weight: 600;
    }

  }

  &--no-border {

    tr {

      th {
        color: #929FB9;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        background: none;
        border-bottom: 1px solid #DEE2EA;
      }

      th, td {
        padding: 7px 16px 8px;
        border-top: none;
      }

      &.tr-active {
        cursor: default;

        td {
          background: #F4F5F8;
          border-top: 1px solid #DEE2EA;
          border-bottom: 1px solid #DEE2EA;
          &:first-child {
            border-radius: 8px 0 0 8px;
            border-left: 1px solid #DEE2EA;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
            border-right: 1px solid #DEE2EA;
          }
        }

      }

    }

  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;

    &--start {
      justify-content: flex-start !important;
    }

    &--end {
      justify-content: flex-end !important;
    }

    &.center {
      justify-content: center !important;
    }

    &.right {
      justify-content: right;
    }
  }

  &__filters {
    display: flex;
    flex-direction: column;
    margin-left: 9px;
  }

  &__arrow {
    margin-bottom: 8px;
    cursor: pointer;
    fill: #B5B5B5;

    &:last-child {
      margin-bottom: 0;
    }

    &--first {
      transform: rotate(180deg);
    }

    &--active {
      path {
        fill: #4277FF;
      }
    }
  }

  &--sm-height {
    td {
      padding: 7px 16px 8px;
    }
  }

  &--xs-height {
    th, td {
      padding: 5px 8px;
    }
  }

  &--striped {

    th {
      color: #929FB9;
      background: #E9ECF1;
      font-weight: 600;
    }

    tr {
      td {
        border-top: none;
      }
      &:nth-of-type(2n) {
        td {
          background: #F4F5F8;
        }
      }
    }
  }

}

.invest-cards {
  display: none;
  @media (max-width: 576px) {
    display: block;
  }

  &__item {
    background: #fff;
    border: 1px solid #E9ECF1;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 16px;
  }

  &__row {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    color: #424E66;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 8px;
  }

  &__content {
    color: #424E66;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;

    &--lg {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }

    &--lg-height {
      padding: 8px;
    }

  }

  &__docs {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &--disabled {
      opacity: 0.6;
    }

  }

  &__button {
    display: inline-flex;
    margin: 0 8px 8px 0;
  }

  &__recall-button {
    width: 100%;
  }

}

@keyframes trey {
  100%{
    margin-left: -300%;
  }
}

.td-overflow {
  white-space: nowrap;
  width: 145px;
  overflow: hidden;

  @media (max-width: 576px) {
    width: 350px;
  }
  @media (max-width: 575px) {
    width: 138px;
  }
  span {
    display: inline-block;
    width: 350px;
    &:hover {
      animation: trey 7s linear;
    }
  }
}

.top-info-panel {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
    margin-bottom: 16px;
  }

  &--md-column {
    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 16px;
    }
  }

  &--lg-column {
    @media (max-width: 992px) {
      flex-direction: column;
      margin-bottom: 16px;
    }
  }

  &__company {
    display: flex;
    align-items: center;
    @media (max-width: 1200px) {
      margin-bottom: 20px;
    }
  }

  &__logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #E6E8E8;
    margin-right: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media (max-width: 576px) {
      width: 50px;
      height: 50px;
    }
  }

  &__img {
    width: 80%;
    height: auto;
    object-fit: contain;
  }

  &__name {
    font-size: 18px;
    line-height: 20px;
    color: #161616;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__funds {
    font-size: 18px;
    line-height: 20px;
    color: #404040;

    span {
      font-weight: 700;
      color: #01C77F;
    }

    @media (max-width: 576px) {
      font-size: 16px;
    }

    &--mr {
      margin-right: 10px;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    @media (max-width: 1200px) {
      margin-bottom: 20px;
    }
  }

  &__tab {
    padding: 9px 15px;
    font-size: 18px;
    line-height: 20px;
    color: #4277FF;

    &.active {
      background: #4277FF;
      border-radius: 10px;
      color: #fff;
    }

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__button {
    width: 34px;
    height: 34px;
    border-radius: 5px;
    background: #01C77F;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-right: 0;
    }

    &.red {
      background: #EA1E21;
    }
  }

  &__trade {
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__doc-buttons {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__doc-button {
    margin-left: 8px;
    @media (max-width: 768px) {
      margin: 0 0 8px 0;
    }
    &:last-child {
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }
  }

  &__assets-buttons {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__assets-button {
    margin-left: 8px;
    @media (max-width: 768px) {
      margin: 0 0 8px 0;
    }
    &:last-child {
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }
  }

}

.news-feed {
  &--pl {
    padding-left: 20px;
    //margin-top: 30px;
  }

  &--border {
    border-top: 1px solid #DEE2EA;
    padding-top: 20px;
  }

  &__overhead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 16px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #424E66;
  }

  &__icon {
    margin-left: 10px;
    width: 17px;
    height: 16px;
    flex-shrink: 0;
  }

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.news-block {
  &__name {
    font-size: 14px;
    line-height: 15px;
    color: #404040;
    margin-bottom: 8px;
  }

  &__desc {
    font-size: 13px;
    line-height: 19px;
    color: #7C7C7C;
    margin-bottom: 8px;
  }

  &__info {
    font-size: 12px;
    line-height: 13px;
    color: #4277FF;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.text-blue {
  color: $blue;
}

.text-green {
  color: $green;
}

.text-red {
  color: $red-hover;
}

.text-purple {
  color: $purple;
}

.text-grey {
  color: #929FB9;
}

.text-dark-grey {
  color: #4A5773;
}

.text-orange {
  color: #FE9534;
}
.bg-green {
  background: $green-light;
}

.footer-difis {
  padding: 15px 30px 16px;
  margin-top: auto;
  box-shadow: inset 0px 1px 0px #E9ECF1;
  /*-- footer fixed
  position: fixed;
  bottom: 0;
  right: 0;
  left: 94px;
  z-index: 100;
  background: #F4F5F8;
   --*/

  &--pl-0 {
    padding-left: 0;
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    color: #929FB9;

    a {
      display: inline;
    }

  }

  @media (max-width: 992px) {
    padding: 20px 15px;
  }
  @media (max-width: 576px) {
    padding: 20px 10px;
  }
}
.main-select {
  -moz-padding-start: calc(.85rem - 3px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4.99999 3.78145L8.29999 0.481445L9.24266 1.42411L4.99999 5.66678L0.757324 1.42411L1.69999 0.481445L4.99999 3.78145Z' fill='%23929FB9'/%3e%3c/svg%3e ");  background-position: right .85rem center;
  background-repeat: no-repeat;
  border: 1px solid #DEE2EA;
  border-radius: 8px;
  color: #000;
  display: block;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  padding: 8px 2.55rem 8px 12px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;
  margin-bottom: 16px;
  &:focus-visible {
    outline: none;
  }
}
.main-select-wrapper {
  .select2-selection__rendered {
    font-size: 13px;
    line-height: 34px !important;
  }

  .select2-selection--single {
    height: 34px !important;
  }

  &--min {
    .select2-container {
      width: 84px !important;
    }
  }
}


.column {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.load-button {
  padding: 8.5px 20px;
  font-size: 13px;
  line-height: 13px;
  color: #4277FF;
  border: 1px solid #4277FF;
  border-radius: 4px;
  text-align: center;
  transition: .2s linear;

  @media (min-width: 576px) {
    margin-left: 7px;
  }

  &--blue {
    color: #fff;
    background: #4277FF;
    @media (min-width: 320px) {
      margin-top: 5px;
    }
  }
}

.sell-btn {
  padding: 8.5px 20px;
  font-size: 13px;
  line-height: 13px;
  color: #fff;
  background: #4277FF;
  border: 1px solid #4277FF;
  border-radius: 4px;
  text-align: center;
  transition: .2s linear;
}

.input-file {
  display: none;
}

.add-account__icon {
  margin-right: 9px;
}
.add-account-button {
  margin-right: 8px;
  @media (max-width: 576px) {
    margin: 0 0 8px 0;
  }
  i {
    color: $blue-hover;
    font-size: 16px;
    margin-right: 10px;
  }
}

.doc-upload {
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__file {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__btn {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    //background: #4277FF;
    border-radius: 8px;
    transition: 0.2s linear;
    text-align: center;
    flex-shrink: 0;
    width: auto;

    &--green {
      background: $green;
    }

  }

  &__text {
    display: inline-block;
    color: #404040;
    font-size: 14px;
    line-height: 14px;
    margin-left: 10px;
  }

  &__cancel {
    position: relative;
    z-index: 50;
    cursor: pointer;
    margin-left: 5px;
  }

  &__hint {
    display: inline-block;
    color: $red-hover;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
    @media (min-width: 481px) {
      
    }
    @media (max-width: 576px) {
      margin-top: 8px;
    }

    &--grey {
      color: #929FB9;
    }

  }

}

.area-upload {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  position: relative;

  &__file {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__btn {
    padding: 16px;
    font-size: 14px;
    line-height: 16px;
    background: #F4F5F8;
    border-radius: 8px;
    transition: 0.2s linear;
    text-align: center;
    margin-bottom: 19px;
    //flex-shrink: 0;

    i {
      color: $blue;
      font-size: 48px;
      margin-bottom: 8px;
    }

  }

  &__help-text {
    color: #424E66;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__help-subtext {
    color: #929FB9;
    font-weight: 300;
  }

  &__item {
    color: $blue-hover;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 14px;
    background-repeat: no-repeat;
    background-position: left center;

    i {
      color: #929FB9;
      font-size: 16px;
      margin-right: 10px;
    }

    &--error {
      color: $red-hover;
      i {
        color: $red-hover;
      }
    }

    &--text-error {
      color: $red-hover;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      margin-top: 5px;
    }

  }

  &__cancel {
    //position: relative;
    //z-index: 50;
    //cursor: pointer;
    //margin-left: 5px;
  }

}

                                                    /*Auth page*/
.auth {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1280px) {
    width: 1177px;
    margin: 0 auto;
    padding: 32px 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

}
.auth__head {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
  @media (min-width: 1280px) {
    margin-bottom: 80px;
  }
}
.auth__brands {
  display: none;
  @media (min-width: 1280px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 80px;
  }
}
.auth__brand {
  display: flex;
  justify-content: center;
}
.auth-form {
  background: #F4F5F8;
  border: 1px solid #D3D8E3;
  box-shadow: 0px 20px 40px rgba(66, 78, 102, 0.08);
  border-radius: 8px;
  width: 464px;
  padding: 24px;
  margin: 0 auto 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 528px) {
    width: 100%;
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 24px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    @media (max-width: 528px) {
      flex-direction: column;
    }
  }

  &__button {
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(66, 119, 255, 0.1);
    border-radius: 8px;
    color: $blue;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    width: 200px;
    height: 48px;
    @media (max-width: 528px) {
      width: 100%;
      margin-bottom: 8px;
    }
  }

}
.input-password {
  position: relative;
}
.password-toggle {
  display: block;
  color: #929FB9;
  font-size: 16px;
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 16px;
  cursor: pointer;
}
.auth-form__recovery {
  text-align: center;
}
.auth-form__recovery-link {
  color: #929FB9;
  font-size: 14px;
  line-height: 14px;
}
.auth__support {
  color: #929FB9;
  font-size: 14px;
  text-align: center;
}
.auth__support-link {
  display: inline;
  color: #424E66;
}
.auth-partners {
  display: none;
  @media (min-width: 1280px) {
    display: block;
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 36px;
  }

  &__brands {
    display: flex;
    justify-content: center;
  }

  &__brand {
    margin: 0 24px;

  }

}
/*Auth page end*/

                                                    /*Auth modal*/

.auth-tel {

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  &__text {
    color: #424E66;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;

    &--light {
      color: #B3B3B3;
    }

  }

  &__input {
    height: 40px;
  }

  &__subtext {
    color: #B3B3B3;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

  }

  &__text, &__subtext {

    span {
      color: $blue;
      cursor: pointer;
    }

  }

}

/*Auth modal end*/

.content-wrapper {
  display: flex;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  @media (max-width: 576px) {
    padding: 16px 24px;
  }

  &--md-row {
    @media (max-width: 1200px) and (min-width: 768px)  {
      flex-direction: row;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

}
.content-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  &--overflow {
    overflow: hidden;
  }

  &--resp {
    flex: 0 1 auto;
  }

}
.aside {
  flex: 0 0 auto;
  margin-left: 24px;
  margin-bottom: 24px;
  @media (max-width: 1280px) {
    margin: 30px auto 10px;
  }
  @media (max-width: 1280px) {
    display: none;
  }

  &--sm {
    width: 205px;
    @media (max-width: 1280px) {
      width: 100%;
    }
  }

  &--md {
    width: 342px;
    @media (max-width: 1280px) {
      width: 100%;
    }
  }

  &--lg {
    width: 500px;
    @media (max-width: 1280px) {
      width: 100%;
    }
  }

  &--border {
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    padding: 16px;
  }

  &--pt {
    padding-top: 43px;
    @media (max-width: 1280px) {
      padding-top: 0;
    }
  }

  &--ml-lg {
    margin-left: 32px;
    @media (max-width: 1280px) {
      margin: 30px auto 10px;
    }
  }

  &--resp {
    flex: 1 1 auto;
    margin-left: 0;
    @media (max-width: 1280px)  {
      display: block;
      margin: 0 0 24px;
    }
    @media (max-width: 767px)  {
      width: 100%;
    }
  }

}
.notify {

  &--border {
    //border-top: 1px solid #DEE2EA;
  }

  &__title {
    color: #424E66;
    font-weight: 500;

    &--border {
      //font-size: 16px;
      //line-height: 24px;
      //border-top: 1px solid #DEE2EA;
      //padding: 20px 0 0 16px;
      //margin: 0 0 20px 0;
    }

    &--active {

    }

    &--block {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      background: #E9ECF1;
      border-radius: 8px;
      padding: 12px 16px;
      height: 48px;
      margin-bottom: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: #929FB9;
      }

      i {
        color: #929FB9;
        font-size: 24px;
        opacity: 0.5;
      }

    }

  }

  &__item {
    position: relative;
    margin-bottom: 16px;

    &--mb-sm {
      margin-bottom: 8px;
    }

  }

  &__text {
    color: #424E66;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    padding-right: 20px;
    margin-bottom: 8px;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 19px;
    line-height: 1;
    cursor: pointer;
    i {
      color: #D3D8E3;
      font-size: 16px;
    }

  }

  &__row {
    display: flex;
    align-items: baseline;
  }

  &__date {
    color: #929FB9;
    font-size: 11px;
    font-weight: 500;
    margin-right: 4px;
  }

  &__time {
    color: #929FB9;
    font-size: 11px;
    font-weight: 500;
    margin-right: 8px;
  }

  &__author {
    color: #929FB9;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;

    &--primary {
      color: #4277FF;
    }

    &--success {
      color: #01C77F;
    }

    &--dangerous {
      color: #EE4D4F;
    }

    &--secondary {
      color: #7D00DF;
    }

  }

  &--new {
    position: relative;
    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #EA1E21;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }

}
.box {
  border-top: 1px solid #DEE2EA;
  border-right: 1px solid #DEE2EA;
  border-bottom: 1px solid #DEE2EA;
  border-radius: 8px;
  border-left: 3px;
  padding: 16px;

  &--blue {
    border-left: 3px solid #4277FF;
  }

  &--green {
    border-left: 3px solid #01C77F;
  }

  &--red {
    border-left: 3px solid #EE4D4F;
  }

  &--bright-red {
    border-left: 3px solid #EA1E21;
  }

  &--purple {
    border-left: 3px solid #7D00DF;
  }

}

                                            /*Quick-Panel*/
.quick-panel {
  display: flex;
  flex-wrap: wrap;
  width: 1456px;
  @media (min-width: 2584px)  {
    width: 2184px;
  }
  @media (max-width: 2583px) and (min-width: 2220px)  {
    width: 1820px;
  }
  @media (max-width: 1919px) and (min-width: 1600px)  {
    width: 1092px;
  }
  @media (max-width: 1599px) and (min-width: 1128px)  {
    width: 728px;
  }
  @media (max-width: 1127px) and (min-width: 768px)  {
    width: 364px;
  }
  @media (max-width: 767px) {
    flex-wrap: nowrap;
    flex-direction: column;
    width: auto;
  }

  &__col {
    margin-right: 24px;
    @media (max-width: 767px) {
      margin-right: 0;
    }
  }

  &__item {
    width: 340px;
    height: 251px;
    background: #FFFFFF;
    border: 1px solid #E9ECF1;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 100%;
    }

  }

  &__body {
    overflow-x: auto;
    width: 100%;
    padding: 0 16px;
    margin-top: 16px;
  }

  &__table {
    width: 100%;
    border-spacing: 0;

    tr {

      th {
        color: #B3B3B3;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        text-align: left;
        padding: 6px 8px;
      }

      td {
        color: #2C2C2C;
        font-weight: 600;
        font-size: 12px;
        border-bottom: 1px solid #E9ECF1;
        padding: 5px 8px;
        height: 32px;

        img {
          margin: 0 auto;
        }

      }

      th, td {

        &.right {
          text-align: right;
        }

        &.center {
          text-align: center;
        }

        &:first-child, &:last-child {
          padding: 3px 0 4px;
        }

      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }

    }
  }

  &__tag {
    display: inline-block;
    background: #fff;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 7px 3px;
    height: 21px;
    text-align: center;
    white-space: nowrap;

    &--border-grey {
      color: #929FB9;
      border: 1px solid #929FB9;
    }

    &--border-green {
      color: $green;
      border: 1px solid $green;
    }

    &--border-red {
      color: $red-hover;
      border: 1px solid $red-hover;
    }

    &--blue {
      color: #fff;
      background: $blue;
    }

    &--green {
      color: #fff;
      background: $green;
    }

    &--red {
      color: #fff;
      background: $red-hover;
    }

    &--lg {
      width: 104px;
    }

    &--sm {
      width: 55px;
    }

  }

  &__all {
    color: #929FB9;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 251px;
    color: $blue-gray-300;
    border-radius: 8px;
    margin-bottom: 24px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23BDC5D5FF' stroke-width='2' stroke-dasharray='10%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    transition: .2s linear;
    @media (max-width: 1919px) and (min-width: 1600px)  {
      width: 340px;
    }
    @media (max-width: 1599px) and (min-width: 1128px)  {
      width: 704px;
    }
    @media (max-width: 1599px) {
      height: 100px;
    }
    @media (max-width: 1127px) and (min-width: 768px)  {
      width: 340px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }

    /*
    @media (max-width: 1919px) and (min-width: 1600px)  {
      //width: 1068px;
    }
    @media (max-width: 1919px) {
      //height: 100px;
    }
    @media (max-width: 1599px) and (min-width: 1128px)  {
      //width: 704px;
    }
    @media (max-width: 1127px) and (min-width: 768px)  {
      //width: 340px;
    }
    @media (max-width: 767px) {
      width: 100%;
      height: 100px;
    }

     */

    &:hover {
      background-color: #E9ECF1;
    }

    i {
      font-size: 24px;
    }

    &--strerch {
      @media (min-width: 768px) {
        height: auto;
      }
    }

  }
/*
  .quick-panel__col {
    &:nth-of-type(5) {
      .quick-panel__add {
        width: 1432px;
        height: 100px;
        @media (max-width: 1919px) and (min-width: 1600px)  {
          width: 340px;
          height: 251px;
        }
        @media (max-width: 1919px) {
          //height: 100px;
        }
        @media (max-width: 1599px) and (min-width: 1128px)  {
          width: 704px;
          height: 100px;
        }
        @media (max-width: 1127px) and (min-width: 768px)  {
          width: 340px;
        }
        @media (max-width: 767px) {
          width: 100%;
          //height: 100px;
        }
      }
    }
  }



  .quick-panel__col {
    &:nth-of-type(4n) {
      .quick-panel__add {
        width: 340px;
        height: 251px;
      }
    }
  }
  */

}

.quick-panel-head {
  border-radius: 8px;
  padding: 12px 16px;
  height: 48px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  &--blue {
    background: $blue-light;
  }

  &--red {
    background: $red-light;
  }

  &__icon {
    width: 24px;
    margin-right: 9px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  &__more {
    margin-left: auto;
    i {
      font-size: 24px;
    }
    &--blue {
      i {
        color: $blue;
        opacity: 0.5;
      }
    }
    &--red {
      i {
        color: $red;
        opacity: 0.5;
      }
    }
  }

}

/*Quick-Panel end*/
                                                    /*User account*/
.account-tabs {
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #DFE6E9;

  &--lg {
    max-width: 830px;
  }

  &--mb {
    margin-bottom: 16px;
  }

  &--mb-lg {
    margin-bottom: 24px;
  }

  &--wrap {
    @media (max-width: 774px) {
      flex-wrap: wrap;
    }
    .account-tabs__item {
      @media (max-width: 774px) {
        margin-top: 16px;
      }
    }
  }

  &__item {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    border-bottom: 2px solid transparent;
    padding-bottom: 13px;
    margin-right: 24px;
    cursor: pointer;
    text-align: left;
    display: flex;
    @media (max-width: 576px) {
      font-size: 14px;
      line-height: 16px;
      width: auto;
      padding-bottom: 8px;
    }

    &--active {
      color: $blue;
      border-bottom: 2px solid $blue-hover;
      cursor: default;
    }

    &--active-green {
      color: $green;
      border-bottom: 2px solid $green;
      cursor: default;
    }

    &--active-red {
      color: $red-hover;
      border-bottom: 2px solid rgba(238, 77, 79, 0.5);
      cursor: default;
    }

    &--long {
      @media (max-width: 1100px) {
        font-size: 14px;
      }
      @media (max-width: 768px) {
        font-size: 13px;
      }
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

  }

}

.setting-box {
  background: #fff;
  border: 1px solid #E9ECF1;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;

  &--mb {
    margin-bottom: 16px;
  }

}
.user-account {

  &__head {
    color: #4A5773;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;

    span {
      color: #929FB9;
    }

  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #424E66;
    margin-bottom: 24px;
  }

  &__row {
    display: flex;
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__foto {
    width: 136px;
    flex-shrink: 0;
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &__foto-img {
    margin-bottom: 16px;
    @media (max-width: 576px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__button {
    max-width: 140px;
    height: 32px;
    @media (max-width: 576px) {
      max-width: none;
    }

    &--ml {
      margin-left: 8px;
      @media (max-width: 576px) {
        margin: 8px 0 0 0;
      }
    }

  }
  &__check-button {
    width: 32px;
    height: 32px;
    padding: 0;
    flex-shrink: 0;
  }

  &__name {
    margin-left: 32px;
    flex-grow: 1;
    @media (max-width: 576px) {
      margin: 24px 0 0 0;
    }
  }

  &__info {
    margin-top: 24px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  &__button-gos {
    color: #1466AC;
    border: 1px solid #1466AC;
    width: 220px;
    padding: 5px 16px 5px;
    @media (max-width: 480px) {
      width: 100%;
      margin-bottom: 8px;
    }

    img {
      margin-right: 8px;
    }

  }

  &__button-confirm {
    width: 127px;
    @media (max-width: 480px) {
      width: 100%;
    }
  }

}

.personal-account__row--no-column {
  .user-account__button--ml {
    @media (max-width: 576px) {
      margin: 0 0 0 8px;
    }
  }
}

.user-account-hint {
  border: 1px solid #DEE2EA;
  border-radius: 8px;
  padding: 20px 16px 16px;
  margin-bottom: 24px;

  &__title {
    color: $blue;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  &__text {
    color: #4A5773;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

}

/*User account end*/
                                            /*Pers account*/
.pers-account {

  &__col {
    display: flex;
  }

  &__item {
    width: 100%;
    min-height: 176px;
    cursor: pointer;
  }

  &__body {
    padding-right: 30px;
  }

  &__services {
    display: inline-block;
    padding: 8px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    margin: 0 4px 4px 0;

    &--blue {
      color: $blue;
      background: rgba(66, 119, 255, 0.1);
    }

    &--green{
      color: $green;
      background: rgba(1, 199, 127, 0.1);
    }

    &--red {
      color: $red-hover;
      background: rgba(234, 30, 33, 0.1);
    }

    &--purple {
      color: $purple;
      background: rgba(125, 0, 223, 0.1);
    }

  }

}

.pers-account-head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &__img {
    width: 50px;
    margin-right: 8px;
  }

  &__name {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  &__text {
    color: #929FB9;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }

  &__more {
    margin-left: auto;
    cursor: pointer;
    i {
      color: #929FB9;
      font-size: 24px;
      opacity: 0.5;
    }
  }

}

/*Pers account end*/
                                              /*User account*/
.account-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  @media (max-width: 576px) {
    flex-direction: column;
  }

  &__back {
    color: #4A5773;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      margin-bottom: 16px;
    }
  }

  &__back-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 8px;
    i {
      color: #929FB9;
      font-size: 16px;
      opacity: 0.5;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__edit-button {
    margin-left: 8px;
  }

}
.account-info {

  &__main-group {
    margin-bottom: 16px;
  }

  &__main-title {
    color: #424E66;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 4px;
  }

  &__main-text {
    color: #4A5773;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }

  &__main-docs-item {
    color: #4277FF;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 14px;
    cursor: pointer;
    i {
      color: #929FB9;
      font-size: 16px;
      margin-right: 4px;
    }
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;
  }

  &__services {
    width: 100%;
  }

  &__assets {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__services-item {
    display: flex;
    align-items: center;
    border: 1px solid #DEE2EA;
    border-radius: 4px;
    padding: 7px 24px 8px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__services-icon {
    width: 24px;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__services-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    margin-right: 16px;
  }

  &__services-switch {
    margin-left: auto;
  }

  &__col {
    display: flex;
  }

  &__head {
    margin-bottom: 24px;
  }

  &__chart {
    flex-grow: 1;
    display: flex;
    margin-bottom: 16px;
  }

}

.chart {
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column;
  }

  &__image {
    width: 185px;
    margin-right: 32px;
    position: relative;
    @media (max-width: 480px) {
      margin: 0 0 32px 0;
    }
  }

  &__legend {

    &--row {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .chart__legend-item {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

    }

  }

  &__legend-item {
    color: #424E66;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 12px;
    padding-left: 12px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      margin-top: -4px;
      left: 0;
    }

    &--green {
      span {
        color: $green;
      }
      &::before {
        background: $green;
      }
    }

    &--blue {
      span {
        color: $blue;
      }
      &::before {
        background: $blue;
      }
    }

    &--grey {
      span {
        color: #424E66;
      }
      &::before {
        background: #D3D8E3;
      }
    }

    &--violet {
      span {
        color: $violet;
      }
      &::before {
        background: $violet;
      }
    }

    &--mint {
      span {
        color: $mint;
      }
      &::before {
        background: $mint;
      }
    }

    &--cyan {
      span {
        color: $cyan;
      }
      &::before {
        background: $cyan;
      }
    }

    &--orange {
      span {
        color: $orange-light;
      }
      &::before {
        background: $orange-light;
      }
    }

    &--red {
      span {
        color: $watermelon;
      }
      &::before {
        background: $watermelon;
      }
    }

    &--sm {
      padding-left: 10px;

      &::before {
        width: 6px;
        height: 6px;
        margin-top: -3px;
      }

    }

  }

  &__total {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 116px;
    text-align: center;
    position: absolute;
    top: 75px;
    left: 35px;
    right: 35px;
  }

  &__total-sum {
    color: #636E72;
  }

  &--column {
    flex-direction: column;

    .chart__image {
      width: 100%;
      height: 160px;
      margin: 0 0 16px;

      @media (max-width: 400px) {
        max-width: 270px;
      }

      &--auto {
        width: auto;
      }

    }

  }

}

.account-edit {

  &__docs {
    padding: 24px;
    border-bottom: 1px solid #EDEDED;
  }

  &__head {
    margin-bottom: 24px;
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }

  &__text {
    color: #929FB9;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    margin-top: 4px;
  }

  &__contacts {
    padding: 24px;
  }

}

.account-docs {

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__name {
    color: #4A5773;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  &__date {
    color: #929FB9;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

  &__info {
    flex-grow: 1;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icons {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__icon {
    width: 32px;
    height: 32px;
    border: 1px solid $blue;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    i {
      color: $blue;
      font-size: 16px;
    }
  }

}

.account-delete {
  display: flex;
  padding: 8px 8px 0 3px;

  &__icon {
    width: 24px;
    margin-right: 16px;
    i {
      color: $red-hover;
      font-size: 24px;
    }
  }

  &__info {
    flex-grow: 1;
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  &__text {
    color: #929FB9;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }

}

.panel-settings {

  &__text {
    color: #929FB9;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 16px;
  }

  &__col {
    display: flex;
  }

  &__box {
    width: 100%;
  }

  &__list {
    padding-top: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__switch {
    width: 44px;
    margin-right: 16px;
  }

  &__descr {
    flex-grow: 1;
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

}

/*User account end*/

                                                  /*Account*/
.account-row {
  display: flex;

  @media (max-width: 1199px) {
    flex-direction: column;
  }

}

.account-faq {
  flex: 1 1 auto;
  margin-bottom: 24px;

  &__item {
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    //padding: 18px 16px;
    margin-bottom: 8px;
  }

  &__question {
    color: #4A5773;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 16px;

    i {
      color: #929FB9;
      font-size: 20px;
    }

  }

  &__answer {
    color: #929FB9;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    padding: 0 16px 18px;
    margin-top: -8px;
  }

}

.account-faq__item--open {
  .account-faq__answer {
    display: block;
  }
}

.account-create {
  background: #fff;
  border: 1px solid #E9ECF1;
  padding: 24px;
  border-radius: 8px;
  //max-width: 768px;
  //margin-bottom: 32px;
  flex: 0 0 auto;
  width: 768px;
  margin: 0 32px 32px 0;
  @media (max-width: 1199px) {
    width: 100%;
    max-width: 768px;
    margin: 0 0 32px;
  }
}

.account-create-application {

  &__text {
    color: #424E66;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 16px;
    span {
      color: $blue;
    }
  }

  &__subtext {
    color: #929FB9;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    a {
      color: $blue;
    }
  }

}

.personal-account-textarea {
  min-height: 32px;
}

.personal-account__button-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
}

.pers-account-alert {
  background: #fff;
  border-radius: 8px;
  padding: 18px 16px 16px;
  max-width: 611px;
  display: flex;
  justify-content: space-between;
  margin: auto 0 10px 32px;
  @media (max-width: 576px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  &__inner {
    padding-right: 24px;
  }

  &__text {
    color: $red-hover;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 2px;
    margin-bottom: 12px;
  }

  &__subtext {
    color: #4A5773;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

  &__close {
    cursor: pointer;
   i {
     color: #929FB9;
     font-size: 16px;
   }
  }

  &__icon {
    margin-right: 18px;
    i {
      color: $red;
      font-size: 24px;
    }
  }

}

.notifications-page {
  display: flex;
  flex-direction: column;
  @media (min-width: 1361px) {
    flex-direction: row;
  }

  &__notifications {
    @media (min-width: 1361px) {
      flex: 1 0 63.3%;
      margin-right: 24px;
    }
  }

  &__news {
    @media (min-width: 1361px) {
      flex: 1 0 auto;
    }
  }

}

.notifications {
  &__head {
    color: #4A5773;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 28px;
  }
}

.news {

  &__head {
    color: #4A5773;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 28px;
  }

  &__item {
    border: 1px solid #E9ECF1;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 8px;
  }

  &__title {
    color: #424E66;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  &__date {
    color: #929FB9;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 8px;
  }

  &__text {
    color: #4A5773;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }

}

/*Account end*/

                                            /*-- Invest platform --*/
.invest {
  width: 100%;

  &__title {
    color: #4A5773;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;

    &--bold {
      font-weight: 600;
    }

  }

}

.invest-top-panel {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invest-money {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  @media(max-width: 820px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  &__col {
    border-top: 1px solid #DEE2EA;
    border-bottom: 1px solid #DEE2EA;
    border-left: 1px solid #DEE2EA;
    height: 39px;
    display: flex;
    align-items: center;

    &:first-child {
      border-radius: 8px 0 0 8px;
      @media(max-width: 820px) {
        border-right: 1px solid #DEE2EA;
        border-radius: 8px 8px 0 0;
      }
    }

    &:last-child {
      border-right: 1px solid #DEE2EA;
      border-radius: 0 8px 8px 0;
      @media (max-width: 820px) {
        border-right: none;
        border-radius: 0;
      }
    }

    &:nth-of-type(2) {
      @media(max-width: 820px) {
        border-radius: 0 0 8px 8px;
        border-top: none;
        border-right: 1px solid #DEE2EA;
      }
    }

    &--lg {
      flex: 1 1 34.5%;
      padding: 5px 16px 6px;
      @media (max-width: 1079px) {
        flex: 1 0 40%;
      }
      @media (max-width: 820px) {
        flex: 1 0 100%;
        justify-content: center;
      }
    }

    &--sm {
      flex: 1 0 15.5%;
      min-width: 190px;
      padding: 5px 16px 6px;
      justify-content: center;
      @media (max-width: 1079px) {
        flex: 0 0 5%;
        min-width: auto;
      }
      @media (max-width: 820px) {
        border: none;
        flex: 0 0 49%;
        min-width: auto;
        padding: 0;
        margin: 8px 0.5% 0;
      }

    }

    &--no-border {
      border: none;
    }

  }

  &__col-no-border {
    flex: 1 1 49%;
    &:first-child {
      margin-right: 0.5%;
    }
    &:last-child {
      margin-left: 0.5%;
    }
  }

  &__available {
    color: #5A6A8B;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;

    span {
      white-space: nowrap;
    }

  }

  &__button {
    font-size: 14px;
    line-height: 21px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 820px) {
      border-width: 1px;
      border-style: solid;
      border-radius: 8px;
      padding: 4px 4px 5px;
      width: 100%;
    }

    &--green {
      color: $green;
      @media(max-width: 820px) {
        border-color: $green;
      }
    }

    &--red {
      color: $red-hover;
      @media(max-width: 820px) {
        border-color: $red-hover;
      }
    }

    i {
      font-size: 24px;
      margin-right: 8px;
      @media(max-width: 820px) {
        font-size: 16px;
      }
    }

    span {
      display: inline;
      @media(max-width: 1079px) and (min-width: 821px) {
        display: none;
      }
    }

    &--border {
      border-width: 1px;
      border-style: solid;
      border-radius: 8px;
      padding: 4px 4px 5px;
      width: 100%;

      span {
        @media(max-width: 1079px) and (min-width: 821px) {
          display: inline;
        }
        @media(max-width: 420px) {
          display: none;
        }
      }

    }

  }

}

.textarea-container  {
  position: relative;
  padding-bottom: 5px;
}
.textarea-counter {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #929FB9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

                                                              /*Infobase*/
.infobase-search {
  max-width: 640px;
  display: flex;
  position: relative;
  margin-bottom: 16px;

  &__input {
    height: 48px;
    padding: 16px 36px;
    flex: 1 1 auto;
    margin-right: 8px;

    &::placeholder {
      color: #4A5773;
    }

  }

  &__button {
    flex: 0 0 auto;
    width: 119px;
    @media(max-width: 375px) {
      width: 92px;
    }
  }

  i {
    color: #B5B5B5;
    font-size: 13px;
    position: absolute;
    left: 14px;
    top: 19px;
  }

}

.infobase-tabs {
  background: #fff;
  display: flex;
  padding: 0 35px;
  margin-bottom: 32px;

  @media(max-width: 768px) {
    padding: 0 24px;
  }

  &__icon {
    margin-right: 18px;

    @media (max-width: 767px) {
      margin-left: 18px;
    }

  }

  &__item {
    color: #4A5773;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    height: 48px;
    margin-right: 34px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 767px) {
      margin-right: 24px;
    }

    &--faq {
      .infobase-tabs__icon {
        path {
          fill: #929FB9;
        }
      }
    }

    &--panel {
      .infobase-tabs__icon {
        path {
          fill: #929FB9;
        }
      }
    }

    &--inv {
      .infobase-tabs__icon {
        path {
          fill: #D3D8E3;
        }
      }
    }

    &--active {
      color: $blue;
      border-bottom: 2px solid $blue;

      .infobase-tabs__icon {
        path {
          fill: $blue;
        }
      }

    }

  }

  &__item-title {

    @media (max-width: 767px) {
      display: none;
    }

  }

}

.infobase-content {

  &__title {
    color: #4A5773;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  &__group {
    margin-bottom: 32px;
  }

  &__faq {
    background: #fff;
    border: 1px solid #DEE2EA;
    border-radius: 12px;
  }

  &__faq-item {
    border-bottom: 1px solid #DEE2EA;

    &:last-child {
      border-bottom: none;
    }

  }

  &__faq-question {
    color: #4A5773;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 31px 35px 32px;

    i {
      color: #929FB9;
      font-size: 24px;
      margin-left: auto;
    }

  }

  &__faq-icon {
    margin-right: 19px;
    path {
      fill: $blue;
    }
  }

  &__faq-answer {
    color: #4A5773;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    //border-top: 1px solid #DEE2EA;
    //padding-top: 16px;
    //margin-top: 35px;
    padding: 16px 35px 32px;
    position: relative;

    &:before {
      content: "";
      display: block;
      height: 1px;
      background: #DEE2EA;
      position: absolute;
      top: 0;
      left: 35px;
      right: 35px;
    }

  }

  &__row {
    margin-right: -8px;
    margin-left: -8px;
  }

  &__col {
    padding-left: 8px;
    padding-right: 8px;
  }

  &__box {
    height: 200px;
    margin-bottom: 16px;
  }

  &__subtitle {
    color: #424E66;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;

      a {
        color: $blue;
        &:hover, &:focus {
          color: $blue-hover;
        }
      }

    }

  }

}

.infobase-box {
  background: #fff;
  border-radius: 7px;
  padding: 24px;
}

.infobase-content__faq-item--open {
  .infobase-content__faq-answer {
    display: block;
  }
}

.infobase-nav {
  margin-bottom: 16px;

  &__back {
    color: #4A5773;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  &__back-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 8px;

    i {
      color: #929FB9;
      font-size: 16px;
      opacity: 0.5;
    }

  }

}

.infobase-inner {
  display: flex;
  align-items: flex-start;
  @media (max-width: 1199px) {
    flex-direction: column;
  }

  &__content {
    flex: 1 1 auto;
    margin-bottom: 24px;

    h1, h2, h3, h4, h5, h6, p, ul li, ol li {
      color: #424E66;
      font-weight: 500;
      margin-bottom: 24px;
    }

    h1 {
      font-size: 16px;
      line-height: 18px;
    }

    h2 {
      font-size: 14px;
      line-height: 16px;
    }

    h3, h4, h5, h6 {
      font-size: 13px;
      line-height: 16px;
    }

    p, ul li, ol li {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }

    p, ul, ol {
      margin-bottom: 24px;
    }

    img {
      max-width: 100%;
      height: auto;
      margin: 24px auto;
    }

  }

  &__aside {
    flex: 0 0 auto;
    width: 360px;
    margin-left: 32px;
    margin-bottom: 16px;
    @media (max-width: 1199px) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__aside-title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 24px;
  }

  &__aside-list {
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $blue;
        &:hover, &:focus {
          color: $blue-hover;
        }
      }
    }

  }

}

/*Infobase end*/

                                                    //** Safe Contract **//
.td-collapse {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 2px solid #DFE6E9;
  border-radius: 4px;
  margin: auto;

  i {
    font-size: 12px;
    font-weight: 600;
  }

}

.invest-table {

  tr.tr-collapsed {
    cursor: default;

    > td {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
    }

    &--disabled {

      td {
        color: #424E66;
        opacity: 0.6;
      }

    }

  }

  tr.tr-toggle {

    td {
      border-top-width: 1px;
    }

  }

  tr.double-border:not(:first-of-type) {
    td {
      border-top-width: 2px;
    }
  }

  &--padding-sm {
    td {
      padding: 7px 16px 8px;
    }
  }

  &__recall-button {
    width: 98px;
    margin: auto;
  }

}

.invest-inner-table {
  width: 100%;

  tr {

    td {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      border-top: none;
      padding: 0 8px 0 0;
      cursor: default;

      &:last-child {
        text-align: left;
      }

    }

  }

  &__button {
    display: inline-flex;
    margin-left: auto;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

  }

}

.deal-status {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-radius: 7px;
  padding: 2px 8px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  margin-left: 4px;

  @media (max-width: 514px) {
    display: block;
    width: 121px;
    margin: 4px 0 0 0;
  }

  &--green {
    color: $green;
    border-color: $green;
    background: $green-light;
  }

  &--red {
    color: $red;
    border-color: $red;
    background: $red-light;
  }

  &--blue {
    color: $blue;
    border-color: $blue;
    background: $blue-light;
  }

  &--disabled {
    color: #424E66;
    border-color: #DEE2EA;
    background: #F4F5F8;
  }

}

.new-deal {

  &__no {
    color: #929FB9;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  &__head {
    background: #fff;
    border: 1px solid #DEE2EA;
    border-radius: 8px 8px 0 0;
    padding: 16px;
    @media (max-width: 992px) {
      padding: 16px 16px 0;
    }
  }

  &__body {
    border-left: 1px solid #DEE2EA;
    border-right: 1px solid #DEE2EA;
    border-bottom: 1px solid #DEE2EA;
    border-radius: 0 0 8px 8px;
    padding: 16px;
    @media (max-width: 992px) {
      padding: 16px 16px 0;
    }
  }

  &__row {
    display: flex;
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }

  &__inner-row {
    display: flex;
    @media (max-width: 700px) {
      flex-wrap: wrap;
    }
  }

  &__col-fixed {
    flex: 0 0 auto;
    width: 360px;
    @media (max-width: 1440px) {
      width: 240px;
    }
    @media (max-width: 1030px) {
      width: 200px;
    }
  }

  &__col-auto {
    flex: 1 1 auto;
  }

  &__col {
    flex: 1 1 auto;
    width: 25%;
    @media (max-width: 700px) {
      width: 50%;
    }
  }

  &__group {
    @media (max-width: 992px) {
      margin-bottom: 16px;
    }
  }

  &__title {
    color: #929FB9;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 4px;
  }

  &__text {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    &--green {
      color: $green;
    }

  }

}

.new-deal-add {
  margin-top: 26px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    @media (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 16px;
    }

  }

  &__head-text {
    color: #4A5773;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    margin-right: 8px;
    @media (max-width: 700px) {
      margin-bottom: 16px;
    }
  }

  &__body {

    &--disabled {
      opacity: 0.3;
    }

  }

  &__body-text {
    color: #4A5773;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 16px;
  }

  &__invoices {
    margin-bottom: 16px;
  }

}

.security {

  &__section {
    border-bottom: 1px solid #EDEDED;
    padding: 24px;
    &:last-child {
      border-bottom: none;
    }
  }

  &__section-title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  &__info {
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
  }

  &__complete-title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  &__complete-text {
    color: #424E66;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    p {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }

  }

  &__add-account {
    margin-top: 24px;
  }

}

.security-contract {

  &__content {
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    background: #F4F5F8;
    padding: 16px;
    margin-bottom: 16px;
    height: 200px;
    overflow-y: scroll;

    p, ul li, ol li {
      margin-bottom: 10px;
    }

  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__text {
    color: #424E66;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }

  &__download {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__download-text {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
  }

}

.security-token {

  &__container {
    margin-bottom: 16px;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  &__item {
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }

    &--mb {
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }

  }

  &__label {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;
  }

  &__info {
    background: #F4F5F8;
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    height: 32px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  &__sum {
    color: #424E66;
  }

  &__percent {
    color: $green;

    &--disabled {
      color: #929FB9;
    }

  }

  &__status {
    color: #4A5773;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
  }

  &__buttons {
    display: flex;
    margin-bottom: 16px;
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  &__button {
    @media (max-width: 480px) {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  &__note {
    color: #4A5773;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 8px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      font-size: 18px;
      margin-right: 10px;

      &.la-exclamation-triangle {
        color: #E3B123;
      }

      &.la-info-circle {
        color: #929FB9;
      }

    }

    &--lg {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;

      i {
        font-size: 24px;
      }

    }

  }

}

.security-sign {

  &__container {
    display: flex;
    @media (max-width: 786px) {
      flex-wrap: wrap;
    }
    @media (max-width: 575px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }

  &__sm-col {
    flex: 0 1 auto;
    width: 242px;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    @media (max-width: 786px) {
      width: 48%;
      margin: 0 0 24px 4%;
      &:first-child {
        margin-left: 0;
      }
    }
    @media (max-width: 575px) {
      width: 100%;
      margin: 0 0 24px;
    }
  }

  &__lg-col {
    flex: 1 1 auto;
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 8px;

  }

  &__box {
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 148px;
    @media (max-width: 340px) {
      padding: 6px 16px;
    }

    &--waiting {
      color: #929FB9;
      font-size: 14px;
      line-height: 21px;
      justify-content: center;
      align-items: center;
    }

    &--sc {
      border-color: #200E9F;
    }

  }

  &__name {
    color: #4A5773;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 8px;
    height: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__status {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--orange {
      color: #FE9534;
    }

    &--red {
      color: $red-hover;
    }

    &--green {
      color: $green;
    }

  }

  &__sc-title {
    color: #200E9F;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 4px;
    @media (max-width: 340px) {
      font-size: 13px;
    }
  }

  &__sc-text {
    color: #200E9F;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    @media (max-width: 340px) {
      font-size: 10px;
    }

    p {
      margin-bottom: 2px;
    }

  }

  &__tooltip {

    i {
      color: #929FB9;
      font-size: 16px;
      margin-left: 2px;
    }

    &[data-tooltip] {
      position: relative;

      &::before {
        content: attr(data-tooltip);
        position: absolute;
        width: 360px;
        left: -18px;
        top: -100px;
        background: #fff;
        border-radius: 7px;
        padding: 16px;
        box-shadow: (0px 10px 20px rgba(0, 0, 0, 0.08));
        color: #2D3436;
        font-family: "Manrope", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        pointer-events: none;
        opacity: 0;
        transition: 1s;

        @media (max-width: 1440px) {
          left: auto;
          right: -75px;
        }

        @media (max-width: 974px) {
          right: -25px;
        }

        @media (max-width: 480px) {
          width: 333px;
        }

      }

      &:hover::before {
        opacity: 1;
        left: -13px;
        top: -105px;

        @media (max-width: 1440px) {
          left: auto;
          right: -80px;
        }

        @media (max-width: 974px) {
          right: -30px;
        }

      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: #ffffff transparent transparent transparent;
        position: absolute;
        left: -2px;
        top: -5px;
        opacity: 0;
        transition: 1s;
      }

      &:hover::after {
        opacity: 1;
        left: 3px;
        top: -10px;
      }

    }

  }

}

.token {
  display: flex;
  margin-bottom: 24px;

  @media (max-width: 480px) {
    flex-direction: column;
  }

  &__qr {
    flex: 0 1 auto;
    width: 180px;
    margin-right: 32px;

    @media (max-width: 480px) {
      width: 100%;
      margin-bottom: 24px;
    }

  }

  &__buy {
    flex: 1 1 auto;
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  &__help-text {
    color: #AEACB4;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 16px;
  }

  &__qr-img {
    width: 108px;
  }

  &__group {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    color: #424E66;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

  &__row {
    margin-bottom: -8px;
    margin-left: -8px;
  }

  &__col {
    padding-right: 8px;
    padding-left: 8px;
    @media (max-width: 576px) {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}

//** Safe Contract end**//

                                                        //*Assets*//
.assets {

  &__row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
  }

  &__col {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;

    &--stretch {
      display: flex;

      .assets-card {
        display: flex;
        flex-direction: column;
      }

      .assets-card__body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }

      .assets-card__section {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }

      .assets-card__info {
        margin-bottom: 16px;
      }

      .assets-card__buttons {
        margin-top: auto;
      }

    }

    &--3 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;

      @media (max-width: 1599px) {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media (max-width: 1079px) {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

    }

    &--2 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;

      @media (max-width: 1079px) {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

    }

    &--flex {
      display: flex;
    }

  }

}

.assets-card {
  width: 100%;
  background: #fff;
  border: 1px solid #DEE2EA;
  border-radius: 8px;
  margin-bottom: 24px;

  &--tr {
    background: transparent;
  }

  &__head {
    padding: 16px 24px;
    border-bottom: 1px solid #DEE2EA;
    display: flex;
    justify-content: space-between;

    @media (max-width: 480px) {
      flex-direction: column;
    }

  }

  &__section {
    padding: 24px;
    border-bottom: 1px solid #DEE2EA;

    &:last-child {
      border-bottom: none;
    }

  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;

    @media (max-width: 480px) {
      margin-bottom: 8px;
    }

    &--red {
      color: $red-hover;
    }

  }

  &__sum {
    color: #424E66;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;

    &--green {
      color: $green;
    }

  }

  &__chart {
    margin-bottom: 16px;
  }

  &__info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    @media (max-width: 374px) {
      flex-direction: column;
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

  &__invest-money {
    margin-bottom: 0;
  }

  &__table-wrapped {
    margin-bottom: 0;
  }

  &__invest-table {
    tbody {
      tr {
        cursor: default;
      }
    }
    td {
      font-weight: 600;
      letter-spacing: 0.02em;

    }
    .text-grey {
      font-weight: 400;
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
    @media (max-width: 576px) {
      max-width: 153px;
    }
  }

  &__action-button {
    margin-left: 4px;
    @media (max-width: 576px) {
      flex-shrink: 0;
      width: 92px;
      margin: 0 12px 0 0;
    }
    @media (max-width: 320px) {
      width: 83px;
      margin: 0 8px 0 0;
    }
  }

  &__info {

    &--empty {
      color: #929FB9;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      padding-right: 20px;
    }

  }

}

.assets-text {
  color: #424E66;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  @media (max-width: 374px) {
    margin-bottom: 4px;
  }
}

.assets-sum {
  color: #424E66;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  white-space: nowrap;
}

.assets-label {
  padding-left: 16px;
  position: relative;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 6px;
    position: absolute;
    left: 0;
    top: 7px;
  }

  &--violet {
    &::before {
      background: $violet;
    }
  }

  &--orange {
    &::before {
      background: $orange-light;
    }
  }

  &--mint {
    &::before {
      background: $mint;
    }
  }

  &--cyan {
    &::before {
      background: $cyan;
    }
  }

  &--red {
    &::before {
      background: $watermelon;
    }
  }

}

.assets-chart {

  &__line {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
  }

  &__item {
    flex: 1 1 auto;
    height: 24px;
    margin-right: 2px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      margin-right: 0;
    }

    &--violet {
      background: $violet;
    }

    &--orange {
      background: $orange-light;
    }

    &--mint {
      background: $mint;
    }

    &--cyan {
      background: $cyan;
    }

    &--red {
      background: $watermelon;
    }

  }

}

.assets-alert {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &--border {
    border: 1px solid #BDC5D5;
    border-radius: 8px;
    padding: 5px 10px;
  }

  &__icon {
    color: #FD9C2A;
    font-size: 24px;
    margin-right: 10px;

    &--blue {
      color: $blue;
    }

  }

  &__text {
    color: #929FB9;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;

    &--dark-gray {
      color: #4A5773;
    }

  }

}

.assets-chart-card {
  width: 100%;
  border: 1px solid #DEE2EA;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;

  &__title {
    color: #4A5773;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
  }

}

.history-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    @media (max-width: 1280px) {
      margin-bottom: 16px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__button {
    margin: 0 10px 0 0 !important;

    &:last-child {
      margin-right: 0 !important;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin: 0 0 5px 0 !important;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}

.loan-actions {

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  &__item {
    margin-bottom: 16px;
    position: relative;
  }

  &__label {
    color: #424E66;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  &__input-hint {
    color: $blue;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    bottom: 8px;
    right: 12px;
  }

  &__total {
    color: #424E66;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    &--disabled {
      color: #929FB9;
      font-weight: 300;
    }

  }

  &__button {
    width: auto;
    @media (max-width: 575px) {
      width: 100%;
      margin-top: 8px;
    }
  }

  &__button-buy-hint {
    color: #929FB9;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    text-align: right;
    margin-top: 4px;
    @media (max-width: 575px) {
      text-align: center;
    }
  }

}

.guarantee {
  background: #F4F5F8;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;

  &__top-text {
    color: #4A5773;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 8px;
  }

  &__sc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    @media (max-width: 400px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__button {
    width: 138px;
    @media (max-width: 400px) {
      width: 100%;
      margin-top: 12px;
    }
  }

  &__bottom-text {
    color: #929FB9;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
  }

}

.auction-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__text {
    color: #424E66;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    display: inline-flex;
    align-items: center;
    margin-right: 24px;
    @media (max-width: 480px) {
      margin: 0 0 16px 0;
    }

    i {
      color: $red;
      font-size: 18px;
      margin-right: 8px;
    }

  }

  &__button {
    width: 178px;
    @media (max-width: 480px) {
      width: 100%;
    }
  }

}

.cancel {
  display: flex;
  padding: 8px 0 0;

  &__icon {
    width: 24px;
    margin-right: 16px;
    i {
      color: $red-hover;
      font-size: 24px;
    }
  }

  &__info {
    flex-grow: 1;
  }

  &__title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  &__text {
    color: #929FB9;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }

}

.sale-token {

  &__table {
    margin-bottom: 16px;
  }

  &__form {
    margin-bottom: 16px;
  }

}

.placement-actions {

  &__buttons {
    display: flex;
    justify-content: space-between;
    @media (max-width: 480px) {
      flex-direction: column;
    }

    button {
      &:first-child {
        margin-right: 4px;
        @media (max-width: 480px) {
          margin: 0 0 8px 0;
        }
      }
      &:last-child {
        margin-left: 4px;
        @media (max-width: 480px) {
          margin: 0;
        }
      }
    }

  }

}

.difis-id {

  &__text {
    color: #929FB9;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 16px;

    p {
      margin-bottom: 8px;
    }

  }

}

.difis-id-switches {

  &__item {
    border: 1px solid #E9ECF1;
    border-radius: 4px;
    padding: 8px 22px 8px 17px;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  &__logo {
    margin-right: 17px;

    img {
      width: 24px;
    }

  }

  &__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 4px;

    &--blue {
      color: $blue;
    }

    &--red {
      color: $red;
    }

    &--green {
      color: $green;
    }

  }

  &__text {
    color: #929FB9;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
  }

  &__switch {
    width: 44px;
    margin-left: 16px;
  }

}

.status-upload {
  width: 144px;
}

.text-center {
  text-align: center;
}

.upload-doc {
  width: 144px;
}

.load {
  display: flex;
  align-items: center;
  @media (max-width: 375px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__hint {
    color: #929FB9;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
    @media (max-width: 375px) {
      margin: 8px 0 0 0;
    }
  }

}

.sc-support {
  display: inline;
  color: $green;
}

.sc-contacts {

  &__item {
    display: inline-block;
    margin-right: 32px;
  }

}

.sc-recommend-label {
  color: $green;
  background: rgba(1, 199, 127, 0.1);
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  padding: 3px 9px;
}

.custom-tabs {
  .tab-content {
    display: none;
  }
  .tab-content.show {
    display: block;
  }
}

.mb-sm-3 {
  @media (max-width: 576px) {
    margin-bottom: 1rem !important;
  }
}