.main-modal {
  display: block;
  width: 100%;
  max-width: 697px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }

  &--medium {
    max-width: 448px;
    filter: drop-shadow(0px 10px 25px rgba(0, 0, 0, 0.25));
  }

  &--xxs-width {
    max-width: 348px;
  }

  &--xs-width {
    max-width: 402px;
  }

  &--sm-width {
    width: 416px;
  }

  &--small-width {
    width: 480px;
    @media (max-width: 500px) {
      width: calc(100% - 20px);
    }
  }

  &--mdm-width {
    width: 527px;
    @media (max-width: 500px) {
      width: calc(100% - 20px);
    }
  }

  &--560-width {
    max-width: 560px;
  }

  &--medium-width {
    width: 684px;
    @media (max-width: 704px) {
      width: calc(100% - 20px);
    }
  }

  &--md-width {
    max-width: 715px;
  }

  &--740-width {
    max-width: 740px;
  }

  &--lg-width {
    max-width: 752px;
  }

  &--xl-width {
    max-width: 860px;
  }

  &--xxl-width {
    max-width: 980px;
    @media (max-width: 994px) {
      width: calc(100% - 20px);
    }
  }

  &__head {
    padding: 17px 24px 14px;
    border-bottom: 1px solid #EDEDED;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 576px) {
      //font-size: 20px;
      //padding: 15px;
    }
  }

  &__close {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: linear 0.2s;

    i {
      color: #929FB9;
      font-size: 24px;
    }

    &:hover {
      i {
        color: #4A5773;
      }
    }

  }

  &__inner {
    padding: 24px;
    @media (max-width: 768px) {
      //padding: 15px 15px 20px 15px;
      max-height: calc(100vh - 330px);
      overflow-y: auto;
    }

    &--p0 {
      padding: 0;
    }

    &--padding-lg {
      //padding: 32px;
      padding: 32px 28px;
    }

    &--padding {
      padding: 15px;
    }

    &--pb {
      padding-bottom: 20px;
    }

    &--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: start;
      gap: 20px;
      @media (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }

    &--grid-big {
      grid-template-columns: 1fr 154px 90px;
      grid-gap: 30px;
      @media (max-width: 576px) {
        grid-template-columns: 1fr;
        grid-gap: 14px;
      }
    }
  }

  &__section {
    padding: 24px;
    border-bottom: 1px solid #EDEDED;

    &:last-child {
      border-bottom: none;
    }

  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    @media (max-width: 576px) {
      flex-direction: column;
    }

    &--grid {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      @media (max-width: 576px) {
        display: flex;
        .main-modal__box {
          width: 100%;
        }
      }
    }
  }

  &__box {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &--medium {
      width: 60%;
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    &--small {
      width: 40%;
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    &--flex {
      display: flex;
      align-items: center;
    }

    @media (max-width: 576px) {
      margin: 0 0 20px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &--indent-none {
      margin: 0;
    }

    &--mb {
      margin-bottom: 8px;
    }

    &--mb-lg {
      margin-bottom: 16px;
    }

    &--mr-0 {
      margin-right: 0;
    }

  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 7px 5px;
    margin-top: 10px;

    .main-modal__title {
      margin-bottom: 0;
    }
  }

  &__block {
    max-width: 147px;
    width: 100%;
  }

  &__title {
    font-size: 16px;
    line-height: 18px;
    color: #424E66;
    margin-right: 24px;

    &--dark {
      color: #5B5B60;
    }

    &--black {
      color: #161616;
    }

    &--big {
      color: #161616;
      font-size: 18px;
      margin-bottom: 22px;
      line-height: 20px;
    }

    &--color {
      color: #01C77F;
      line-height: 19px;
    }

    &--grey-blue {
      color: #58748B;
    }

    &--mb {
      margin-bottom: 20px;
    }

    &--medium {
      font-weight: 500;
    }

  }

  &__inner-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #424E66;
    margin-bottom: 16px;

    &--mb-sm {
      margin-bottom: 8px;
    }

  }

  &__subtitle {
    font-size: 13px;
    line-height: 19px;
    color: #9B9B9F;
    margin-bottom: 5px;
  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #424E66;
    margin-bottom: 16px;

    &--color {
      color: #666666;
      margin-bottom: 6px;
    }

    &--medium {
      font-size: 16px;
      line-height: 23px;
      color: #404040;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    &--small {
      font-size: 13px;
      line-height: 19px;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    &--sm {
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
    }

    &--mb {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 576px) {
        margin-bottom: 10px;
      }
    }

    &--mb-sm {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--black {
      color: #161616;
    }

    &--grey {
      color: #7C7C7C;
    }

    &--dark-grey {
      color: #404040;
    }

    &--light-gray {
      color: #AEACB4;
    }

    &--center {
      text-align: center;
    }

    &--flex {
      display: inline-flex;

      span {
        margin-left: 6px;
      }
    }

    &--dark {
      color: #5B5B60;
    }

    &--mb-none {
      margin-bottom: 0;
    }

    .green {
      color: #5CB85C;
    }

    &--mb-mob {
      @media (max-width: 576px) {
        margin-bottom: 6px;
      }
    }

  }

  &__button {
    justify-content: center;
    padding: 10px 20px;
    cursor: pointer !important;
    width: 100%;

    &--radius {
      border-radius: 2px !important;
      height: 35px !important;
      color: #FFFFFF;
      background: #01C77F;
      cursor: default;
      transition: .2s linear;
      @media (max-width: 370px) {
        justify-content: center;
      }

      &:hover {
        background: #1fa775;
      }
    }

    &--blue {
      background: #4277FF;

      &:hover {
        background: $blue-hover;
      }
    }

    &--grey {
      background: #58748B;

      &:hover {
        background: #526d83;
      }
    }

    &--rounded {
      font-size: 14px;
      line-height: 14px;
      border-radius: 8px;
      color: #fff;
    }

    &--mr {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: 370px) {
        margin: 0 0 10px 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--rounded-grey {
      border: 1px solid #B5B5B5;
      background: #fff;
      color: #B5B5B5;
    }

    &--rounded-blue {
      color: $blue;
      background: #fff;
      border: 1px solid $blue;
      &:hover {
        color: $blue-hover;
        border: 1px solid $blue-hover;
      }
    }

  }

  &__input {
    padding-left: 5px;
    max-width: 325px;
    width: 100%;
    height: 34px;
    font-size: 13px;
    line-height: 14px;
    border: 1px solid #C8C7CC;
    margin-bottom: 10px;
    border-radius: 0;
    @media (max-width: 576px) {
      max-width: 100%;
    }

    &--white {
      color: #5B5B60;
      max-width: 100%;
      border: 1px solid #C8C7CC;
      background: #FFFFFF;
      margin: 6px 0 0 0 !important;

      &::placeholder {
        color: #9B9B9F;
      }
    }
  }

  &__textarea {
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #DEE2EA;
    margin-bottom: 10px;
    border-radius: 8px;
    resize: none;
    @media (max-width: 576px) {
      max-width: 100%;
    }

    &--border-radius {
      //border-radius: 5px;
    }

  }

  .select2-container {
    width: 100% !important;
    margin-bottom: 8px;
  }

  .select2-selection {
    height: 34px;
    display: flex;
    align-items: center;
  }

  .select2-selection--single {
    border-radius: 8px !important;
    border: 1px solid #DEE2EA !important;
  }

  .select2-selection__rendered {
    font-weight: 300;
    font-size: 14px !important;
    line-height: 16px !important;
    //color: #929FB9 !important;
    color: #000 !important;
    padding-left: 12px !important;
  }

  .select2-selection__placeholder {
    color: #929FB9 !important;
  }

  &__btn {
    border-radius: 2px;
    border: 1px solid #B5B5B5;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B5B5B5;
    font-size: 14px;
    padding: 0 20px;
    line-height: 13px;
    height: 35px;

    &--rounded {
      border-radius: 5px;
    }

    &--mt-mob {
      @media (max-width: 370px) {
        margin-top: 10px;
      }
    }

    &--small {
      font-size: 13px;
      line-height: 14px;
      height: auto;
      padding: 4.5px 20px;
    }

    &--dark-grey {
      background: $smalt-blue;
      border-color: $smalt-blue;
      color: #fff;

      &:hover {
        background: $smalt-blue-hover;
        border-color: $smalt-blue-hover;
      }
    }

    &--mr {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &--blue {
      background: $blue;
      border-color: $blue;
      color: #fff;

      &:hover {
        background: $blue-hover;
        border-color: $blue-hover;
      }
    }

    &--red {
      color: #fff;
      background: $valencia;
      border-color: $valencia;

      &:hover {
        background: $valencia-hover;
        border-color: $valencia-hover;
      }
    }

    &--green {
      color: #fff;
      background: $fern;
      border-color: $fern;

      &:hover {
        background: $fern-hover;
        border-color: $fern-hover;
      }
    }

    &--part-mob {
      @media (max-width: 576px) {
        width: 50%;
      }
    }
  }

  &__bottom {
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #EDEDED;

    &--start {
      justify-content: flex-start;
    }

    &--between {
      justify-content: space-between;
      @media (max-width: 576px) {
        flex-direction: column;
      }

      @media (max-width: 370px) {
        //flex-direction: column-reverse;
      }
    }

    &--end {
      justify-content: flex-end;
    }

    &--col {
      @media (max-width: 370px) {
        flex-direction: column;
      }
    }

    &--col-mob {
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }

    &--pt-0 {
      padding-top: 0;
    }

    &--no-border {
      border-top: none;
    }

  }

  &__img {
    width: 132px;
    height: 132px;
  }

  &__info-box {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__price {
    padding: 7px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    &--grey {
      color: #4A5773;
    }
    &--green {
      color: #01C77F;
    }
  }

  &__link {
    display: inline;
    color: $blue;

    &:hover {
      color: $blue-hover;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;
    @media (max-width: 576px) {
      grid-template-columns: 1fr;
      grid-gap: 14px;
    }

    &--border-b {
      border-bottom: 1px solid #F7F7F8;
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  &__label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #424E66;

    &--black {
      color: #404040;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--col-mob {
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &--mb-sm {
      margin-bottom: 6px;
      @media (max-width: 576px) {
        margin-bottom: 12px;
      }
    }

    &--mb {
      margin-bottom: 20px;
    }

    &--mt {
      margin-top: 20px;
    }
  }

  &__square {
    width: 34px;
    height: 34px;
    border: 1px solid #AEACB4;
    border-radius: 2px;
    @include flex-row-center;
    margin-left: 10px;
    flex-shrink: 0;
    background: transparent;
  }

  &__status {
    padding: 2.5px 6px;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    color: #fff;
    background: #5CB85C;
    border-radius: 2px;
    margin-left: 6px;
    @include flex-row-center;
  }

  &__buttons {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      margin-top: 14px;
      width: 100%;
    }
  }

  &__item {
    &--mb {
      margin-bottom: 20px;
    }

    &--full {
      grid-column: 1/3;
      @media (max-width: 576px) {
        grid-column: initial;
      }
    }
  }

  &__personal-info {
    margin-bottom: 20px;
  }

  &__qr {
    width: 132px;
    height: 132px;
  }

  &__logo {
    margin: 0 20px 0 auto;
  }

  &__section {
    padding: 24px;
  }

  &__invest-sum {
    font-size: 14px;
    line-height: 16px;
    margin-top: 15px;

    &--green {
      color: $green;
      font-weight: 500;
    }

    &--disabled {
      color: #929FB9;
      font-weight: 300;
    }

  }

  &__form-group {
    @media (max-width: 576px) {
      margin-bottom: 16px;
    }
  }

  &__alert {
    display: flex;
    align-items: center;
  }

  &__alert-icon {
    font-size: 24px;
    margin-right: 10px;

    &--blue {
      color: $blue;
    }

  }

  &__alert-text {
    color: #4A5773;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }

  &__group {
    padding: 26px 24px 22px;
    @media (max-width: 576px) {
      padding: 18px 24px;
    }

    &--confirmed {
      background: #F2FCF8;
      border-bottom: 1px solid #EDEDED;
    }

    &--disabled {
      background: #F4F5F8;
      border-top: 1px solid #EDEDED;
    }

  }

  &__group-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__group-title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    @media (max-width: 375px) {
      font-size: 15px;
      padding-right: 8px;
    }

    &--disabled {
      color: #929FB9;
    }

  }

  &__group-status {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    //white-space: nowrap; 
    @media (max-width: 375px) {
      font-size: 15px;
      max-width: 182px;
    }

    &--blue {
      color: $blue;
    }

    &--green {
      color: $green;
    }

    &--disabled {
      color: #929FB9;
    }

    &--red {
      color: $red-hover;
    }

    i {
     font-size: 24px;
     line-height: 18px;
     vertical-align: bottom;
     margin-right: 4px;
     @media (max-width: 375px) {
        font-size: 18px;
        margin-right: 2px;
      }
    }

  }

  &__group-body {
    margin-top: 16px;

    &--mt-0 {
      margin-top: 0;
    }

  }

  &__group-text {
    color: #4A5773;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 16px;

    &--lg {
      font-weight: 500;
      font-size: 16px;
    }

    &--mb-0 {
      margin-bottom: 0;
    }

  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
  }

  &__tab {
    color: #4A5773;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    width: 50%;
    padding: 12px;
    background: #F4F5F8;

    &--active {
      background: #DEE2EA;
    }
  }

  &__tab.active {
    background: #DEE2EA;
  }

  &__area {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #E9ECF1;

    &--grey {
      background: #F4F5F8;
      border-color: #F4F5F8;
    }

    &--disabled {
      opacity: 0.3;
      cursor: default;

      .main-radio__input {
        cursor: default;
      }

    }

  }

  &__area-content {
    margin-top: 16px;
    display: none;
  }

  &__area.active {
    background: #F4F5F8;
    border-color: #F4F5F8;
  }

  &__area-row {
    display: flex;
    justify-content: space-between;

    &--wrap {
      @media (max-width: 576px) {
        flex-direction: column;
      }

      .main-modal__area-right {
        @media (max-width: 576px) {
          margin-top: 8px;
        }
      }

    }

  }

  &__area-right {
    text-align: right;
    @media (max-width: 375px) {
      font-size: 14px;
    }

    span, a {
      font-weight: 500;
    }

    a {
      text-decoration: underline;
    }

  }

  &__button-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 8px;
    @media (max-width: 880px) {
      flex-direction: column-reverse;
      align-items: stretch;
    }
    @media (max-width: 576px) {
      margin: 8px 0 0 0;
    }
  }

  &__button-hint {
    display: inline-block;
    color: #929FB9;
    font-size: 13px;
    line-height: 18px;
    margin-right: 16px;
    @media (max-width: 880px) {
      margin: 8px 0 0 0;
    }
  }

}