.input {
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: #FFFFFF;
  border-radius: 8px;
  color: $black;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  transition: .2s linear;
  margin: 0;
  border: 1px solid #DEE2EA;
  @media (max-width: 768px) {
    //min-height: 40px;
  }

  &:focus {
    border-color: $blue;
  }

  &::placeholder {
    color: #424E66;
  }

  &--disabled {
    background: #E9ECF1;
    color: #424E66;
    border-color: #DEE2EA;
    cursor: not-allowed;
    pointer-events: none;
    &::placeholder {
      color: #929FB9;
      opacity: 1;
    }
  }

  &:disabled {
    color: #424E66;
    opacity: 1;
  }

  &--pr {
    padding-right: 55px;
  }

  &--pr-medium {
    padding-right: 40px;
  }

  &--border-blue {
    border-color: $blue;
  }

}

textarea {

  &::placeholder {
    color: #929FB9;
  }

  &::-webkit-resizer {
    background: url(../images/resizer.svg) no-repeat;
  }

}