.back-button {
  width: 34px;
  height: 34px;
  border: 1px solid #AEACB4;
  background: $white;
  border-radius: 2px;
  @include flex-row-center;
  margin-right: 10px;
  @media (max-width: 576px) {
    width: 30px;
    height: 30px;
  }
}
