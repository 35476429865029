@mixin flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@mixin mask-contain-center {
  mask-repeat: no-repeat !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-position: center center !important;
  -webkit-mask-position: center center !important;
  mask-size: contain !important;
  -webkit-mask-size: contain !important;
}