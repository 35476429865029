.company-offer {

  &--grey {
    padding: 16px;
    border-radius: 8px;
    background: #F4F5F8;
    border: 1px solid #DEE2EA;
  }

  &--mb {
    margin-bottom: 10px;
  }

  &--mb-lg {
    margin-bottom: 16px;
  }

  &--mb-lg-mob {
    margin-bottom: 16px;
    @media (max-width: 576px) {
      margin-bottom: 24px;
    }
  }

  &--light {
    background: transparent;
  }

  &__label {
    font-size: 12px;
    line-height: 13px;
    color: #929FB9;
    margin-bottom: 8px;

    .blue {
      color: #4277FF;
    }

    &--mt {
      margin: 6px 0 0 0;
    }

    &--lg {
      color: #424E66;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &--mb-sm {
      margin-bottom: 4px;
    }

  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #424E66;
    text-transform: uppercase;

    &--grey {
      color: #929FB9;
    }

    span {
      color: #01C77F;
    }

    @media (max-width: 576px) {
      font-size: 20px;
    }
  }

  &__overhead {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    @media (max-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }

    &--xs {
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: 480px) {
        grid-template-columns: 1fr;
      }
    }

    &--sm {
      grid-template-columns: repeat(3, 1fr);
      @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &--md {
      grid-template-columns: repeat(5, 1fr);
      @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &--lg {
      grid-template-columns: repeat(6, 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &--xl {
      grid-template-columns: repeat(7, 1fr);
      grid-column-gap: 16px;
      @media (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &--mb {
      margin-bottom: 16px;
    }

  }

  &__text {
    font-size: 12px;
    line-height: 13px;
    color: #424E66;

    &--medium {
      font-weight: 500;
    }

    &--mb {
      margin-bottom: 17px;
    }

    &--grey {
      color: #7C7C7C;
    }

    &--dark-grey {
      color: #5B5B60;
    }

    &--light-grey {
      color: #9B9B9F;
    }

    &--green {
      color: #01C77F;
    }

    &--blue {
      color: $blue;
    }

    .green {
      color: #5CB85C;
    }

    .red {
      color: #C82E29;
    }

    &--mb-sm {
      margin-bottom: 6px;
    }

    &--md-size {
      font-size: 14px;
      line-height: 15px;
    }

    &--mb-sm {
      margin-bottom: 6px;
    }

    &--lg {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &--sm {
      color: #929FB9;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }

  }

  &__box {
    min-width: 121px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 576px) {
      margin-bottom: 16px;
    }

    &--sm {
      min-width: 112px;
    }

    &--mdm {
      min-width: 147px;
    }

    &--153 {
      min-width: 153px;
      @media (max-width: 768px) {
        min-width: 153px;
      }
    }

    &--md {
      min-width: 156px;
      @media (max-width: 768px) {
        min-width: 154px;
      }
    }

    &--lg {
      min-width: 179px;
      @media (max-width: 768px) {
        min-width: 155px;
      }
    }

    &--xl {
      min-width: 202px;
    }

  }

  &__stat {
    display: flex;
    align-items: center;
    margin: 10px 0 15px 0;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__stat-item {
    width: 50%;
    margin-right: 26px;

    &:last-child {
      margin-right: 0;
    }

    span {
      color: #D43F3A;
    }

    &--auto {
      width: auto;
    }
    @media (max-width: 576px) {
      margin: 0 0 8px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__input-wrap {
    max-width: 100%;
    margin: 6px 0;
  }

  &__buttons {
    display: flex;
    align-items: center;

    &--mt {
      margin-top: 16px;
    }

    &--mb {
      margin-bottom: 16px;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__button {
    margin-right: 8px;
    //border: none;
    padding: 5px 20px 6px!important;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 576px) {
      width: 100%;
      margin: 0 0 10px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &--lg {
      padding: 5px 20px 6px!important;
      width: 200px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%234277FF80' stroke-width='2' stroke-dasharray='2' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e");
      &:hover {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%234277FF80' stroke-width='2' stroke-dasharray='2' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e");
      }
    }

    &--sm {
      padding: 5px 16px 6px!important;
      width: 171px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%234277FF80' stroke-width='2' stroke-dasharray='2' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e");
      &:hover {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%234277FF80' stroke-width='2' stroke-dasharray='2' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e");
      }
    }

  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--mb-sm {
      margin-bottom: 6px;
    }
  }

  &--bordered {
    background: #F7F7F8;
    border: 1px solid #718EA5;
    border-radius: 3px;
  }

  &--border {
    border: 1px solid #DEE2EA;
    border-radius: 8px;
    padding: 16px;
  }

}
