.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
  &--disabled {
    cursor: default;
    pointer-events: none;
    .custom-select__trigger{
      border-color: #E6E6E6;
      background: #E6E6E6;
      color: #828282;
    }
    .arrow {
      display: none;
    }
  }
}
.custom-select {
  position: relative;
  display: flex;
  flex-direction: column;
}
.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 6px;
  color: #444;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #C8C7CC;
  font-size: 13px;
  line-height: 14px;
  min-height: 34px;
}
.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background: #FFFFFF;
  max-height: 288px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  border: 1px solid #DFDFDF;
  border-top: 0;
}
.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.custom-option {
  position: relative;
  display: flex;
  padding: 7px 5px;
  font-size: 13px;
  line-height: 14px;
  color: #141011;
  cursor: pointer;

}
.custom-option:hover {
  cursor: pointer;
  //background: #fcfcfc;
}
.custom-option.selected {
  //background: #F2F2F2;
}
.arrow {
  position: relative;
  height: 13px;
  width: 13px;
  flex-shrink: 0;
  margin-left: 10px;
  background: url("../../images/icons/bottom-chevron.svg") center /contain no-repeat;
}

.open .arrow {
  transform: rotate(180deg);
}

