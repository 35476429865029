.personal-account {

  &--pt {
    padding-top: 30px;
  }

  &--pt-big {
    padding-top: 130px;
    @media (max-width: 992px) {
      padding-top: 100px;
    }
    @media (max-width: 768px) {
      padding-top: 60px;
    }
    @media (max-width: 576px) {
      padding-top: 30px;
    }
  }

  &--pb-0 {
    padding-bottom: 0;
  }


  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &--mb {
      margin-bottom: 20px;
    }

    &--mt {
      margin-top: 50px;
    }
  }

  &__header-title {
    color: #424E66;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    &--big {
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 30px;
      @media (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 20px;
      }
      @media (max-width: 576px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }
  }

  &__check {
    margin-right: 16px;
  }

  &__step {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    color: #FE7D34;
    position: relative;
    padding-left: 30px;

    &--grey {
      color: #B5B5B5;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__types {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

  }

  &__item {
    padding-bottom: 24px;
    border-bottom: 1px solid #DEE2EA;

    &--pt {
      padding-top: 24px;
    }

    &--bb-none {
      border-bottom: none;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    @media (max-width: 768px) {
      padding-bottom: 25px;
    }

    &--border {
      padding-top: 40px;
      margin-top: 40px;
      border-top: 1px solid #EDEDED !important;
      @media (max-width: 576px) {
        padding-top: 25px;
        margin-top: 25px;
      }
    }
  }

  &__type-box {
    width: 100%;
    position: relative;
    @media (max-width: 768px) {
      margin: 0 0 8px 0;
    }
    &:first-child {
      .personal-account__type-button {
        border-left: 1px solid $blue;
        border-radius: 8px 0 0 8px;
        @media (max-width: 768px) {
          border-right: 1px solid $blue;;
          border-radius: 8px;
        }
      }
    }
    &:last-child {
      .personal-account__type-button {
        border-right: 1px solid $blue;
        border-radius: 0 8px 8px 0;
        @media (max-width: 768px) {
          border-left: 1px solid $blue;
          border-radius: 8px;
        }
      }
    }
  }

  &__type-input {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  &__type-button {
    background: #fff;
    width: 100%;
    padding: 12px 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $blue;
    border-top: 1px solid $blue;
    border-bottom: 1px solid $blue;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: 768px) {
      padding: 10px;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
      border-radius: 8px;
    }
    &.active {
      background: $blue;
      color: #fff;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: start;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--between {
      justify-content: space-between;
    }

    &--mb-medium {
      margin-bottom: 16px;
    }

    &--column-xl {
      @media (max-width: 1360px) {
        flex-direction: column;
        align-items: start;
      }
      @media (max-width: 1199px) {
        flex-direction: row;
      }
      @media (max-width: 730px) {
        flex-direction: column;
      }
    }

    &--column-lg {
      @media (max-width: 1260px) {
        flex-direction: column;
        align-items: start;
      }
    }

    &--column-md {
      @media (max-width: 750px) {
        flex-direction: column;
        align-items: start;
      }
    }

    &--column-sm {
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: start;
      }
    }

    &--no-column {
      @media (max-width: 576px) {
        flex-direction: row;
      }
    }

    &--validated {
      align-items: center;
      @media (max-width: 576px) {
        align-items: flex-start;
      }
    }

  }

  &__box {
    width: 100%;
    margin-bottom: 8px;

    &--col {
      margin-right: 24px;
      @media (max-width: 768px) {
        margin: 0 0 24px 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &--part {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &--part-md {
      width: 50%;
      @media (max-width: 750px) {
        width: 100%;
      }
    }

    &--part-xl {
      width: 50%;
      @media (max-width: 1360px) {
        width: 100%;
      }
      @media (max-width: 1199px) {
        width: 50%;
      }
      @media (max-width: 730px) {
        width: 100%;
      }
    }

    &--big {
      width: 40%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &--small {
      width: 20%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &--big-width {
      width: 60%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &--medium-width {
      width: 40%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &--medium-small {
      width: 55%;
      margin-right: 20px;
    }

    &--mr {
      margin-right: 16px;
    }

    &--mr-sm {
      margin-right: 8px;
    }

    &--mb-lg {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }

  }

  &__label {
    font-size: 14px;
    line-height: 16px;
    color: #424E66;
    margin-bottom: 8px;
    &--grey {
      color: #466857;
    }
  }

  &__hint {
    font-size: 12px;
    line-height: 14px;
    color: #929FB9;
    margin-top: 8px;
  }

  &__button {
    max-width: 140px;
    @media (max-width: 768px) {
      max-width: 100%;
    }

    &--ml {
      margin-left: 8px;
      @media (max-width: 576px) {
        margin: 8px 0 0 0;
      }
    }

  }

  &__area-container {
    position: relative;
    width: 100%;
  }

  &__check-input {
    position: absolute;
    right: 0;
    top: -20px;
    width: 12px;
    height: 12px;
    background: #466857;
    @include mask-contain-center;
    mask: url("../../images/icons/chevron-green.svg");
    -webkit-mask: url("../../images/icons/chevron-green.svg");
  }

  &__entrepreneur {

    span {
      font-weight: 400;
      display: block;
    }
  }

  &__overhead {
    font-weight: 400;
    font-size: 13px;
    line-height: 108%;
    color: #404040;
    margin-bottom: 5px;
  }

  &__user-info {
    &--mb {
      margin-bottom: 20px;
    }

    &--flex {
      display: flex;
      align-items: center;
    }
  }

  &__desc {
    font-size: 13px;
    line-height: 108%;
    color: #7C7C7C;
  }

  &__block {
    width: 50%;
    margin-right: 20px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    margin-bottom: 30px;

    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }

  &__wrapper {
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
      padding: 6px;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

    &:checked+.personal-account__wrapper {
      border-color: $green;

      .personal-account__name {
        color: $green;
      }
    }
  }

  &__img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 5px;
    margin-right: 15px;
  }

  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $dark;
  }

  &__position {
    font-size: 12px;
    line-height: 20px;
    color: $dark-grey;
  }

  &__container {
    padding-bottom: 30px;
    border-bottom: 1px solid #EDEDED;
  }
  &__tab-content {
    display: none;
    &.show {
      display: block;
    }
  }
  &__input-info {
    max-width: 164px;
    font-size: 11px;
    line-height: 11px;
    color: #7C7C7C;
    margin-top: -14px;
  }
  &__company {
    margin-bottom: 20px;
  }
  &__text {
    font-size: 16px;
    line-height: 143%;
    color: $black;
    @media (max-width: 576px) {
      font-size: 14px;
    }
    span {
      color: $blue;
    }
    a {
      color: $blue;
      display: inline;
    }
    &--mb {
      margin-bottom: 20px;
    }
    &--small {
      font-size: 13px;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
    &--grey {
      color: #7C7C7C;
    }
  }
  &__choose-personal-account {
    margin-bottom: 30px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__bottom-button {
    width: auto!important;
    @media (max-width: 576px) {
      width: 100%!important;
    }
  }

  &__help {
    color: #01C77F;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.02em;
    margin-top: 2px;
  }

  &__validated {
    color: #929FB9;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 24px;
    flex: 1 0 auto;
    @media (max-width: 576px) {
      margin: 8px 0 0 0;
    }
    i {
      color: #929FB9;
      font-size: 16px;
      margin-right: 9px;
    }
  }

}

.personal-account__type-input:checked + .personal-account__type-button {
  background: $blue;
  color: #fff;
}
