.choose-personal-account {
  padding: 20px;
  border: 1px solid #8DB3FF;
  background: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  @media (max-width: 576px) {
    padding: 15px;
  }
  &__title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #161616;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  &__text {
    color: #7C7C7C;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 20px;

  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }

    &--column-md {
      @media (max-width: 800px) {
        grid-template-columns: 1fr;
      }
    }

  }

}
