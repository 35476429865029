.personal-info {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 365px;
    grid-gap: 20px;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      grid-gap: 14px;
    }
  }
  &__item {
    padding: 20px;
    border: 1px solid #DFDFDF;
    @media (max-width: 576px) {
      padding: 20px 14px;
    }
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 15px;
    color: #58748B;
    &--mb {
      margin-bottom: 10px;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 16px;
    }
  }
  &__preview {
    flex-shrink: 0;
    margin-right: 20px;
    width: 100px;
    height: 100px;
    border: 1px solid #E6E8E8;
    border-radius: 2px;
    @include flex-row-center;
    @media (max-width: 576px) {
      margin: 0 0 14px 0;
    }
    &--small {
      width: 67px;
      height: 67px;

    }
  }
  &__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__label {
    min-width: 124px;
    font-size: 13px;
    line-height: 19px;
    color: #858585;
    margin-right: 20px;
    &--width {
      min-width: 174px;
    }
    @media (max-width: 576px) {
      margin: 0 0 5px 0;
    }
    &--width-sm {
      min-width: 100px;
    }
  }
  &__button {
    font-size: 13px;
    line-height: 13px;
    color: #58748B;
    padding: 4.5px 20px;
    margin-right: 10px;
    border: 1px solid #58748B;
    border-radius: 2px;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 576px) {
      margin: 0 0 8px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__text {
    font-size: 13px;
    line-height: 19px;
    color: #5B5B60;
  }
  &__wrapper {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
