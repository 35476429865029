button {
  background: transparent;
  padding: 0;
}

.button {
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 10px 24px 11px;
  border-radius: 8px;
  color: #fff;
  transition: .2s linear;
  white-space: nowrap;

  &--blue {
    color: #fff;
    background: $blue;
    border-color: $blue;
    &:hover {
      background: $blue-hover;
      border-color: $blue-hover;
    }
  }

  &--border-blue {
    color: $blue;
    background: #fff;
    border: 1px solid $blue;
    &:hover {
      color: $blue-hover;
      border: 1px solid $blue-hover;
    }
  }

  &--border-blue-tr {
    color: $blue;
    background: transparent;
    border: 1px solid $blue;
    &:hover {
      color: $blue-hover;
      border: 1px solid $blue-hover;
    }
  }

  &--dashed-blue {
    color: $blue;
    background-color: #fff;
    border: 1px dashed $blue;
    &:hover {
      color: $blue-hover;
      border: 1px dashed $blue;
    }
  }

  &--blue-disabled {
    color: #fff;
    background: $blue;
    border-color: $blue;
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--red {
    color: #fff;
    background: $red;
    border-color: $red;
    &:hover {
      background: $red-hover;
      border-color: $red-hover;
    }
  }

  &--border-red {
    color: $red;
    background: #fff;
    border: 1px solid $red;
    &:hover {
      color: $red-hover;
      border: 1px solid $red-hover;
    }
  }

  &--border-red-tr {
    color: $red;
    background: transparent;
    border: 1px solid $red;
    &:hover {
      color: $red-hover;
      border: 1px solid $red-hover;
    }
  }

  &--green {
    color: #fff;
    background: $green;
    border-color: $green;
    &:hover {
      background: $green-hover;
      border-color: $green-hover;
    }
  }

  &--border-green {
    color: $green;
    background: #fff;
    border: 1px solid $green;
    &:hover {
      color: $green-hover;
      border: 1px solid $green-hover;
    }
  }

  &--border-green-tr {
    color: $green;
    background: transparent;
    border: 1px solid $green;
    &:hover {
      color: $green-hover;
      border: 1px solid $green-hover;
    }
  }

  &--green-disabled {
    color: #fff;
    background: $green;
    border-color: $green;
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--border-green-disabled {
    color: $green;
    background: #fff;
    border: 1px solid $green;
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--grey {
    color: #fff;
    background: #929FB9;
    border-color: #929FB9;
    &:hover {
      background: #4A5773;
      border-color: #4A5773;
    }
  }

  &--border-grey {
    color: #929FB9;
    background: #fff;
    border: 1px solid #929FB9;
    &:hover {
      color: #4A5773;
      border: 1px solid #4A5773;
    }
  }

  &--border-grey-tr {
    color: #929FB9;
    background: transparent;
    border: 1px solid #929FB9;
    &:hover {
      color: #4A5773;
      border: 1px solid #4A5773;
    }
  }

  &--border-light-grey {
    color: #BDC5D5;
    background: #fff;
    border: 1px solid #BDC5D5;
    &:hover {
      color: #929FB9;
      border: 1px solid #929FB9;
    }
  }

  &--disabled {
    color: $blue-gray-500;
    background: $blue-gray-50;
    border: 1px solid $blue-gray-150;
    cursor: not-allowed;
    &:hover {
      color: $blue-gray-500;
      background: $blue-gray-50;
      border: 1px solid $blue-gray-150;
    }
  }

  &--sm {
    padding: 5px 16px 6px;
  }

  &--md {
    padding: 9px 24px 10px;
  }

  &--lg {
    padding: 13px 38px 14px;
  }

  &--auto {
    width: auto;
  }

  &--184-width {
    width: 184px;
  }

  &--mr {
    margin-right: 8px;
  }

  &--between {
    margin-right: 8px;
    @media(max-width: 576px) {
      margin: 0 0 8px 0;
    }
  }

  &--right {
    margin-left: auto;
  }

  &--left {
    margin-right: auto;
    @media(max-width: 576px) {
      margin-right: 0;
    }
  }

  &--start {
    align-self: flex-start;
    @media(max-width: 576px) {
      align-self: auto;
    }
  }

}

.button-link {
  border: none;

  &--blue {
    color: $blue;
  }

  &--red {
    color: $red-hover;
  }

  &--green {
    color: $green;
  }

}


  /*
  @media(max-width: 992px) {
    font-size: 15px;
    padding: 9px 30px;
    margin-bottom: 15px;
  }
  @media(max-width: 576px) {
    font-size: 14px;
    padding: 8px 30px;
  }

  &--main-red {
    background: #D43F3A;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
    padding: 10px 20px;
    color: #fff;
    margin: 0;

    &:hover {
      background: #c93834;
    }

    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 12px;
    }
  }

  &--dark-grey {
    color: #fff;
    background: $smalt-blue;
    border: 1px solid $smalt-blue;

    &:hover {
      background: $smalt-blue-hover;
      border-color: $smalt-blue;
    }
  }

  &--main-small {
    justify-content: center;
    padding: 10px 20px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
  }

  &--bordered-grey {
    color: #AEACB4;
    border: 1px solid #AEACB4;
  }

  &--bordered-dark-grey {
    color: $smalt-blue;
    border: 1px solid $smalt-blue;
  }

  &--light-blue {
    color: #fff;
    background: #46B8DA;
    &:hover {
      background: #43b2d3;
    }
  }
  &__icon {
    margin-right: 6px;
  }

   */


/*
.button--white-green {
  border: 1px solid #01C77F;
  color: #01C77F;
  background-color: #fff;
  transition: .2s linear;

  &:hover {
    background-color: #01C77F;
    color: #fff;
  }
}

.button--width {
  width: 100%;
}

.button--indent {
  padding: 16px 40px;

  @media(max-width: 576px) {
    padding: 15px 20px;
  }
}

.button--medium {
  padding: 16px 20px;
}

.button--small {
  padding: 11px 20px;
}

.button--center {
  text-align: center;
}

.button--disabled {
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}

.button--style-disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.button--small {
  font-size: 14px;
  line-height: 16px;
  padding: 11px 20px;
}

.button--blue {
  background: #296CDB;
  color: #fff;
  transition: .2s linear;

  &:hover {
    background: rgb(34, 92, 185);
  }
}

.button--red {
  background: #F03A31;
  color: #fff;
  transition: .2s linear;

  &:hover {
    background: rgb(204, 49, 40);
  }
}

.button--style-grey {
  color: #525252;
  background: #F5F5F5;
}

.button--style-green {
  color: #FFFFFF;
  background: #01C77F;
  cursor: default;

  &:hover {
    color: #FFFFFF !important;
    background: #01C77F !important;
  }
}

.button--grey {
  display: inline-block;
  border: 1px solid #C2C2C2;
  color: #858585;
  transition: .2s linear;

  &:hover {
    background-color: rgb(235, 235, 235);
  }
}
*/