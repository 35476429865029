.overhead-main {
  margin-top: 60px;
  padding: 14px 30px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
    margin-top: 55px;
  }
  &__title {
    font-size: 18px;
    line-height: 20px;
    color: #5B5B6A;
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  &__breadcrumbs {
    @media (max-width: 576px) {
      margin-top: 12px;
    }
  }
}
