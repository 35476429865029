.sidebar-main {
  position: fixed;
  left: 0;
  top: 60px;
  width: 220px;
  height: 100%;
  background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 97.4%, #EBEBEB 100%);
  padding: 30px 0 0 0;
  transition: width, transform 0.5s;
  z-index: 10;
  @media (max-width: 992px) {
    display: block;
    transform: translateX(-220px);
  }

  &.close {
    width: 60px;

    .sidebar-main__box,
    .sidebar-main__arrow,
    .nav-main__name,
    .nav-main__arrow,
    .nav-main__link,
    .nav-main__title {
      display: none;
    }

    @media (max-width: 992px) {
      width: 220px;
      transform: translateX(0);
      .sidebar-main__box,
      .sidebar-main__arrow,
      .nav-main__name,
      .nav-main__arrow,
      .nav-main__link,
      .nav-main__title {
        display: block;
      }
    }

  }

  &__user {
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 40px;
  }

  &__icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__name {
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: #484844;
    margin-bottom: 5px;
    transition: opacity 0.5s;
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    color: #535353;
    transition: opacity 0.5s;
  }

  &__arrow {
    width: 12px;
    height: 12px;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  &__list-wrap {
    margin-left: auto;
  }

  &__box {
    white-space: nowrap;
  }

  &__list-wrap {
    position: relative;
  }

  &__list {
    width: 152px;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 10;
    box-shadow:  0 0 5px #ccc;
    opacity: 0;
    visibility: hidden;
    transition: .1s linear;
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  &__list-link {
    font-size: 12px;
    padding: 10px 20px;
    color: #8e8e93;
    &:hover {
      background: #faf9f9;
    }
    &--bt {
      border-top: 1px solid #e5e5e5;
      margin-top: 5px;
      padding-top: 13px;
    }
  }
}
