.nav-main {
  &__title {
    white-space: nowrap;
    padding: 16px 20px;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #535353;
  }
  &__box {
    display: flex;
    align-items: center;
    padding: 17.5px 15px 17.5px 20px;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  &__icon {
    margin-right: 24px;
  }
  &__arrow {
    margin-left: auto;
    transform: rotate(90deg);
  }
  &__content {
    display: none;
  }
  &__wrapper {
    &.open {
      .nav-main__arrow {
        transform: rotate(0);
      }
      .nav-main__box {
        padding-left: 16px;
      }
      background: #F1F1F1;
      border-left: 4px solid #C82E29;
      .nav-main__content {
        display: block;
      }
    }
  }
  &__name {
    white-space: nowrap;
    font-size: 12px;
    line-height: 13px;
    color: #484844;
  }
  &__link {
    white-space: nowrap;
    font-size: 12px;
    line-height: 13px;
    color: #535353;
    padding: 12px 20px 12px 64px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    &.active {
      background: #E9E9E9;
    }
  }
}
